import axios from "axios";
import moment from "moment";
import { useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../config";
import TripScheduleController from "./TripSchduleController";

const initSearchForm = {
  searchstring: "Pulling today's data from mas system ...  ",
  resultsDisplay: "display1",
  searchFromDOB: "",
  searchToDOB: "",
  searchName: "",
  searchCINNumber: "",
  searchInvoiceNumber: "",
  searchServiceEndsFrom: "",
  searchTransportType: null,
  searchServiceEndsTo: "",
  searchCounty: null,
  searchServiceStartsFrom: moment().format("MM/DD/YYYY"),
  searchServiceStartsTo: moment().add(1, "days").format("MM/DD/YYYY"),
  page: 0,
  per_page: 0,
  total: 0,
  total_pages: 0,
  freshFetch: false,
  fromSchedule: true,
  fetching: true,
  excelExport: false,
  pdfExport: false,
  sort: { field: "pickupDate", order: "ASC" },
  tripStatus: [],
};

const TripScheduler = () => {
  const [searchForm, setSearchForm] = useState(initSearchForm);
  const [fullDataSet, setFullDataSet] = useState({
    data: [],
    page: 0,
    per_page: 1,
    total: 71,
    total_pages: 7,
  });
  const [tablerows, setTablerows] = useState({
    data: [],
    page: 0,
    per_page: 1,
    total: 71,
    total_pages: 7,
  });

  const [countries, setCountries] = useState([]);
  const [tripStatusList, setTripStatusList] = useState([]);
  const [PASubmissionResultsList, setPASubmissionResultsList] = useState([]);
  const [correctionList, setCorrectionList] = useState([]);
  const [exportStatusList, setExportStatusList] = useState([]);
  const [transportProviderList, setTransportProviderList] = useState([]);

  const [openSearch, setOpenSearch] = useState(true);
  const [tripSortByList, setTripSortByList] = useState([]);
  const [countyList, setCountyList] = useState([]);
  const [transportTypeList, setTransportTypeList] = useState([]);
  const [searchstring, setSearchstring] = useState(
    "Pulling today's data from mas system ...  "
  );
  const [fetching, setFetching] = useState(false);
  const [cars, setCars] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [user, setUser] = useState({});
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [selectedInvoiceOpen, setSelectedInvoiceOpen] = useState(false);
  const [modleProgress, setModleProgress] = useState(false);
  const [schaduleRetrived, setSchaduleRetrived] = useState(true);

  const [fethcResults, setFetchResults] = useState(false);

  useEffect(async () => {
    setSearchForm({
      ...initSearchForm,
    });
    let user = { active: false, name: "" };
    if (localStorage.getItem("session")) {
      user = JSON.parse(localStorage.getItem("session"));
    }
    setUser(user);
    await loadRefData(user);
    setFetchResults(true);
  }, []);

  useEffect(async () => {
    if (fethcResults) {
      await fetchResults();
      setFetchResults(false);
    }
  }, [fethcResults]);

  useEffect(async () => {
    if (searchForm.excelExport) {
      await exportToExcel();
    }
  }, [searchForm.excelExport]);

  useEffect(async () => {
    if (schedules.length == 0 && !schaduleRetrived) {
      await _retriveTripScheduleData();
      setSchaduleRetrived(true);
    }
  }, [schaduleRetrived]);

  async function loadRefData(user) {
    try {
      const metaResp = await axios.get(BASE_URL + "/refdata/all", {
        headers: {
          "x-auth-key": user.key,
        },
      });
      const countryList = await metaResp.data;
      const _searchForm = searchForm;

      _searchForm.tripStatus = countryList.tripStatusList.rows;
      //searchForm.searchstring = "Pulling today's data from mas system ...  ";
      setCountries(await countryList.countryList.rows);
      setCountyList(await countryList.countyList.rows);
      setTripStatusList(await countryList.tripStatusList.rows);
      setPASubmissionResultsList(
        await countryList.pASubmissionResultsList.rows
      );
      setCorrectionList(await countryList.correctionList.rows);
      setExportStatusList(await countryList.exportStatusList.rows);
      setTransportTypeList(await countryList.transportTypeList.rows);
      setTransportProviderList(await countryList.transportProviderList.rows);
      setTripSortByList(await countryList.tripSortByList.rows);
      setDrivers(await countryList.drivers.data);
      setCars(await countryList.cars);
      setModleProgress(false);
      setSearchForm(_searchForm);
      //   setFetchResults(true);
    } catch (err) {
      console.log("ERROR ---- >", err);
      toast.error(
        "Error fetching metadata , Please check your internet connection  ",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }

  async function viewRosterPdf(invoice) {
    setModleProgress(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        "x-auth-key": user.key,
      };

      const response = await axios.post(
        BASE_URL + "/scheduler/viewRosterPdf",
        { invoice },
        {
          headers: headers,
        }
      );
      setModleProgress(false);
      if (response.data.pdfURL) {
        window.open(response.data.pdfURL);
      }
    } catch (e) {
      toast.error("Error exporting pdf . Please try again ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setModleProgress(false);
    }
  }

  async function syncData() {
    const headers = {
      "Content-Type": "application/json",
      "x-auth-key": user.key,
    };
    try {
      const response = await axios.post(
        BASE_URL + "/scheduler/sync",
        searchForm,
        {
          headers: headers,
        }
      );
      setModleProgress(false);
      //   await fetchResults();
    } catch (err) {}
  }

  function handleChangePage(event, newPage) {
    let _searchForm = searchForm;
    _searchForm.page = newPage;
    setSearchForm(_searchForm);
    setFetchResults(true);
  }

  function handleChangeRowsPerPage(event) {
    let _searchForm = searchForm;
    _searchForm.per_page = parseInt(event.target.value, 10);
    setSearchForm(_searchForm);
    setFetchResults(true);
  }

  function getSelectedInvoice() {
    return selectedInvoice;
  }

  function excelExport() {
    let _searchForm = searchForm;
    _searchForm.excelExport = true;
    setSearchForm(_searchForm);
    exportToExcel();
  }

  async function exportToExcel() {
    setModleProgress(true);
    const headers = {
      "Content-Type": "application/json",
      "x-auth-key": user.key,
    };
    try {
      let scheduleRequest = {
        invoces: tablerows.data.map((i) => i.invoiceNumber),
        searchForm: searchForm,
      };
      console.log(BASE_URL + "/scheduler/exportExcel", scheduleRequest);
      const response = await axios.post(
        BASE_URL + "/scheduler/exportExcel",
        scheduleRequest,
        {
          headers: headers,
        }
      );
      if (response.data.fileName) {
        window.open(response.data.fileName);
      }

      setModleProgress(false);
    } catch (e) {
      console.log(e);
      setModleProgress(false);
      toast.error(
        "Error fetching data , Please check your internet connection  ",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }

  function pdfExport() {
    setSearchForm({
      ...searchForm,
      pdfExport: true,
    });
  }

  function resetSearchForm() {
    setSearchForm({
      ...searchForm,
      resultsDisplay: "display1",
      searchFromDOB: "",
      searchToDOB: "",
      searchName: "",
      searchCINNumber: "",
      searchInvoiceNumber: "",
      searchServiceEndsFrom: "",
      searchTransportType: null,
      searchServiceEndsTo: "",
      searchCounty: null,
      searchServiceStartsFrom: moment().format("MM/DD/YYYY"),
      searchServiceStartsTo: "",
      page: 0,
      per_page: 0,
      total: 0,
      fromSchedule: true,
      total_pages: 0,
      freshFetch: false,
      fetching: true,
      schaduleRetrived: false,
      excelExport: false,
      pdfExport: false,
      sort: { field: "pickupDate", order: "ASC" },
      tripStatus: [],
    });
    setFetchResults(true);
  }

  async function updateTripSchedule(invoice, leg, key) {
    const headers = {
      "Content-Type": "application/json",
      "x-auth-key": user.key,
    };
    try {
      var _schedules = schedules;
      var schedule = _schedules[key] ? _schedules[key] : { updated: false };

      if (
        !schedule.self &&
        (!schedule.updated ||
          !schedule.scheduledcar ||
          !schedule.selecteddriver)
      ) {
        return;
      }
      const response = await axios.post(
        BASE_URL + "/scheduler/updatetripSchedule",
        schedule,
        {
          headers: headers,
        }
      );
      let scheduleKey = key;
      var schedule = _schedules[scheduleKey] ? _schedules[scheduleKey] : {};
      schedule.updated = false;
      schedule.saved = true;
      _schedules[scheduleKey] = schedule;
      console.log(_schedules);
      setSchedules(_schedules);
    } catch (e) {
      setModleProgress(false);
      toast.error(
        "Error fetching data , Please check your internet connection  ",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }

  async function retriveTripScheduleData() {
    setSchedules([]);
    setSchaduleRetrived(false);
  }

  async function _retriveTripScheduleData() {
    setModleProgress(true);
    let _user = user;
    if (!user) {
      _user = { active: false, name: "" };
      if (localStorage.getItem("session")) {
        _user = JSON.parse(localStorage.getItem("session"));
      }
    }
    const headers = {
      "Content-Type": "application/json",
      "x-auth-key": _user.key,
    };
    try {
      let scheduleRequest = {
        invoces: tablerows.data.map((i) => i.invoiceNumber),
        searchForm: searchForm,
      };

      console.log("scheduleRequest ->", scheduleRequest);
      const response = await axios.post(
        BASE_URL + "/scheduler/fetchtripschadues",
        scheduleRequest,
        {
          headers: headers,
        }
      );
      var _schedules = schedules;

      for (var schd of response.data) {
        if (!schd.suggest) {
          schd.saved = true;
          schd.updated = false;
        }

        _schedules[schd.key] = schd;
      }
      setSchedules(_schedules);
      setSchaduleRetrived(true);

      setModleProgress(false);
    } catch (e) {
      console.log("_retriveTripScheduleData ->", e);
      setModleProgress(false);
      toast.error(
        "Error fetching data , Please check your internet connection  ",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }

  async function fetchTripRoster(invoice) {
    setModleProgress(true);

    const headers = {
      "Content-Type": "application/json",
      "x-auth-key": user.key,
    };

    try {
      const response = await axios.post(
        BASE_URL + "/scheduler/viewRoster",
        { invoice },
        {
          headers: headers,
        }
      );

      setSelectedInvoice(response.data.triproster.tripRoster);
      setModleProgress(false);
      setSelectedInvoiceOpen(true);
    } catch (e) {
      setModleProgress(false);
      toast.error(
        "Error fetching data , Please check your internet connection  ",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }

  function setSearchOrder(option, order) {
    let _sort = searchForm.sort;
    _sort.field = option;
    _sort.order = order;

    let _searchForm = searchForm;
    _searchForm.sort = _sort;
    setSearchForm(_searchForm);
    setFetchResults(true);
  }

  function setSearchFormDate(id, value) {
    let _searchForm = searchForm;
    _searchForm[id] = value;
    setSearchForm(_searchForm);
    setFetchResults(true);
  }
  function setSearchFormCustom(id, value) {
    let _searchForm = searchForm;
    _searchForm[id] = value;
    setSearchForm(_searchForm);
  }

  function setSearchFormAutoComplete(id, value) {
    let _searchForm = searchForm;
    _searchForm[id] = value;
    setSearchForm(_searchForm);
    setFetchResults(true);
  }

  async function handleFilteration(filters) {
    console.log(fullDataSet);
    let _searchForm = searchForm;
    _searchForm.searchCounty =
      filters["searchCounty"] == "All" ? "" : filters["searchCounty"];
    _searchForm.tripStatus = filters["tripStatus"];
    _searchForm.opencorrection = filters["opencorrection"];
    _searchForm.searchChangeSice = filters["searchChangeSince"];
    _searchForm.searchStandingOrder = filters["searchStandingOrder"];
    _searchForm.searchMasDriver = filters["searchMasDriver"];
    _searchForm.searchDKDriver = filters["searchDKDriver"];
    setSearchForm(_searchForm);
    setFetchResults(true);
  }

  async function updateSchedule() {
    let _searchForm = searchForm;
    _searchForm.fetching = true;
    setSearchForm(_searchForm);

    const headers = {
      "Content-Type": "application/json",
      "x-auth-key": user.key,
    };

    var searchServiceStartsFrom = moment(
      searchForm.searchServiceStartsFrom,
      "MM/DD/YYYY"
    ).startOf("day"); // moment().format("MM/DD/YYYY")
    var searchServiceStartsTo = moment(
      searchForm.searchServiceStartsTo,
      "MM/DD/YYYY"
    ).endOf("day");

    //Difference in number of days
    var dayCount = parseInt(
      Math.ceil(
        moment
          .duration(searchServiceStartsTo.diff(searchServiceStartsFrom))
          .asDays()
      )
    );
    if (dayCount > 4) {
      toast.error(
        "You have selected  " +
          dayCount +
          " days , Please max 4 days for live update  ",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      let _searchForm = searchForm;
      _searchForm.fetching = false;
      setSearchForm(_searchForm);
    } else {
      try {
        const response = await axios.post(
          BASE_URL + "/scheduler/updateSchedule",
          searchForm,
          {
            headers: headers,
          }
        );
        // set the state of the user
        //this.setState({ modleProgress : false });
        let _searchForm = searchForm;
        _searchForm.page = await response.data.pagedData.page;
        _searchForm.per_page = await response.data.pagedData.per_page;
        _searchForm.total = await response.data.pagedData.total;
        _searchForm.total_pages = await response.data.pagedData.total_pages;
        _searchForm.fetching = false;
        _searchForm.excelExport = false;
        _searchForm.pdfExport = false;

        if (response.data.fileName) {
          window.open(await response.data.fileName);
        }

        if (response.data.pdfFileName) {
          window.openawait(response.data.pdfFileName);
        }

        setSearchForm(_searchForm);

        setTablerows(await response.data.pagedData);
        await retriveTripScheduleData();
        // }, async () => {
        //   await this.retriveTripScheduleData();
        // });
        // store the user in localStorage
      } catch (error) {
        console.log("error Print");
        if (error.response) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Error ", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  }

  async function fetchResults(firstTime = false) {
    let _searchForm = searchForm;

    _searchForm.fetching = true;

    if (!firstTime) setSearchstring("Fetching data .. Please wait ! ");

    const headers = {
      "Content-Type": "application/json",
      "x-auth-key": user.key,
    };

    try {
      const response = await axios.post(
        BASE_URL + "/scheduler/view",
        _searchForm,
        {
          headers: headers,
        }
      );
      _searchForm.page = await response.data.pagedData.page;
      _searchForm.per_page = await response.data.pagedData.per_page;
      _searchForm.total = await response.data.pagedData.total;
      _searchForm.total_pages = await response.data.pagedData.total_pages;
      _searchForm.fetching = false;
      _searchForm.excelExport = false;
      _searchForm.freshFetch = false;
      _searchForm.pdfExport = false;

      if (response.data.fileName) {
        window.open(await response.data.fileName);
      }

      if (response.data.pdfFileName) {
        window.open(await response.data.pdfFileName);
      }

      setSearchstring(response.data.searchStr);
      setSearchForm(_searchForm);
      let _fullDataSet = await response.data.pagedData;
      console.log("Setting Full Dataset");
      setFullDataSet(_fullDataSet);
      setTablerows(await response.data.pagedData);
      retriveTripScheduleData();
    } catch (error) {
      setSearchstring(searchstring);

      let _searchForm = searchForm;
      _searchForm.fetching = false;
      setSearchForm(_searchForm);
      console.log("error Print", error);
      if (error.response) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Error ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  return (
    <TripScheduleController
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      //   updateState={updateState}
      resetSearchForm={resetSearchForm}
      setSearchOrder={setSearchOrder}
      setSearchFormDate={setSearchFormDate}
      setSearchFormAutoComplete={setSearchFormAutoComplete}
      setSearchFormCustom={setSearchFormCustom}
      handleFilteration={handleFilteration}
      fetchResults={fetchResults}
      setFetchResults={setFetchResults}
      pdfExport={pdfExport}
      excelExport={excelExport}
      syncData={syncData}
      handleChangePage={handleChangePage}
      updateTripSchedule={updateTripSchedule}
      getSelectedInvoice={getSelectedInvoice}
      // setState={this.setState}
      _openSearch={openSearch}
      _tripSortByList={tripSortByList}
      _searchForm={searchForm}
      _countyList={countyList}
      _transportTypeList={transportTypeList}
      _searchstring={searchstring}
      _fetching={fetching}
      _tablerows={tablerows}
      _cars={cars}
      _drivers={drivers}
      _schedules={schedules}
      _user={user}
      _selectedInvoiceOpen={selectedInvoiceOpen}
      _modleProgress={modleProgress}
      _schaduleRetrived={schaduleRetrived}
    />
  );
};

export default TripScheduler;
