import { React, useState, useEffect, useCallback } from "react";
import { Grid } from "@mui/material";

import { attestationList } from "./Attestations";

// import "./roster_planning_styles.css";
import SearchBy from "./SearchBy";
import AttestInvoiceView from "./AttestInvoiceView";

const AttestationController = () => {
  const [notify, seNotify] = useState(null);
  const [uploadView, setUploadView] = useState(true);
  const [searchAttrs, setSearchAttrs] = useState({});

  useEffect(async () => {
    attestationList.registerNotifyAll(seNotify);
    await attestationList.loadAttestations();
  }, []);

  useEffect(() => {
    setUploadView(true);
    attestationList.registerNotifyAll(seNotify);
  }, [notify]);



  const handleOnSearch = useCallback(
    (attr) => {
      setSearchAttrs(attr);
      // setUploadView(false);
    },
    [searchAttrs]
  );

  return (
    <>
      <Grid container className={"attestations_container"}>
        <Grid item sm={12}>
          <SearchBy handleOnSearch={handleOnSearch} uploadView={uploadView}/>
        </Grid>
        <Grid item sm={12}>
          <br></br>
        </Grid>
        <Grid item sm={12}>
            <AttestInvoiceView searchAttrs={searchAttrs} />
        </Grid>
      </Grid>
    </>
  );
};

export default AttestationController;
