import { useEffect, useState } from "react";

// mui
import { Button, IconButton, MenuItem, Select, TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// Helpers
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Icons
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DragIndicator from "@mui/icons-material/DragIndicator";

// Business objects
import { scheduleGroups } from "../scheduleGrouping/ScheduleGroups";

import "./scheduleGroups.css";
import { PanoramaFishEye } from "@mui/icons-material";

export const ScheduleTableRows = (props) => {
  const { groups } = props;
  const [schdules, setSchedules] = useState([]);
  const [pickupTime, setPickupTime] = useState();

  useEffect(() => {
    if (groups.schedules) setSchedules(groups.schedules);

    for (const trip of schdules) {
      console.log(
        trip.id,
        trip.startingdateime,
        "START ->",
        moment(trip.startingdateime).tz("America/New_York").format("HH:mm"),
        trip.startingdateimeformatted
      );
      setPickupTime({
        ...pickupTime,
        [trip.id]: moment(trip.startingdateime)
          .tz("America/New_York")
          .format("HH:mm"),
      });
    }
  }, [groups.schedules]);

  useEffect(() => {
    for (const trip of schdules) {
      console.log(
        trip.id,
        trip.startingdateime,
        moment(trip.startingdateime).tz("America/New_York").format("HH:mm"),
        trip.startingdateimeformatted
      );
      setPickupTime({
        ...pickupTime,
        [trip.id]: moment(trip.startingdateime)
          .tz("America/New_York")
          .format("HH:mm"),
      });
    }
  }, [groups]);

  // This function is used to set all attributes needed to update state to display in screen
  const setScheduleAttributes = (params) => {
    const {
      tripId,
      addressEdit = null,
      addressEdited = null,
      address = null,
      addressMapped = null,
      timeEdited = null,
      timeEdit = null,
      startingdateimeformatted = null,
      startingdateime = null,
      groupId = null,
      moved = null,
      arrange = null,
    } = params;

    if (groupId != null && moved != null && arrange != null) {
      let scheduleToAdd = schdules.find((t) => t.scheduleid == tripId);
      scheduleToAdd.group = groupId;
      scheduleToAdd.moved = moved;
      scheduleGroups.addGroupSchedule(scheduleToAdd, groupId);
      scheduleGroups.removeGroupSchedule(tripId, groups.group.id);
      return;
    }

    let _schdules = schdules.map((t) => {
      if (Number(t.scheduleid) == Number(tripId)) {
        t.addressEdit = addressEdit != null ? addressEdit : t.addressEdit;
        t.address = address != null ? address : t.address;
        t.addressEdited =
          addressEdited != null ? addressEdited : t.addressEdited;
        t.timeEdited = timeEdited != null ? timeEdited : t.timeEdited;
        t.timeEdit = timeEdit != null ? timeEdit : t.timeEdit;
        if (addressMapped) {
          _schdules.address = scheduleGroups.addresses[t.namekey].filter(
            (a) => a.address !== t.address
          )[0].address;
          t.addressEdit = false;
          t.addressEdited = true;
        }
        if (startingdateimeformatted != null) {
          console.log(
            startingdateimeformatted,
            moment(startingdateimeformatted).toISOString()
          );
          t.startingdateimeformatted = startingdateimeformatted;
          console.log(
            moment(startingdateimeformatted).toISOString(),
            "ISO NY ->",
            moment(startingdateime).tz("America/New_York").toISOString()
          );
          t.startingdateime = startingdateime;
        }
      }
      return t;
    });
    // Set current modified schedule to the group in state
    setSchedules(_schdules);
  };

  // Update business objects
  function updateScheduleGrups(_schdules) {
    if (_schdules)
      scheduleGroups.updateGroupSchedule(_schdules, groups.group.id);
    else scheduleGroups.updateGroupSchedule(schdules, groups.group.id);
  }

  function onDragEnd(result) {
    const { source, destination } = result;
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    console.log("RESULT ::", result);
    let _schdules = Array.from(schdules);
    const [reordeItems] = _schdules.splice(source.index, 1);
    _schdules.splice(destination.index, 0, reordeItems);
    let __schdules = _schdules.map((tr, index) => {
      tr.order = index;
      tr.moved = true;
      return tr;
    });
    setSchedules(__schdules);
    updateScheduleGrups(__schdules);
  }

  function handlePickupTime(e, t) {
    let val = e.target.value;
    setPickupTime({ ...pickupTime, [t.id]: val });
  }

  function changeTimeZone(date, timeZone) {
    if (typeof date === "string") {
      return new Date(
        new Date(date).toLocaleString("en-US", {
          timeZone,
        })
      );
    }

    return new Date(
      date.toLocaleString("en-US", {
        timeZone,
      })
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="table">
        {(provided) => (
          <table
            width="100%"
            className="schedule-styled-table"
            style={{
              borderCollapse: "collapse",
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <thead width="100%">
              <tr className="schedule-styled-table-thead-tr">
                <td></td>
                <td>Name</td>
                <td>Contact </td>
                <td>Invoice / Leg</td>
                <td>Pickup Time</td>
                <td>Distance</td>
                <td>Pickup location</td>
                <td>Dropoff location</td>
                {/* <td>Group</td> */}
              </tr>
            </thead>

            {schdules &&
              schdules.map((trip, index) => {
                return (
                  <Draggable
                    draggableId={trip.id.toString()}
                    key={trip.id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <tr ref={provided.innerRef} {...provided.draggableProps}>
                        <td>
                          <IconButton {...provided.dragHandleProps}>
                            <DragIndicator className="schedule-icons" />
                          </IconButton>
                        </td>
                        <td width={"10%"}>
                          {trip.firstname} {trip.lastname}
                        </td>
                        <td>{trip.medicaidNumber}</td>
                        <td width={"10%"}>
                          {trip.invoice} / {trip.leg}
                        </td>
                        <td width={"10%"}>
                          <input
                            className="input-time flex__child"
                            id="start"
                            type="time"
                            defaultValue={moment(trip.startingdateime)
                              .tz("America/New_York")
                              .format("HH:mm")}
                            onChange={(e) => {
                              handlePickupTime(e, trip);
                            }}
                            onBlur={(e) => {
                              {
                                let startingDate = String(
                                  moment(trip.startingdateime)
                                    // .tz("America/New_York")
                                    .format("MM/DD/YYYY") +
                                    " " +
                                    (!pickupTime[trip.id]
                                      ? moment(trip.startingdateime)
                                          // .tz("America/New_York")
                                          .format("HH:mm")
                                      : pickupTime[trip.id])
                                );
                                let startdateTimeSplit =
                                  trip.startingdateime.split("T");
                                let startingDateTime =
                                  startdateTimeSplit[0] +
                                  "T" +
                                  (!pickupTime[trip.id]
                                    ? startdateTimeSplit[1]
                                    : pickupTime[trip.id] + ":00-05:00");
                                setScheduleAttributes({
                                  tripId: trip.scheduleid,
                                  startingdateimeformatted: startingDate,
                                  startingdateime: moment(startingDateTime)
                                    .tz("America/New_York")
                                    .format(),
                                  timeEdit: false,
                                  timeEdited: false,
                                  addressEdited: true,
                                });
                                updateScheduleGrups();
                                // setHideStartTime(false);
                              }
                            }}
                          />
                        </td>
                        <td>{parseFloat(trip.distance).toFixed(2)} Miles </td>
                        <td width={"20%"}>
                          {trip.addressEdit ? (
                            <TextField
                              value={trip.address}
                              inputProps={{
                                style: { width: "80%", fontSize: "12px" },
                              }}
                              onChange={(e) => {
                                setScheduleAttributes({
                                  tripId: trip.scheduleid,
                                  address: e.target.value,
                                });
                              }}
                              onBlur={(e) => {
                                setScheduleAttributes({
                                  tripId: trip.scheduleid,
                                  addressEdit: false,
                                  addressEdited: true,
                                });
                                updateScheduleGrups();
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                width: "100%",
                                flexDirection: "row",
                                display: "flex",
                              }}
                            >
                              <span
                                style={{
                                  flex: "1",
                                }}
                              >
                                {trip.address}
                              </span>
                              <span
                                style={{
                                  justifyContent: "flex-end",
                                  textAlign: "end",
                                  flex: "0 0 50px",
                                }}
                              >
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    setScheduleAttributes({
                                      tripId: trip.scheduleid,
                                      addressEdit: true,
                                    });
                                  }}
                                >
                                  <EditIcon className="schedule-icons" />
                                </IconButton>
                              </span>
                              <span>
                                {trip.leg % 2 == 1 &&
                                scheduleGroups.addresses &&
                                trip.namekey &&
                                scheduleGroups.addresses[trip.namekey] ? (
                                  scheduleGroups.addresses[trip.namekey].filter(
                                    (a) => a.address !== trip.address
                                  ).length > 0 ? (
                                    <IconButton
                                      className="schadule-address-btn"
                                      size="small"
                                      title={
                                        scheduleGroups.addresses[
                                          trip.namekey
                                        ].filter(
                                          (a) => a.address !== trip.address
                                        )[0].address
                                      }
                                      onClick={(e) => {
                                        setScheduleAttributes({
                                          tripId: trip.scheduleid,
                                          addressMapped: true,
                                        });
                                      }}
                                    >
                                      <InfoOutlinedIcon className="schedule-icons" />
                                    </IconButton>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          )}
                        </td>
                        <td>{trip.dropaddress} </td>
                        {provided.placeholder}
                      </tr>
                    )}
                  </Draggable>
                );
              })}
          </table>
        )}
      </Droppable>
    </DragDropContext>
  );
};
