import React, { useState } from "react";

export const useForm = (initialFormValues, validateOnChange = false) => {
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});

  const handleOnChange = (e, elem, elemVal) => {
    console.log(e, elem, elemVal)
    if (elem && elemVal) {
      setValues({
        ...values,
        [elem]: elemVal,
      });
    } else {
      let { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
    }
    if (validateOnChange) {
      //   validate({ [name]: value });
    }
  };

  const resetForm = () => {
    setValues(initialFormValues);
    Array.from(document.querySelectorAll("input")).forEach(
      (input) =>
        (input.value =
          initialFormValues[input.name] != undefined
            ? initialFormValues[input.name]
            : input.value)
    );
    // setErrors({});
  };

  return {
    values,
    setValues,
    handleOnChange,
    resetForm,
  };
};
