
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";


const StyledFrame = styled(Paper)(({theme}) =>({
    backgroundColor:theme.palette.background.frame,
    borderColor: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
    width:"100%",
    padding: theme.spacing(1),
  }));


  export default StyledFrame;