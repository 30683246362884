import React from "react";

import moment from "moment";

import "react-toastify/dist/ReactToastify.css";

import { Alert } from "@mui/material";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

function ScheduleHeader({ row, user }) {
  return (
    <>
      {" "}
      <TableRow
        key={row.name}
        sx={{
          display: { xl: "table-row", md: "table-row", sm: "none", xs: "none" },
        }}
        className="schedule-inv-row"
      >
        <TableCell
          align="right"
          className="trip-results-data-cell-head"
          style={{ border: "1px solid #dee2e6   !important" }}
        >
          <span>
            {row.invoiceNumber}
            <hr style={{ borderTop: "1px solid rgba(0,0,0,.1)" }} />
            {row.exAcpriorAuthorizationNumber != null &&
            row.exAcpriorAuthorizationNumber !== "" ? (
              +row.exAcpriorAuthorizationNumber
            ) : (
              <br />
            )}
            <hr style={{ borderTop: "1px solid rgba(0,0,0,.1)" }} />
            {row.standingOrderEndDate != null && row.standingOrderEndDate !== ""
              ? moment(row.standingOrderEndDate).format("MM/DD/YYYY")
              : ""}
          </span>
          {row.exAcattested === 1 &&
          (row.exAcpriorAuthorizationNumber === "" ||
            row.exAcpriorAuthorizationNumber == null) ? (
            <>
              <Alert severity="warning">PA#</Alert>
            </>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell align="right" className="trip-results-data-cell-head">
          {row.isStandingOrderTrip == 1 ? "SO" : "MO"}
        </TableCell>
        <TableCell align="right" className="trip-results-data-cell-head">
          {row.status}
        </TableCell>

        <TableCell align="right" className="trip-results-data-cell-head">
          {row.medicaidNumber}
        </TableCell>

        <TableCell align="right" className="trip-results-data-cell-head">
          {row.firstName} {row.lastName}
        </TableCell>

        {user.userlevel == 1 ? (
          <TableCell align="right" className="trip-results-data-cell-head">
            $ {parseFloat(row.totalTripCost).toFixed(2)}
          </TableCell>
        ) : null}
        <TableCell align="right" className="trip-results-data-cell-head">
          {parseFloat(row.calculatedTripMileage).toFixed(2)} Ml.
        </TableCell>

        <TableCell align="right" className="trip-results-data-cell-head">
          <div>{moment(row.pickupDate).format("MM/DD/YYYY")}</div>
          <div>{moment(row.pickupTime, "HH:mm").format("hh:mm A")}</div>
        </TableCell>
        {/* <TableCell align="right" className="trip-results-data-cell-head">
          {row.triplegs.length}
        </TableCell> */}
        <TableCell align="right" className="trip-results-data-cell-head">
          {row.medicaidCountyName}
        </TableCell>
        <TableCell align="right" className="trip-results-data-cell-head">
          <div>{moment(row.originalCreateddate).format("MM/DD/YYYY")}</div>
          <div>{moment(row.originalCreateddate).format("hh:mm A")}</div>
        </TableCell>
      </TableRow>
    </>
  );
}
export default ScheduleHeader;
