import { useEffect, useState } from "react";

// mui
import { Button, IconButton, TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// Helpers
import moment from "moment";
import { toast } from "react-toastify";

// Icons
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

// components
import { ScheduleTableRows } from "./scheduleTableRows";

// Business Logic Object
import { scheduleGroups } from "../scheduleGrouping/ScheduleGroups";

import "./scheduleGroups.css";

export const ShceduleGroupsTable = () => {
  const [groups, setGroups] = useState([]);
  const [hideStartTime, setHideStartTime] = useState(false);
  const [hideEndTime, setHideEndTime] = useState(false);
  const [hideFromAddress, setHideFromAddress] = useState(false);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  useEffect(() => {
    setHideStartTime(false);
    setHideEndTime(false);
    setHideFromAddress(false);
  }, []);

  useEffect(() => {
    console.log("GROUPES >>>> ", scheduleGroups.groups);
    setGroups(scheduleGroups.groups);
    setStartTime(scheduleGroups.startingdateime);
    setEndTime(scheduleGroups.endingdatetime);
  }, [scheduleGroups.groups]);

  // This function is used to set all attributes needed to update state to display in screen
  const setGroupAttributes = (params) => {
    const {
      id,
      display = null,
      edited = null,
      fromEdit = null,
      nameEdit = null,
      title = null,
      timeEdited = null,
      toEdit = null,
      updated = false,
      startingdateime = null,
      endingdatetime = null,
    } = params;
    let _scheduleGroups = groups.map((g) => {
      if (Number(g.group.id) == Number(id)) {
        g.nameEdit = nameEdit != null ? nameEdit : g.nameEdit;
        g.edited = edited != null ? edited : g.edited;
        g.fromEdit = fromEdit != null ? fromEdit : g.fromEdit;
        g.timeEdited = timeEdited != null ? timeEdited : g.timeEdited;
        g.toEdit = toEdit != null ? toEdit : g.toEdit;
        g.group.title = title != null ? title : g.group.title;
        g.group.startingdateime =
          startingdateime != null ? startingdateime : g.group.startingdateime;
        g.group.endingdatetime =
          endingdatetime != null ? endingdatetime : g.group.endingdatetime;
        if (display != null) {
          if (g.schedules.length > 0) {
            removeGroupErrorMsg(g.schedules.length, g.group.title);
          } else {
            g.display = display;
          }
        }
        return g;
      }
      return g;
    });
    setGroups(_scheduleGroups);
  };

  // Update business objects
  function updateScheduleGrups() {
    scheduleGroups.groups = groups;
    scheduleGroups.notifyAll({ groups: groups });
  }

  function handleStartTime(e, g) {
    let val = e.target.value;
    setStartTime(val);
  }

  function handleEndTime(e, g) {
    let val = e.target.value;
    setEndTime(val);
  }

  const removeGroupErrorMsg = (length, title) => {
    toast.error(`Please remove ${length} assignment(s) from ${title} ! `, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      {groups
        ? groups
            .filter((g) => g.display)
            .map((g, index) => (
              <table width={"100%"} className="group-trip-container">
                <tbody>
                  <tr
                    style={{
                      backgroundColor: "#19d28b",
                      fontSize: "14px",
                      fontWeight: "bolder",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "5px",
                    }}
                  >
                    <td width={"20%"}>
                      {g.nameEdit ? (
                        <>
                          <TextField
                            value={g.group.title}
                            className="Schedule-edit-input"
                            onChange={(e) => {
                              setGroupAttributes({
                                id: g.group.id,
                                edited: true,
                                title: e.target.value,
                                updated: true,
                              });
                            }}
                            onBlur={(e) => {
                              setGroupAttributes({
                                id: g.group.id,
                                nameEdit: false,
                              });
                              updateScheduleGrups();
                              setHideFromAddress(false);
                            }}
                          />
                        </>
                      ) : (
                        <span>
                          {g.group.title}
                          {!hideFromAddress ? (
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              size="smal"
                              onClick={(e) => {
                                setGroupAttributes({
                                  id: g.group.id,
                                  nameEdit: true,
                                });
                                setHideFromAddress(true);
                              }}
                            >
                              <EditIcon
                                variant="social"
                                className="schedule-icons"
                              />
                            </IconButton>
                          ) : null}
                        </span>
                      )}
                    </td>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "bottom",
                      }}
                      width={"40%"}
                      colSpan={3}
                    >
                      <div>{moment(g.group.tripdate).format("MM/DD/YYYY")}</div>
                      <div>
                        <label for="start">
                          Start :{" "}
                          {moment(g.group.startingdateime).format("HH:mm A")}
                        </label>
                        {/* <input
                          className="input-time flex__child"
                          id="start"
                          type="time"
                          defaultValue={moment(g.group.startingdateime).format(
                            "HH:mm"
                          )}
                          onChange={(e) => {
                            console.log(g.group);
                            handleStartTime(e, g);
                          }}
                          onBlur={(e) => {
                            {
                              let startingDate = moment(
                                moment(g.group.startingdateime)
                                  .tz("America/New_York")
                                  .format("MM/DD/YYYY") +
                                  " " +
                                  (!startTime
                                    ? moment(g.group.startingdateime)
                                        .tz("America/New_York")
                                        .format("HH:mm")
                                    : startTime)
                              ).toISOString();
                              setGroupAttributes({
                                id: g.group.id,
                                fromEdit: false,
                                edited: true,
                                startingdateime: startingDate,
                              });
                              updateScheduleGrups();
                              setHideStartTime(false);
                            }
                          }}
                        /> */}
                      </div>
                      <div>
                        <label for="end">
                          End :{" "}
                          {moment(g.group.endingdatetime).format("HH:mm A")}
                        </label>
                        {/* <input
                          className="input-time flex__child"
                          id="end"
                          type="time"
                          defaultValue={moment(g.group.endingdatetime).format(
                            "HH:mm"
                          )}
                          onChange={(e) => {
                            handleEndTime(e, g);
                          }}
                          onBlur={(e) => {
                            {
                              let endingTime = moment(
                                moment(g.group.endingdatetime)
                                  .tz("America/New_York")
                                  .format("MM/DD/YYYY") +
                                  " " +
                                  (!endTime
                                    ? moment(g.group.endingdatetime)
                                        .tz("America/New_York")
                                        .format("HH:mm")
                                    : endTime)
                              ).toISOString();
                              setGroupAttributes({
                                id: g.group.id,
                                toEdit: false,
                                edited: true,
                                endingdatetime: endingTime,
                              });
                              updateScheduleGrups();
                              setHideEndTime(false);
                            }
                          }}
                        /> */}
                      </div>
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <IconButton
                        color="warning"
                        aria-label="upload picture"
                        component="label"
                        onClick={(e) => {
                          setGroupAttributes({
                            id: g.group.id,
                            display: false,
                          });
                        }}
                      >
                        <RemoveCircleIcon
                          variant="social"
                          className="schedule-icons"
                        />
                      </IconButton>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={5} width="100%">
                      <ScheduleTableRows groups={g} key={g.group.id} />
                    </td>
                  </tr>
                </tbody>
              </table>
            ))
        : null}
    </>
  );
};
