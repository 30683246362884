import { React, useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  ClickAwayListener,
  Grid,
  LinearProgress,
  Button,
  IconButton,
} from "@mui/material";
import { attestationList } from "./Attestations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid";
import moment from "moment"
import { makeStyles } from "@mui/styles";
import { darken, lighten, styled } from "@mui/material/styles";


import AttestationConfirmationDialog from "./components/Dialog/AttestationConfirmationDialog";
import AttestInvoiceStatusDialog from "./components/Dialog/AttestInvoiceStatusDialog";
import { StyledAvatar } from "../../styled";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const momentTZ = require('moment-timezone');

const datagridSx = {
  borderRadius: 2,
  "& .MuiDataGrid-main": { borderRadius: 2 },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#f5fafe",
    color: "#033d5f",
    fontWeight: "bold !important",
    fontSize: 14,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold !important",
    color: "#033d5f !important",
    overflow: "visible",
    lineHeight: "1.43rem",
    whiteSpace: "normal",
  },
  "& .MuiDataGrid-cell": {
    paddingTop: 3,
    paddingBottom: 3,
  },
};

const useStyle = makeStyles({
  root: {
    "& .wrapHeader .MuiDataGrid-colCellTitle": {
      overflow: "visible",
      lineHeight: "1.43rem",
      whiteSpace: "normal",
    },
  },
  red: {
    backgroundColor: "red",
  },
});

const getBackgroundColor = (color, mode) =>
  {
    return (mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7));
  }
const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const AttestationDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .planner-view-grid--O-0": {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.light,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.light,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.light,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.light,
          theme.palette.mode
        ),
      },
    },
  },
  "& .planner-view-grid--O-1": {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode
        ),
      },
    },
  },

  "& .planner-view-grid--O-3": {
    backgroundColor: getBackgroundColor(
      theme.palette.secondary.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.secondary.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.main,
          theme.palette.mode
        ),
      },
    },
  },
})); 




function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
      <GridToolbarExport
        csvOptions={{
          allColumns: true,
          fileName: `${moment(new Date()).format(
            "YYYY-MM-DD HH:mm:ss"
          )}_mas_roster`,
        }}
      />
    </GridToolbarContainer>
  );
}



const AttestInvoiceView = (props) =>{
    const classes = useStyle()

    const [showUpateConfimation, setShowUpateConfimation] = useState(false);
    const [showAttestionStatus,setShowAttestationStatus] = useState(false);
    const [attest, setAttest] = useState(false);
    const [attestationData, setAttestationData] = useState({});
    const [attestationEnvironment, setAttestationEnvironment] = useState('Prod');
    const [attestationResponse, setAttestationResponse] = useState({});
    const [rows, setRows] = useState([]);
    const [masDrivers, setMasDrivers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [sendBreadcrumbs, setSendBreadcrumbs] = useState(false);
    const [selectedIds, setSelectedIds] = useState({});
    const [messageType, setMessageType] = useState("attest & invoice");
    const [reload, setReload] = useState(true);

    useEffect(()=>{
      async function attestInvoice (){
        if(attest) {
          try{

            const response = await attestationList.attestInvoice(attestationEnvironment,attestationData.type, 
                attestationData.invoiceNumbers, 
                attestationData.tripDate, 
                attestationData.drivers);

            if(response.status == 200) {
              let invoices = [];
              for(let inv of attestationData.invoiceNumbers){
                invoices.push(inv.invoiceNumber)
              }
              setAttestationResponse({
                response:response.data,
                timtDelta:new Date().getTime()
              });
              setShowAttestationStatus(true);
            }
          }catch (error){
            console.log(error)
            toast.error(`[Error] ${error.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        // setAttestationData({});
      }
      setAttest(false);
    }
    attestInvoice();
    },[attest , attestationEnvironment])


    useEffect(()=>{
      async function initSendBreadcrumbs (){
        if(sendBreadcrumbs) {
          try{

            const response = await attestationList.sendBreadcrumbs(attestationEnvironment,attestationData.type, 
                attestationData.invoiceNumbers, 
                attestationData.tripDate, 
                attestationData.drivers);

            if(response.status == 200) {
              let invoices = [];
              for(let inv of attestationData.invoiceNumbers){
                invoices.push(inv.invoiceNumber)
              }
              setAttestationResponse({
                ...attestationResponse,
                breadcrumbs:response.data,
                timtDelta:new Date().getTime()
              });
              setShowAttestationStatus(true);
            }
          }catch (error){
            console.log(error)
            toast.error(`[Error] ${error.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
          }
          setSendBreadcrumbs(false);
      }
      initSendBreadcrumbs();
    },[sendBreadcrumbs])

    useEffect(() => {
      async function getMasDrivers() {
        await attestationList
          .fetchMasDrivers()
          .then((res) => {
            setMasDrivers(res);
          })
          .catch((error) => {
            setMasDrivers([]);
          });
      }
      getMasDrivers();
    }, []);

    useEffect(()=>{
      console.log(props, reload)
      setReload(props.searchAttrs.reload)
    },[props]);

    useEffect(() => {
      
      if(reload){
        if (Object.keys(props.searchAttrs).length === 0) {
          attestInvoiceData({
            tripDate: moment(new Date()).format("YYYY-MM-DD"),
          });
        } else {
          attestInvoiceData({
            tripDate: props.searchAttrs.tripDate,
            invoiceNo: props.searchAttrs.invoiceNo,
          });
        }
        setReload(false);
      }
    }, [reload]);

    const attestInvoiceData = useCallback(async (searchAttrs) => {
      setRows([]);
      setLoading(true);
      let _invoiceNumbers = []
      let _rows = []
      let invoice = {}
      let invoiceNumber = null;
      let prevInvoiceNumber = null;
      await attestationList
        .getAttestInvoiceData(searchAttrs)
        .then((res) => {
          res.forEach((rec) => {
            invoiceNumber = rec.invoiceNumber
            if (invoiceNumber !=prevInvoiceNumber) {
              if(invoice.invoiceNumber){
                _rows.push(invoice)
                invoice = {}
              }
              
              invoice.invoiceNumber = rec.invoiceNumber;
              invoice.combinewith = "";
              invoice.tripDate = rec.tripDate;

              invoice.pickup_dropoff_address= [];
              invoice.pickuplatlng_dropofflatlng = [];
              invoice.mas_pickup_dropoff_time = [];
              invoice.act_pickup_dropoff_time = [];
              invoice.driverName = [];
              invoice.vehicle = [];
              invoice.id = rec.id;
              
            } 
            // create a check to know if enable or dissable attestation / no show
            {
              const dropOffDateTime = moment(`${moment(rec.tripDate).format("MM-DD-YYYY")} 
                                              ${rec.dropoff_time!=null?moment(rec.dropoff_time).format("HH:mm:ss"):
                                              rec.masDropoffTime.length!=0?moment(rec.masDropoffTime,'HHmm').format("HH:mm:ss"):
                                              moment(rec.masPickupTime,'HHmm').add(2, 'hours').format("HH:mm:ss")}`);
              // const currDateTime = moment(momentTZ.tz(moment(), 'America/New_York')).format('DD/MM/YYYY HH:mm');
              const currDateTime = moment();
              // console.log(dropOffDateTime, currDateTime, rec);
              rec.diff = currDateTime.diff(dropOffDateTime,"minutes")
            }
            rec.pickup_dropoff_address = `${rec.pickaddress}|||${rec.dropoffAddress}`;
            rec.mas_pickup_dropoff_time = `${rec.masPickupTime}|||${rec.masDropoffTime}`;
            rec.act_pickup_dropoff_time = `${rec.pickup_time!=null?moment(rec.pickup_time).format("HH:mm:ss"):"-"}
                                          |||${rec.dropoff_time!=null?moment(rec.dropoff_time).format("HH:mm:ss"):"-"}`;
            rec.tripDate = moment(rec.tripDate).format("MM-DD-YYYY");
            rec.combinewith = "";
            rec.scheduled = rec.pickup_time == null?false:true;
            rec.driverName = rec.driverName;
            rec.driverNameFormatted = rec.driverName || rec.driverName != null?rec.driverName.replaceAll(" ","").toLowerCase():rec.driverName;
            invoice.pickup_dropoff_address.push(rec.pickup_dropoff_address);
            invoice.mas_pickup_dropoff_time.push(rec.mas_pickup_dropoff_time)
            invoice.act_pickup_dropoff_time.push(rec.act_pickup_dropoff_time)
            invoice.driverName.push(rec.driverName);
            invoice.vehicle.push(rec.vehicle)
            prevInvoiceNumber = rec.invoiceNumber;
          });
          // setInvoiceNumbers(_invoiceNumbers)
          // console.log(res)
          setRows([...res]);
        })
        .catch((error) => {
          setRows([]);
          console.log(error)
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);

    
    const columns = useMemo(
    () => [
      {
        headerName: "Actions",
        field: "actions",
        type: "actions",
        width: 100,
        height:100,
        renderCell: (params) => {
          const status = params.row.status;
          const stat_arr = ['Eligible', 'Cancelled/Combined'];
          console.log(' breadCrumbsSent - > ', params.row?.breadCrumbsSent)
          return (
            <div style={{ paddingLeft:5,  paddingTop: 4, paddingBottom: 4, width:100, display:"flex"}}>
                <div style={{marginLeft:5, width:30}}>
                { stat_arr.includes(status) && (
                    <IconButton
                        variant="contained"
                        size="medium"
                        color={ params.row?.breadCrumbsSent == 1 ? "white":"primary"}
                        style={{ width:10}}
                        tabIndex={params.hasFocus ? 0 : -1}
                        disabled={!params.row.scheduled  || params.row.driverName == null ?true:false}
                        onClick={(e)=>{handleAttestInvoice('Attest',params.row.invoiceNumber, params.row.triplegId, params.row.tripDate)}}
                        >
                        {/* <Check /> */}
                        <StyledAvatar 
                        // enabled={params.row.scheduled || params.row.driverName == null ?false:true} 
                        success={params.row?.breadCrumbsSent == 1}
                        enabled={true}
                        // success={true}
                        alt="Attest">SB</StyledAvatar>
                    </IconButton> 
                  )
                }
              </div>
              <div style={{width:30}}>
                <IconButton
                      variant="contained"
                      size="medium"
                      color={ params.row?.attested > 0 ?"white":"primary"}
                      style={{ width:10}}
                      tabIndex={params.hasFocus ? 0 : -1}
                      onClick={(e)=>{handleAttestInvoice('Cancel',params.row.invoiceNumber, params.row.triplegId, params.row.tripDate)}}>
                  
                  {/* <Cancel /> */}
                  <StyledAvatar success={params.row.status == 'Cancelled'} enabled={true} alt="Cancel">C</StyledAvatar>
                </IconButton> 
              </div>
              <div style={{width:30}}>
                <IconButton
                      variant="contained"
                      size="medium"
                      color={ params.row?.attested > 0 ?"white":"primary"}
                      style={{ width:10}}
                      tabIndex={params.hasFocus ? 0 : -1}
                      disabled={!params.row.scheduled || params.row.driverName == null ||  (params.row.attested == 1 && params.row.driverMatch == 0)?true:false}
                      onClick={(e)=>{handleAttestInvoice('NoShow',params.row.invoiceNumber, params.row.triplegId, params.row.tripDate)}}>
                  {/* <NoShow /> */}
                  <StyledAvatar success={(params.row.status == 'Cancelled' && params.row.tripLegStatus == '2')} enabled={!params.row.scheduled || (params.row.attested == 1 && params.row.driverMatch == 0)?false:true} alt="No Show" >N</StyledAvatar>
                </IconButton> 
              </div>
              <div style={{ width:30}}>
                { stat_arr.includes(status) && (
                    <IconButton
                        variant="contained"
                        size="medium"
                        color={ params.row?.attested > 0 ?"white":"primary"}
                        style={{  width:10}}
                        tabIndex={params.hasFocus ? 0 : -1}
                        disabled={!params.row.scheduled || (params.row.attested == 1 && params.row.driverMatch == 0)?true:false}
                        onClick={(e)=>{handleAttestInvoice('Invoice',params.row.invoiceNumber, params.row.triplegId, params.row.tripDate)}}
                        >
                        {/* <Check /> */}
                        <StyledAvatar success={(params.row.mas_status != null && params.row.mas_status == 'Invoice')} enabled={!params.row.scheduled || (params.row.attested == 1 && params.row.driverMatch == 0)?false:true} alt="Invoice">I</StyledAvatar>
                    </IconButton> 
                  )
                }
              </div>
            </div>
          );
        },
      },
      {
        field: "invoiceNumber",
        headerName: "Invoice Number / PA ",
        width: 120,
        editable: false,
        sortable: true,
        renderCell: (params) => {

          let invoiceNumber = params.row.invoiceNumber;
          let PAnumber = params.row.PAnumber;
          return (
                   
                  <div style={{ paddingTop: 4, paddingBottom: 4, borderBottom:1 }}>
                    <div style={{ marginBottom: 5 }}>
                     
                      { invoiceNumber} /
                    </div>
                    <div>
                     {PAnumber}
                    </div>
                </div>
          );
        },
        
      },
      {
        field: "triplegId",
        headerName: "LegId",
        type: "number",
        width: 120,
        editable: false,
        sortable: true,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "tripDate",
        headerName: "Date",
        type: "number",
        width: 120,
        editable: false,
        sortable: true,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "customerName",
        headerName: "Customer",
        width: 150,
        editable: false,
        sortable: true,
      },
      {
        headerName: "Pickup / Dropoff Address",
        field: "pickup_dropoff_address",
        sortable: true,
        editable: false,
        width: 320,
        renderCell: (params) => {

          const parts = params.row.pickup_dropoff_address.split("|||");
          return (
                   
                  <div style={{ paddingTop: 4, paddingBottom: 4, borderBottom:1 }}>
                    <div style={{ marginBottom: 5 }}>
                      <strong style={{ color: "#033d5f" }}>Pickup : </strong>
                      { parts[0]}
                    </div>
                    <div>
                      <strong style={{ color: "#033d5f" }}>Dropoff : </strong>
                      { parts.length > 0 ?  parts[1] : ""}
                    </div>
                </div>
          );
        },
      },
      {
        field: "mas_pickup_dropoff_time",
        headerName: "MAS Time",
        sortable: true,
        editable: false,
        width: 150,
        renderCell: (params) =>{
          const parts = params.row.mas_pickup_dropoff_time.split("|||");
          return (
            <div style={{ paddingTop: 4, paddingBottom: 4 }}>
              <div style={{ marginBottom: 5 }}>
                <strong style={{ color: "#033d5f" }}>Pickup : </strong>
                {parts[0]}
              </div>
              <div>
                <strong style={{ color: "#033d5f" }}>Dropoff : </strong>
                {parts.length > 0 ? parts[1] : ""}
              </div>
            </div>
          )
        }
      },
      {
        field: "act_pickup_dropoff_time",
        headerName: "Act. Time",
        sortable: true,
        editable: false,
        width: 150,
        renderCell: (params) =>{
          const parts = params.row.act_pickup_dropoff_time.split("|||");
          return (
            <div style={{ paddingTop: 4, paddingBottom: 4 }}>
              <div style={{ marginBottom: 5 }}>
                <strong style={{ color: "#033d5f" }}>Pickup : </strong>
                {parts[0]}
              </div>
              <div>
                <strong style={{ color: "#033d5f" }}>Dropoff : </strong>
                {parts.length > 0 ? parts[1] : ""}
              </div>
            </div>
          )
        }
      },
      {
        field: "driverNameFormatted",
        headerName: "Driver / Vehicle",
        sortable: true,
        editable: false,
        width: 200,
        type: "singleSelect",
        renderCell: (params) =>{
          return (
            <div style={{ paddingTop: 4, paddingBottom: 4 }}>
              <div style={{ paddingTop: 4, paddingBottom: 4 }}>
                  {params.row.driverName} &nbsp;-&nbsp;{params.row.vehicle}&nbsp; 
              </div>
              {
                params.row.attested == 1  && params.row.driverMatch == 0?
                  <div style={{ paddingTop: 4, paddingBottom: 4 }}>
                    <strong style={{ color: "#033d5f" }}>Attested: </strong> {params.row.attestedDriver} &nbsp;-&nbsp;{params.row.attestedVehicle}&nbsp; 
                  </div>
                :null
              }
            </div>
          )
        },
        valueOptions: [
          ...new Set(masDrivers.map((option) => option.firstname.replaceAll(" ","").toLowerCase())),
        ],
      },
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        editable: false,
        width: 120,
        type: "action",
        // valueOptions: ["Assigned", "Cancel", "No show", "Unassigned"],
      },
      // {
      //   field: "combinewith",
      //   headerName: "Combine",
      //   sortable: true,
      //   editable: true,
      //   width: 120,
      //   type: "text",
      // },
      {
        headerName: "Select",
        // field: "select",
        type: "actions",
        width: 100,
        height:100,
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
      },
    ],
    [masDrivers]
  );


  const processRowUpdate = useCallback(
    (newRow) => {
      const index = rows.findIndex((row) => row.id === newRow.id);
      const tempRows = [...rows];
      tempRows[index] = newRow;
      setRows(tempRows);
   
      return newRow;
    },
    [rows, masDrivers]
  );

  const handleMultiAttestInvoice = async (type)=>{
    return new Promise(async (resolve, reject)=>{
      let invoiceNumbers = []
      let tripDate = null;
      let drivers = new Set();
      for(let id of selectedIds){
        let row = rows.find((r)=> r.id ===id)
        if(row){
          invoiceNumbers.push({
            invoiceNumber:row.invoiceNumber,
            triplegId:row.triplegId,
          });
          tripDate = row.tripDate;
          drivers.add(row.driver_id)
        }
      }
      // console.log([...drivers].join(','))
      try{
          setAttestationData({
            type:type,
            invoiceNumbers:invoiceNumbers,
            tripDate:tripDate,
            drivers: drivers.size ==0? null:[...drivers].join(',')
          });
          setMessageType(`${type} and invoice `)
          handleShowUpateConfimation();
        } catch(error) {
          toast.error(`[Error] ${error.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      resolve(true);
    });
  }

  const handleAttestInvoice = async (type, invoiceNumber, triplegId, tripDate) => {
    return new Promise(async (resolve, reject) =>{
        try{
          let invoiceNumbers = [
            {
              invoiceNumber:invoiceNumber,
              triplegId:triplegId,
            }
          ]

          setAttestationData({
            type:type,
            invoiceNumbers:invoiceNumbers,
            tripDate:tripDate,
            drivers: null
          })
          setMessageType(`${type} `)
          handleShowUpateConfimation();
          // await attestInvoice(type, invoiceNumbers, tripDate, drivers)
        } catch(error) {
          // console.log(error)
          toast.error(`[Error] ${error.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
     })    
    }
  

  const handleShowUpateConfimation = () => {
    setAttestationResponse({})
    setShowUpateConfimation(true);

  }

  const handleCloseConfimation = (actionType) => {
    setShowUpateConfimation(false);
    if(actionType == "YES") {
      setAttest(true)
    } else{
      setAttest(false)
    } 
  }



  const handleProcessRowUpdateError = useCallback((error) => {
    toast.error(`[Error] ${error.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }, []);


  
  return (
    <Grid container>
      <ToastContainer />
        {
          showUpateConfimation && <AttestationConfirmationDialog 
                                    showUpateConfimation =  {showUpateConfimation}
                                    handleCloseConfimation = {handleCloseConfimation}
                                    messageType = {messageType}
                                  />
        }
        {
          showAttestionStatus && <AttestInvoiceStatusDialog 
                                    showAttestionStatus = {showAttestionStatus}
                                    setShowAttestationStatus = {setShowAttestationStatus}
                                    attestationResponse={attestationResponse}
                                    attestationData={attestationData}
                                    setAttestationEnvironment={setAttestationEnvironment}
                                    setAttest={setAttest}
                                    setSendBreadcrumbs={setSendBreadcrumbs}
                                    setAttestationData={setAttestationData}
                                    setReload={setReload}
                                  />
        }
      <Grid container>
        <Box sx={{ width: "100%" }}>
          <ClickAwayListener onClickAway={() => setRowSelectionModel([])}>
            <AttestationDataGrid
              rowHeight={50}
              rows={rows}
              
              columns={columns}
              sx={datagridSx}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
                setSelectedIds(new Set(newRowSelectionModel));
              }}
              rowSelectionModel={rowSelectionModel}
              slots={{
                loadingOverlay: LinearProgress,
                toolbar: CustomToolbar,
              }}
              loading={loading}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              pageSizeOptions={[25]}
              autoHeight
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              getRowClassName={(params) =>
                `planner-view-grid--O-${
                  params.row?.attested==1 && params.row?.driverMatch == 0 ? 0 : 2
                }`
              }
              getRowHeight={() => "auto"}
              checkboxSelection disableRowSelectionOnClick
              isRowSelectable={(params) =>{
                return (params.row?.diff < 0 || params.row?.driverName == null?false:true)
              }}
            />
          </ClickAwayListener>
        </Box>
      </Grid>
      {rows?.length > 0 && (
        <Grid item sm={12} className={"attestations_container_button"}>
          <Button 
            variant="contained" 
            onClick={()=>{handleMultiAttestInvoice('Attest')}}>
            Send Breadcrumbs
          </Button>
          <Button 
            variant="contained" 
            onClick={()=>{handleMultiAttestInvoice('Invoice')}}>
            Invoice Selected
          </Button>
          <Button variant="contained"
            onClick={()=>{handleMultiAttestInvoice('Cancel')}}>
            Cancel Selected
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default AttestInvoiceView;