
import { styled } from "@mui/material/styles";
import {  CircularProgress} from "@mui/material";


const StyledWrapper = styled('div')(({theme}) =>({
  borderColor: theme.palette.secondary.main,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor:theme.palette.background.default,
  width:"20%",
}))

const StyledCircularProgress = ({theme, message})=>{
    return (<StyledWrapper><CircularProgress /> {message}</StyledWrapper>)
};


  export default StyledCircularProgress;