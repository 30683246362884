import React, { Component } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

class CustomRadioGroup extends Component {
  constructor(props) {
    super(props);
    let url = new URL(window.location.href);

    this.state = {};
  }
  componentDidMount() {
    let user = { active: false, name: "" };
    if (localStorage.getItem("session")) {
      user = JSON.parse(localStorage.getItem("session"));
    }
    this.props.onChange(this.props.idPrefix, this.props.default);
  }

  render() {
    return (
      <>
        <FormControl
          style={{
            marginRight: "10px !important",
          }}
        >
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={this.props.default}
            name="radio-buttons-group"
            className="input-radio-group"
          >
            {this.props.options.map((value, index) => {
              return (
                <FormControlLabel
                  key={index}
                  className="cust-radio-froup"
                  onClick={() => {
                    this.props.onChange(this.props.idPrefix, value.id);
                  }}
                  onBlur={() => {
                    try {
                      this.props.onBlur(this.props.handleBlur);
                    } catch (err) {
                      console.log("ERROR IN BLUR FUNCTION");
                    }
                  }}
                  id={this.props.idPrefix + "-" + value.id}
                  value={value.id}
                  control={<Radio />}
                  label={value.value}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </>
    );
  }
}

export default CustomRadioGroup;
