
import MuiAvatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";


const StyledAvatar = styled(MuiAvatar)(({theme, enabled, success}) =>({
    backgroundColor: !enabled?theme.palette.dissabled:success?theme.palette.secondary.main:theme.palette.secondary.error,
    width:30,
    height:30,
    padding: theme.spacing(1),
    ...theme.typography.h6,
  }));


  export default StyledAvatar; 