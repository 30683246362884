import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";


const StyledSelect = styled(Select)(({theme}) =>({
    border:'1px solid',
    borderColor: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    width:"100%",
    padding: theme.spacing(0.25),
    marginTop: theme.spacing(0),
  }));


  export default StyledSelect;