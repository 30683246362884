import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { DialogActions, Grid } from '@mui/material';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const TripStatusSummaryByDriver = ({ open, handleClose, data }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle sx={{ fontWeight: 'bold' }}>Trip Status Summary By Driver</DialogTitle>
      <DialogContent>
        <Grid container>
            <Grid item xs={12} md={12} lg={12} height={300}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="driver_name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="Assigned" stackId="a" fill="#85A7D0" />
                    <Bar dataKey="Started" stackId="a" fill="#FF0000" />
                    <Bar dataKey="Ended" stackId="a" fill="#87B5A2" />
                    <Bar dataKey="Rejected" stackId="a" fill="#A9A9A9" />
                    </BarChart>
                </ResponsiveContainer>
            </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TripStatusSummaryByDriver;
