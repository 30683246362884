import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CircularProgress,
  Grid,
  LinearProgress,
  Modal,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ScheduleSearch from "./scheduleSearch";
import ScheduleTableHead from "./scheduleTableHead";
import ScheduleHeader from "./scheduleHeader";
import ScheduleEditor from "./scheduleEditor";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CardRoot = styled(Card)(() => ({
  height: "100%",
  padding: "20px 24px",
  borderRadius: 25,
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  boxShadow: "none",
  // boxShadow: "rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px !important",
}));

const TripScheduleController = ({
  handleChangeRowsPerPage,
  updateState,
  resetSearchForm,
  setSearchOrder,
  setSearchFormDate,
  setSearchFormAutoComplete,
  setSearchFormCustom,
  handleFilteration,
  fetchResults,
  setFetchResults,
  pdfExport,
  excelExport,
  syncData,
  handleChangePage,
  updateTripSchedule,
  getSelectedInvoice,

  _openSearch,
  _tripSortByList,
  _searchForm,
  _countyList,
  _transportTypeList,
  _searchstring,
  _fetching,
  _tablerows,
  _cars,
  _drivers,
  _schedules,
  _user,
  _selectedInvoiceOpen,
  _modleProgress,
  _schaduleRetrived,
}) => {
  const [openSearch, setOpenSearch] = useState(_openSearch);
  const [tripSortByList, setTripSortByList] = useState(_tripSortByList);
  const [searchForm, setSearchForm] = useState(_searchForm);
  const [countyList, setCountyList] = useState(_countyList);
  const [transportTypeList, setTransportTypeList] =
    useState(_transportTypeList);
  const [searchstring, setSearchstring] = useState(_searchstring);
  const [fetching, setFetching] = useState(_fetching);
  const [tablerows, setTablerows] = useState({ data: [] });
  const [cars, setCars] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [user, setUser] = useState(_user);
  const [selectedInvoiceOpen, setSelectedInvoiceOpen] = useState(false);
  const [modleProgress, setModleProgress] = useState(false);
  const [schaduleRetrived, setSchaduleRetrived] = useState(false);

  useEffect(() => {
    setCars(_cars);
    setDrivers(_drivers);
    setUser(_user);
    setCountyList(_countyList);
    setTransportTypeList(_transportTypeList);
    setSearchstring(_searchstring);
    setTablerows(_tablerows);
    setModleProgress(_modleProgress);
    setSchaduleRetrived(_schaduleRetrived);
    setSelectedInvoiceOpen(_selectedInvoiceOpen);
    setFetching(_fetching);
    setSearchForm(_searchForm);
    setOpenSearch(_openSearch);
    setTripSortByList(_tripSortByList);
    setSchedules(_schedules);
  }, [_cars]);

  useEffect(() => {
    if (_openSearch) setOpenSearch(_openSearch);
  }, [_openSearch]);

  useEffect(() => {
    if (_searchForm) setSearchForm(_searchForm);
  }, [_searchForm]);

  useEffect(() => {
    if (_searchstring) setSearchstring(_searchstring);
  }, [_searchstring]);

  useEffect(() => {
    setFetching(_fetching);
  }, [_fetching]);
  useEffect(() => {
    setSelectedInvoiceOpen(_selectedInvoiceOpen);
  }, [_selectedInvoiceOpen]);

  useEffect(() => {
    setModleProgress(_modleProgress);
  }, [_modleProgress]);

  useEffect(() => {
    setSchaduleRetrived(_schaduleRetrived);
    if (_schaduleRetrived) setSchedules(_schedules);
    if (_tablerows) setTablerows(_tablerows);
  }, [_schaduleRetrived]);

  return (
    <>
      <ToastContainer />
      <Grid container>
        <Grid item xs={12} md={8} lg={8} className="screen-sub-title">
          MAS Trip Scheduler
        </Grid>
        <Grid item xs={12}>
          <CardRoot elevation={6}>
            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                <Accordion defaultExpanded={true} expanded={openSearch}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => {
                      setOpenSearch(!openSearch);
                    }}
                  >
                    <Typography>TRIP SCHEDULE - Search Criteria</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "0px" }}>
                    <ScheduleSearch
                      tripSortByList={tripSortByList}
                      searchForm={searchForm}
                      countyList={countyList}
                      driverList={_drivers}
                      transportTypeList={transportTypeList}
                      searchstring={searchstring}
                      fetching={fetching}
                      setFetchResults={setFetchResults}
                      resetSearchForm={resetSearchForm}
                      setSearchOrder={setSearchOrder}
                      setSearchFormDate={setSearchFormDate}
                      setSearchForm={setSearchForm}
                      setSearchFormAutoComplete={setSearchFormAutoComplete}
                      setSearchFormCustom={setSearchFormCustom}
                      handleFilteration={handleFilteration}
                      fetchResults={null}
                      pdfExport={pdfExport}
                      excelExport={excelExport}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={searchForm && !searchForm.fetching}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>Schedule</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "0px" }}>
                    {searchForm.fetching ? (
                      <LinearProgress />
                    ) : (
                      <>
                        <TableContainer component={Paper}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              xl={6}
                              display="flex"
                            >
                              <Pagination
                                page={_tablerows.page}
                                count={_tablerows.total_pages}
                                onChange={handleChangePage}
                                color="primary"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              xl={6}
                              sx={{ textAlign: "right" }}
                              display="flex"
                            >
                              <TablePagination
                                component="div"
                                count={searchForm.total}
                                page={searchForm.page}
                                onPageChange={handleChangePage}
                                rowsPerPage={searchForm.per_page}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </Grid>
                          </Grid>

                          <Table
                            sx={{ minWidth: 650, fontSize: 12 }}
                            aria-label="simple table"
                            className="trip-results-data-table"
                          >
                            <ScheduleTableHead user={user} />
                            <TableBody>
                              {tablerows.data.map((row, index) => (
                                <>
                                  <ScheduleHeader
                                    setState={updateState}
                                    cars={cars}
                                    drivers={drivers}
                                    schedules={schedules}
                                    row={row}
                                    key={index}
                                    user={user}
                                  />
                                  {schaduleRetrived ? (
                                    <>
                                      <ScheduleEditor
                                        setState={updateState}
                                        cars={cars}
                                        drivers={drivers}
                                        schedules={schedules}
                                        row={row}
                                        user={user}
                                        key={index}
                                        updateTripSchedule={updateTripSchedule}
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                            </TableBody>
                          </Table>

                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              xl={6}
                              display="flex"
                            >
                              <Pagination
                                page={searchForm.page}
                                count={searchForm.total_pages}
                                onChange={handleChangePage}
                                color="primary"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              xl={6}
                              sx={{ textAlign: "right" }}
                              display="flex"
                            >
                              <TablePagination
                                component="div"
                                count={searchForm.total}
                                page={searchForm.page}
                                onPageChange={handleChangePage}
                                rowsPerPage={searchForm.per_page}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </Grid>
                          </Grid>
                        </TableContainer>{" "}
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>

                {/* <InvoiceDialog
                    selectedInvoiceOpen={selectedInvoiceOpen}
                    getSelectedInvoice={getSelectedInvoice}
                    setSelectedInvoiceOpen={setSelectedInvoiceOpen}
                  /> */}
              </Grid>
            </Grid>
          </CardRoot>
        </Grid>
      </Grid>

      <Modal
        open={modleProgress}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CircularProgress /> Loading ...
        </Box>
      </Modal>
    </>
  );
};

export default TripScheduleController;
