
import MuiIcon from "@mui/material/Icon";
import { styled } from "@mui/material/styles";


const StyledIcon = styled(MuiIcon)(({theme, size}) =>({
    borderColor: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
    minWidth: size=="small"?15:size=="medium"?25:35,
    padding: theme.spacing(1),
    
  }));


  export default StyledIcon;