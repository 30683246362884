import React, { Component } from "react";
import "fontsource-roboto";
import { BASE_URL } from "../../config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import moment from "moment";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import { DateRangePicker, DateRange } from "mui-daterange-picker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  BarChart,
  Bar,
  CartesianGrid,
  LineChart,
  Line,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  PieChart,
  Pie,
  Label,
  Sector,
  Cell,
  LabelList,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

import Modal from "@mui/material/Modal";
import { Button, Card, TableFooter, TextField } from "@mui/material";


import TripStatusSummaryByDriver from "../components/tripStatusSummaryByDriver";

const CardRoot = styled(Card)(() => ({
  height: "100%",
  padding: "20px 24px",
  borderRadius: 25,
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  boxShadow: "none",
  // boxShadow: "rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px !important",
  borderRadius: " 8px",
}));

const CardTitle = styled("div")(() => ({
  fontStyle: "normal",
  fontWeight: 800,
  fontSize: "16px",
  lineHeight: "22px",
  color: "#1F1F1F",
  marginBottom: "2px",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const expGridCell = {
  fontSize: "12px",
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};


const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};


class MainDashboard extends Component {
  constructor(props) {
    super(props);
    let url = new URL(window.location.href);

    this.state = {
      tripsByCounty: [],
      pageLoading: true,
      dailyAttestedLastMonth: [],
      driverdata: [],
      availableFutureDates: [],
      totalByCountryTotal: 0.0,
      tripsByCountyDate: moment().format("MM/DD/YYYY"),
      tripCostsStats: {
        totalattested: 0,
        lastweekattested: 0,
        lastmonthattested: 0,
        lastyearattested: 0,
      },
      dateRangeOpen: false,
      rangeStartDate: moment()
        .add(-1, "day")
        .startOf("day")
        .format("MM/DD/YYYY"),
      rangeEndDate: moment().add(-1, "day").endOf("day").format("MM/DD/YYYY"),
      user: {},
      tripsStatusSummary: [],
      tripsStatusSummaryByDriver: [],
      tripSummaryByDriverDialog: false,
      allTripsSummary: {}
    };
    this.refreshTripsByCountyChart = this.refreshTripsByCountyChart.bind(this);
    this.randomColor = this.randomColor.bind(this);
    this.chartColor = this.chartColor.bind(this);
    this.renderColorfulLegendText = this.renderColorfulLegendText.bind(this);
    this.setDateRange = this.setDateRange.bind(this);
    this.setDateRangeToggle = this.setDateRangeToggle.bind(this);


  }
  componentDidMount() {
    let user = { active: false, name: "" };
    if (localStorage.getItem("session")) {
      user = JSON.parse(localStorage.getItem("session"));
    }

    this.setState({ user: user }, () => {
      this.refreshTripsByCountyChart();
    });
    console.log(this.state.user);
  }

  setDateRange = (range) => {
    //console.log( moment(range.startDate).startOf("day") );
    //console.log( moment(range.startDate).startOf("day") );
    //console.log(moment(range.startDate).endOf("day") );
    this.setState(
      {
        rangeStartDate: moment(range.startDate)
          .startOf("day")
          .format("MM/DD/YYYY"),
        rangeEndDate: moment(range.endDate).endOf("day").format("MM/DD/YYYY"),
      },
      this.refreshTripsByCountyChart
    );
    this.setDateRangeToggle();
  };
  setDateRangeToggle = () => {
    this.setState({ dateRangeOpen: !this.state.dateRangeOpen });
  };
  renderColorfulLegendText = (value, entry, index) => {
    const { color } = entry;
    let totalTrips = 0;
    for (let city of this.state.tripsByCounty) {
      totalTrips += city.trips;
    }
    return (
      <span style={{ color, fontSize: 12 }}>
        {value} - {this.state.tripsByCounty[index].trips} Trips -{" "}
        {parseFloat(
          (100 * this.state.tripsByCounty[index].trips) / totalTrips
        ).toFixed(2)}{" "}
        %
      </span>
    );
  };
  randomColor() {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  }

  chartColor(index) {
    let chartc = [
      "#003f5c",
      "#374c80",
      "#7a5195",
      "#bc5090",
      "#ef5675",
      "#ff764a",
      "#ffa600",
    ];
    return chartc[6 - index];
  }
  async refreshTripsByCountyChart() {
    const headers = {
      "Content-Type": "application/json",
      "x-auth-key": this.state.user.key,
    };

    await this.setState({ pageLoading: true });

    const response = await axios.post(
      BASE_URL + "/dashboard/tripsByCountyDate",
      {
        tripsByCountyDate: this.state.tripsByCountyDate,
        rangeStartDate: this.state.rangeStartDate,
        rangeEndDate: this.state.rangeEndDate,
      },
      {
        headers: headers,
      }
    );


    console.log("allTripsSummary --- > ",response.data.allTripsSummary)
    console.log("tripsStatusSummaryByDriver --- > ",response.data.tripsStatusSummaryByDriver);
    let totalByCountryTotal = 0.0;
    for (let trip of response.data.totalByCounty) {
      totalByCountryTotal += trip.trips;
    }

    await this.setState({
      tripsByCounty: response.data.tripsByCounty,
      totalByCounty: response.data.totalByCounty,
      dailyAttestedLastMonth: response.data.dailyAttestedLastMonth,
      tripCostsStats: response.data.tripCostsStats,
      availableFutureDates: response.data.availableFutureDates,
      /* driverdata: response.data.driverdata,*/
      driverdata: response.data.expiriengcontracts,

      totalByCountryTotal: parseFloat(totalByCountryTotal).toFixed(2),
      tripsStatusSummary: response.data.tripsStatusSummary,
      tripsStatusSummaryByDriver: response.data.tripsStatusSummaryByDriver,
      allTripsSummary: response.data.allTripsSummary,
      pageLoading: false,
    });
  }



  handleOpenTripSummaryByDriver = () => {
    this.setState({
      tripSummaryByDriverDialog: true,
    });
  };

  handleCloseTripSummaryByDriver = () => {
    this.setState({
      tripSummaryByDriverDialog: false,
    });
  };

  render() {
    return (
      <>
        <ToastContainer />
        <Grid container className="main-dashbord-container" padding={1}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8} lg={8} className="screen-sub-title">
                Analytical Dashboard
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                {moment(this.state.rangeStartDate).format("MM/DD/YYYY")} -{" "}
                {moment(this.state.rangeEndDate).format("MM/DD/YYYY")} &nbsp;
                <Button
                  variant="outlined"
                  startIcon={<CalendarMonthIcon />}
                  onClick={this.setDateRangeToggle}
                  className="date-range-picker-button"
                >
                  Date Range
                </Button>
                <DateRangePicker
                  wrapperClassName="main-dashboard-date-range-wrapper"
                  open={this.state.dateRangeOpen}
                  toggle={this.setDateRangeToggle}
                  onChange={(range) => {
                    this.setDateRange(range);
                  }}
                />
              </Grid>
              {this.state.user.userlevel == 1 ? (
                <Grid item xs={12} md={12} lg={12}>
                  <CardRoot elevation={6} className="date-range-card">
                    <CardTitle>
                      Total Cost ( $ ) By County on{" "}
                      {moment(this.state.rangeStartDate).format("MM/DD/YYYY")} -{" "}
                      {moment(this.state.rangeEndDate).format("MM/DD/YYYY")}{" "}
                      With PA #
                    </CardTitle>

                    <Grid container>
                      <Grid item xs={12} md={8} lg={8} xl={8} height={200}>
                        <ResponsiveContainer>
                          <BarChart
                            margin={{
                              top: 16,
                              right: 16,
                              bottom: 0,
                              left: 24,
                            }}
                            data={this.state.totalByCounty}
                          >
                            <CartesianGrid strokeDasharray="3 3" />

                            <XAxis
                              dataKey="name"
                              tick={{ fontSize: 12 }}
                              scale="band"
                            />
                            <YAxis tick={{ fontSize: 12 }} />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            <Bar dataKey="trips" fill="#64d542">
                              <LabelList
                                dataKey="trips"
                                style={{ fill: "white", fontSize: 12 }}
                              />
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </Grid>

                      <Grid item xs={12} md={4} lg={4} xl={2} height={200}>
                        <Table>
                          <TableBody>
                            {this.state.totalByCounty &&
                              this.state.totalByCounty.map((t) => {
                                return (
                                  <>
                                    <TableRow className="trip-row-legend">
                                      <TableCell className="trip-row-legend-cap">
                                        {" "}
                                        {t.name}{" "}
                                      </TableCell>
                                      <TableCell className="trip-row-legend-val">
                                        $ {t.trips}{" "}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}

                            <TableRow className="trip-total-legend">
                              <TableCell className="trip-row-legend-cap">
                                {" "}
                                Total{" "}
                              </TableCell>
                              <TableCell className="trip-row-legend-val">
                                $ {this.state.totalByCountryTotal}{" "}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>{" "}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ color: "warning.main" }}>
                        * Attested trips with PA numbers only
                      </Box>
                    </Grid>
                  </CardRoot>
                </Grid>
              ) : null}
              <Grid item xs={12} md={4} lg={4}>
                <CardRoot elevation={6}>
                  <CardTitle>Upcoming expiring contracts</CardTitle>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                      <TableContainer style={{ maxHeight: 200 }}>
                        <Table
                          aria-label="simple table"
                          className="trip-results-data-table"
                        >
                          <TableHead>
                            {" "}
                            <TableRow>
                              <TableCell
                                align="left"
                                className="driver-data-header test"
                              >
                                Expiration
                              </TableCell>
                              <TableCell
                                align="left"
                                className="driver-data-header test"
                              >
                                Dates
                              </TableCell>
                              <TableCell
                                align="left"
                                className="driver-data-header test"
                              >
                                Client Name
                              </TableCell>
                              <TableCell
                                align="left"
                                className="driver-data-header test"
                              >
                                County
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.driverdata.map((row) => (
                              <>
                                <TableRow
                                  className={
                                    row.expdiff < 2
                                      ? "driver-row-expired"
                                      : row.expdiff < 7
                                      ? "driver-row-week"
                                      : row.expdiff < 14
                                      ? "driver-row-twoweek"
                                      : row.expdiff < 30
                                      ? "driver-row-month"
                                      : row.expdiff < 90
                                      ? "driver-row-three-months"
                                      : row.expdiff < 365
                                      ? "driver-row-year"
                                      : "driver-row-more-year"
                                  }
                                >
                                  <TableCell
                                    align="left"
                                    className="driver-data-cell test"
                                  >
                                    {moment(row.expirationnt).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className="driver-data-cell test"
                                  >
                                    {row.expdiff}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className="driver-data-cell test"
                                  >
                                    {row.client}
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    className="driver-data-cell test"
                                  >
                                    {row.county}
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </CardRoot>
              </Grid>

              <Grid item xs={12} md={4} lg={4}>
                <CardRoot elevation={6}>
                  <CardTitle>
                    Number of Trips By County on{" "}
                    {moment(this.state.rangeStartDate).format("MM/DD/YYYY")} -{" "}
                    {moment(this.state.rangeEndDate).format("MM/DD/YYYY")}
                  </CardTitle>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12} height={200}>
                      <ResponsiveContainer
                        width="100%"
                        height="100%"
                        margin={25}
                      >
                        <PieChart margin={25}>
                          <Legend
                            layout="radial"
                            formatter={this.renderColorfulLegendText}
                          />
                          <Pie
                            dataKey="trips"
                            isAnimationActive={false}
                            data={this.state.tripsByCounty}
                            fill="#8884d8"
                            /* label={({
                          cx,
                          cy,
                          midAngle,
                          innerRadius,
                          outerRadius,
                          value,
                          index,
                        }) => {
                          console.log("handling label?");
                          const RADIAN = Math.PI / 180;
                          // eslint-disable-next-line
                          const radius =
                            25 + innerRadius + (outerRadius - innerRadius);
                          // eslint-disable-next-line
                          const x = cx + radius * Math.cos(-midAngle * RADIAN);
                          // eslint-disable-next-line
                          const y = cy + radius * Math.sin(-midAngle * RADIAN);
                          let totalTrips = 0;
                          for (let city of this.state.tripsByCounty) {
                            totalTrips += city.trips;
                          }
                          return (
                            <text
                              x={x}
                              y={y}
                              fill="#3e2421"
                              fontSize={"12px"}
                              textAnchor={x > cx ? "start" : "end"}
                              dominantBaseline="central"
                            >
                              {this.state.tripsByCounty[index].name} {value}{" "}
                              trips -{" "}
                              {parseFloat((100 * value) / totalTrips).toFixed(
                                2
                              )}{" "}
                              %
                            </text>
                          );
                        }}*/
                          >
                            {this.state.tripsByCounty.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={this.chartColor(index)}
                              />
                            ))}
                          </Pie>

                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Grid>{" "}
                    <Grid item xs={12}>
                      <Box sx={{ color: "info.main" }}>
                        * Eligible trips regardless of attestation status
                      </Box>
                    </Grid>{" "}
                  </Grid>{" "}
                </CardRoot>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
              <CardRoot elevation={6}>
                  <CardTitle>Dispatch Summary on{" "}
                    {moment(this.state.rangeStartDate).format("MM/DD/YYYY")} -{" "}
                    {moment(this.state.rangeEndDate).format("MM/DD/YYYY")}</CardTitle>
                  <Grid container>
                    <Grid item xs={12} md={6} lg={6} height={300} onClick={this.handleOpenTripSummaryByDriver}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            activeIndex={this.state.tripStatusSummaryActiveIndex}
                            activeShape={renderActiveShape}
                            data={this.state.tripsStatusSummary}
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={this.onTripStatusPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} height={300} onClick={this.handleOpenTripSummaryByDriver}>
                      <div style={{ maxWidth: 400, overflowX: 'auto' }}>
                        <Table>
                          <TableBody>
                            <TableRow>
                                <TableCell>TOTAL LEGS</TableCell>
                              <TableCell>{this.state.allTripsSummary.eligibleTrips}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>TOTAL ASSIGNED</TableCell>
                              <TableCell>{this.state.allTripsSummary.assignedTrips}</TableCell>
                              { this.state.allTripsSummary.eligibleTrips > 0 && <TableCell> {parseFloat((this.state.allTripsSummary.assignedTrips / this.state.allTripsSummary.eligibleTrips ) * 100).toFixed(2)} % </TableCell>}
                            </TableRow>

                            {this.state.allTripsSummary.assignedTrips > 0 && this.state.tripsStatusSummary.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell> &nbsp; &nbsp; &nbsp;{row.name}</TableCell>
                                <TableCell>{row.value}</TableCell>
                                <TableCell>{parseFloat((row.value / this.state.allTripsSummary.assignedTrips ) * 100).toFixed(0)} % </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </Grid>
                    <TripStatusSummaryByDriver open={this.state.tripSummaryByDriverDialog} handleClose={this.handleCloseTripSummaryByDriver} data={this.state.tripsStatusSummaryByDriver} />
                  </Grid>
                </CardRoot>
              </Grid>  
              <Grid item xs={12} md={12} lg={12}>
                <CardRoot elevation={6}>
                  <CardTitle>Number of Trips on last month</CardTitle>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12} height={200}>
                      <ResponsiveContainer>
                        <BarChart
                          margin={{
                            top: 16,
                            right: 16,
                            bottom: 0,
                            left: 24,
                          }}
                          data={this.state.dailyAttestedLastMonth}
                        >
                          <CartesianGrid strokeDasharray="3 3" />

                          <XAxis
                            dataKey="name"
                            tick={{ fontSize: 10 }}
                            scale="band"
                          />
                          <YAxis tick={{ fontSize: 12 }} />
                          <Tooltip content={<CustomTooltip />} />
                          <Legend />
                          <Bar dataKey="trips" fill="#52cdff" barSize={20} />
                        </BarChart>
                      </ResponsiveContainer>
                    </Grid>
                  </Grid>
                </CardRoot>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <CardRoot elevation={6}>
                  <CardTitle>Available future dates</CardTitle>
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12} height={200}>
                      {/* <ResponsiveContainer>
                    <BarChart
                      margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                      }}
                      data={this.state.availableFutureDates}
                    >
                      <CartesianGrid strokeDasharray="3 3" />

                      <XAxis
                        dataKey="name"
                        tick={{ fontSize: 10 }}
                        scale="band"
                      />
                      <YAxis />
                      <Tooltip content={<CustomTooltip />} />

                      <Bar dataKey="trips" fill="#ffd97b">
                        <LabelList dataKey="trips" style={{ fill: "white" }} />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>*/}

                      <ResponsiveContainer>
                        <AreaChart
                          height={200}
                          data={this.state.availableFutureDates}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" tick={{ fontSize: 10 }} />
                          <YAxis tick={{ fontSize: 12 }} />
                          <Tooltip />
                          <Area
                            type="monotone"
                            dataKey="trips"
                            stroke="#8884d8"
                            fill="#8884d8"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Grid>
                  </Grid>
                </CardRoot>
              </Grid>
              {/* Recent Deposits */}

              {/* Recent Orders */}
            </Grid>
          </Grid>
        </Grid>
        <Modal
          open={this.state.pageLoading}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CircularProgress /> Loading ...
          </Box>
        </Modal>
      </>
    );
  }
}

export default MainDashboard;
