import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";


function AttestationConfirmationDialog ({
    showUpateConfimation,
    handleCloseConfimation,
    messageType
}) {
    return (
        <Dialog
            open={showUpateConfimation}
            onClose={() => handleCloseConfimation(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
                {"Attest Invoices"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Do you wish to {messageType} invoice(s)?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseConfimation("NO")}>No</Button>
                <Button onClick={() => handleCloseConfimation("YES")}>
                    Yes
                </Button>
            </DialogActions>
      </Dialog>

    );
}

export default AttestationConfirmationDialog;