import React, { Component } from "react";
import Paper from "@mui/material/Paper";

import Button from "@mui/material/Button";

import "react-toastify/dist/ReactToastify.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

class CSVUploadErrorDialog extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}

  render() {
    return (
      <>
        <Dialog
          open={this.props.showErrorUploadCsv}
          onClose={this.props.onClose}
          TransitionComponent={Transition}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                minWidth: "500px",
              },
            },
          }}
        >
          <DialogTitle>Error!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Attestations upload have failed due to the following rows.
            </DialogContentText>
            <TableContainer component={Paper}>
              <Table aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell>Invoice No</TableCell>
                    <TableCell align="left">Reason for Failure</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.failedRows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row["invoiceNumber"]}
                      </TableCell>
                      <TableCell align="left">{row["Failed Reason"]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose}>OK</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default CSVUploadErrorDialog;
