import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useCallback, useState } from "react";
import { StyledButton, StyledDatePicker, StyledFormControl, StyledFrame, StyledHeader, StyledInputText } from "../../styled";

// const FormHeader = ({ title }) => {
//   return (
//     <Grid container>
//       <Grid item sm={12}>
//         <Box className={"attestations_creteria_header"}>{title}</Box>
//       </Grid>
//     </Grid>
//   );
// };

const SearchBy = ({ handleOnSearch, uploadView }) => {

  const [invoiceNo, setInvoiceNo] = useState(null);
  const [tripDate, setTripDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const handleSearch = () => {
    handleOnSearch({
      invoiceNo: invoiceNo,
      tripDate: tripDate,
      reload:true,
    });
  }
  
  const handleInvoiceNoChange = useCallback((e) => {
    setInvoiceNo(e.target.value);
  });

  const handleSetTripDate = useCallback((e) => {
    setTripDate(e.target.value);
  });

  return (
    <Grid container p={0}>
     
      <Grid item md={12} sm={12} p={0}>
        <StyledHeader>
              Invoice Attest
        </StyledHeader>
      </Grid>

      <Grid item md={12} xs={12} lg={12} p={0}>
        <br></br>
      </Grid>
      <StyledFrame>
        <Grid item md={12} xs={12} lg={12} p={0}>
          <form>
            <Grid container spacing={3} justify="flex-end" p={0}>
              <Grid item xs sm={3} p={0}>
                <StyledFormControl>
                  <Typography  sx={{
                    fontWeight:800
                  }}>Invoice Number</Typography>
                  <StyledInputText 
                    id="invoiceNumber"
                    name="invoiceNumber"
                    // label="Invoice Number"
                    value={invoiceNo ?? ""}
                    onChange={(e)=>{handleInvoiceNoChange(e)}}>
                  </StyledInputText>
                </StyledFormControl>
              </Grid>
              <Grid item sm={3}>
                <StyledFormControl>
                  <Typography  sx={{
                      fontWeight:800
                    }}>Trip Date</Typography>
                      <StyledDatePicker
                        type="date"
                        id="dateFrom"
                        name="dateFrom"
                        value={tripDate}
                        onChange={(e)=>{handleSetTripDate(e)}}
                      />
                </StyledFormControl>
              </Grid>
              <Grid item md={6} lg={6} sm={null} xs={null}>
                <StyledFormControl 
                    style={{
                      width:"100%",
                    }}>
                    <div style={{
                      marginTop:"16px",
                      width:"100%",
                      textAlign:"left",
                      justifyContent:"center",
                    }}>
                    <StyledButton 
                      variant={"contained"}  
                      onClick={(e)=>{handleSearch()}}
                      theme >
                      Search
                    </StyledButton>
                  </div>
                </StyledFormControl>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </StyledFrame>
    </Grid>
  );
};

export default SearchBy
