import React, { Component } from "react";

import axios from "axios";
import { BASE_URL } from "../../../config";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import moment from "moment";

import Button from "@mui/material/Button";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import CustomRadioGroup from "../../components/CustomRadioGroup";
import InvoiceDialog from "../../components/InvoiceDialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Card, Autocomplete, TextField, Alert } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import Pagination from "@mui/material/Pagination";
import TablePagination from "@mui/material/TablePagination";
import LinearProgress from "@mui/material/LinearProgress";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Modal from "@mui/material/Modal";
import SearchIcon from "@mui/icons-material/Search";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { styled } from "@mui/material/styles";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";

import dayjs, { Dayjs } from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
function Search({
  tripSortByList,
  searchForm,
  countyList,
  transportTypeList,
  searchstring,
  fetching,
  setState,
  setSearchOrder,
  setSearchFormDate,
  setSearchForm,
  setSearchFormAutoComplete,
  setSearchFormCustom,
  fetchResults,
  pdfExport,
  excelExport,
  resetSearchForm,
  updateSchedule,
}) {
  return (
    <>
      {" "}
      <Grid container>
        <Grid
          xs={12}
          sm={12}
          md={2}
          xl={2}
          textAlign={{ xl: "right", md: "right", sm: "left", xs: "left" }}
          display="flex"
          className="grid-caption-wrapper"
        >
          <div className="grid-caption"> Trip Sort By : </div>
        </Grid>
        <Grid item xs={10}>
          <Autocomplete
            className="grid-inout-wrapper"
            id="searchTripSortBy"
            options={tripSortByList}
            autoHighlight
            defaultValue={
              (e) => {
                console.log("e" + e);
                return {
                  name: "Trip Date - Low to High ",
                  description: "Trip Date - High to Low ",
                  id: 1,
                  createdAt: "2022-06-05T17:13:02.000Z",
                  updatedAt: "2022-06-05T17:13:02.000Z",
                };
              } /*{
                          name: "Trip Date - Low to High ",
                          description: "Trip Date - High to Low ",
                          id: 1,
                          createdAt: "2022-06-05T17:13:02.000Z",
                          updatedAt: "2022-06-05T17:13:02.000Z"
                        }*/
            }
            onChange={(event, value) => {
              if (value && value.name) {
                let sortName = value.name.split("-")[0].trim();
                let order = value.name.split("-")[1].trim();
                if (order === "Low to High") {
                  order = "ASC";
                } else {
                  order = "DESC";
                }

                let sortz = [
                  { name: "Invoice", field: "invoiceNumber" },
                  { name: "CIN Medicaid", field: "medicaidNumber" },
                  { name: "Recipient", field: "firstName" },
                  { name: "Sex", field: "gender" },
                  { name: "DOB", field: "DOB" },
                  { name: "Trip Cost", field: "totalTripCost" },
                  { name: "Calc Milage", field: "calculatedTripMileage" },
                  { name: "Transport Type", field: "transportType" },
                  { name: "Trip Date", field: "pickupDate" },
                  { name: "Created Date", field: "originalCreateddate" },
                ];

                let selectedField;

                for (let o of sortz) {
                  if (o.name === sortName) {
                    selectedField = o.field;
                  }
                }
                setState({ searchTripSortBy: value });

                setSearchOrder(selectedField, order);
              }
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                className="grid-inout-wrapper"
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          xl={2}
          className="grid-caption-wrapper"
          display="flex"
          textAlign={{ xl: "right", md: "right", sm: "left", xs: "left" }}
          justify="flex-end"
        >
          <div className="grid-caption"> Invoice Number : </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <TextField
            className="grid-inout-wrapper search-input-box"
            id="searchInvoiceNumber"
            variant="outlined"
            size="small"
            value={searchForm.searchInvoiceNumber}
            onChange={(event) => {
              if (event.target.value !== "") {
                setSearchFormDate("searchServiceStartsFrom", "");
                setSearchFormDate("searchServiceStartsTo", "");
              }

              setSearchForm(event);
            }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          xl={2}
          display="flex"
          textAlign={{ xl: "right", md: "right", sm: "left", xs: "left" }}
          justify="flex-end"
          className="grid-caption-wrapper"
        >
          <div className="grid-caption"> Service Starts: </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6} className="datepicker-wrapper">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputFormat="MM/DD/YYYY"
              clearable
              id="searchServiceStartsFrom"
              value={searchForm.searchServiceStartsFrom}
              onChange={(newValue) => {
                console.log("newValue", newValue.date);
                setSearchFormDate(
                  "searchServiceStartsFrom",
                  newValue.format("MM/DD/YYYY")
                );
                setSearchFormDate(
                  "searchServiceStartsTo",
                  newValue.add(1, "days").format("MM/DD/YYYY")
                );
              }}
              renderInput={(params) => (
                <TextField
                  className="search-input-box search-input-box-dob "
                  {...params}
                  value={searchForm.searchServiceStartsFrom}
                />
              )}
            />
          </LocalizationProvider>{" "}
          -
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputFormat="MM/DD/YYYY"
              clearable
              id="searchServiceStartsTo"
              value={searchForm.searchServiceStartsTo}
              minDate={moment(searchForm.searchServiceStartsFrom).toDate()}
              onChange={(newValue) => {
                setSearchFormDate(
                  "searchServiceStartsTo",
                  newValue.format("MM/DD/YYYY")
                );
              }}
              renderInput={(params) => (
                <TextField
                  className="search-input-box search-input-box-dob"
                  {...params}
                  value={searchForm.searchServiceStartsTo}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          xl={2}
          display="flex"
          textAlign={{ xl: "right", md: "right", sm: "left", xs: "left" }}
          justify="flex-end"
          className="grid-caption-wrapper"
        >
          <div className="grid-caption"> Service Ends: </div>
        </Grid>
        <Grid item xs={10} sm={10} md={6} xl={6} className="datepicker-wrapper">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputFormat="MM/DD/YYYY"
              clearable
              id="searchServiceEndsFrom"
              value={searchForm.searchServiceEndsFrom}
              onChange={(newValue) => {
                setSearchFormDate(
                  "searchServiceEndsFrom",
                  newValue.format("MM/DD/YYYY")
                );

                setSearchFormDate();
                // "searchServiceEndsTo", newValue.add(1,'days').format("MM/DD/YYYY")
              }}
              renderInput={(params) => (
                <TextField
                  className="search-input-box search-input-box-dob"
                  {...params}
                  value={searchForm.searchServiceEndsFrom}
                />
              )}
            />
          </LocalizationProvider>{" "}
          -
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputFormat="MM/DD/YYYY"
              clearable
              id="searchServiceEndsTo"
              value={searchForm.searchServiceEndsTo}
              minDate={moment(searchForm.searchServiceEndsFrom).toDate()}
              onChange={(newValue) => {
                setSearchFormDate(
                  "searchServiceEndsTo",
                  newValue.format("MM/DD/YYYY")
                );
              }}
              renderInput={(params) => (
                <TextField
                  className="search-input-box search-input-box-dob"
                  {...params}
                  value={searchForm.searchServiceEndsTo}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1}>
          <Button
            className="date-clear-btn"
            variant="outlined"
            startIcon={<HighlightOffIcon />}
            onClick={() => {
              setSearchFormDate("searchServiceEndsFrom", null);
              setSearchFormDate("searchServiceEndsTo", null);
            }}
          >
            {" "}
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          xl={2}
          textAlign={{ xl: "right", md: "right", sm: "left", xs: "left" }}
          display="flex"
          className="grid-caption-wrapper"
        >
          <div className="grid-caption"> County : </div>
        </Grid>
        <Grid item xs={10}>
          <Autocomplete
            xs={{
              width: 50,
            }}
            className="grid-inout-wrapper"
            id="searchCounty"
            options={countyList}
            autoHighlight
            getOptionLabel={(option) => option.name}
            value={searchForm.searchCounty}
            onChange={(event, value) => {
              setSearchFormAutoComplete("searchCounty", value);
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                className="grid-inout-wrapper search-input-box"
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          xl={2}
          textAlign={{ xl: "right", md: "right", sm: "left", xs: "left" }}
          display="flex"
          className="grid-caption-wrapper"
        >
          <div className="grid-caption"> Trip Status : </div>
        </Grid>
        <Grid item xs={10}>
          {searchForm &&
            searchForm.tripStatus &&
            searchForm.tripStatus.map((item, index) => (
              <div key={index} className="trip-status-check-wrap">
                <input
                  id={"TR-S_CH_" + item.id}
                  value={item.name}
                  type="checkbox"
                  className="trip-status-check"
                  checked={item.checked}
                  onClick={(e) => {
                    let statusList = searchForm.tripStatus;

                    for (let obj of statusList) {
                      if (obj.name === e.target.value) {
                        obj.checked = e.target.checked;
                      }
                    }
                    console.log(statusList);

                    setState({ searchForm: searchForm });
                  }}
                />
                <label htmlFor={"TR-S_CH_" + item.id}>{item.name}</label>
              </div>
            ))}
          {/* <Autocomplete
                        xs={{
                          width: 50,
                        }}
                        className="grid-inout-wrapper"
                        id="searchTripStatus"
                        options={tripStatusList}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        value={searchForm.searchTripStatus}
                        onChange={(event, value) => {
                          setSearchFormAutoComplete(
                            "searchTripStatus",
                            value
                          );
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            className="grid-inout-wrapper search-input-box"
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      /> */}
        </Grid>
      </Grid>
      {/* <Grid container >
                    <Grid item xs={2} display="flex">
                      <div className="grid-caption">
                        {" "}
                        PA Submission Results :{" "}
                      </div>
                    </Grid>
                    <Grid item xs={10}>
                      <Autocomplete
                        xs={{
                          width: 50,
                        }}
                        className="grid-inout-wrapper"
                        id="searchPASubmissionResults"
                        options={pASubmissionResultsList}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        value={searchForm.searchPASubmissionResults}
                        onChange={(event, value) => {
                          setSearchFormAutoComplete(
                            "searchPASubmissionResults",
                            value
                          );
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            className="grid-inout-wrapper"
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid> */}
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          xl={2}
          textAlign={{ xl: "right", md: "right", sm: "left", xs: "left" }}
          display="flex "
          className="grid-caption-wrapper"
        >
          <div className="grid-caption"> Correction ? : </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3} className="radio-wrapper">
          <CustomRadioGroup
            idPrefix={"opencorrection"}
            onChange={setSearchFormCustom}
            options={[
              { id: "Yes", value: "Yes" },
              { id: "No", value: "No" },
              { id: "Any", value: "Any" },
            ]}
            default={"Any"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          xl={3}
          textAlign={{ xl: "right", md: "right", sm: "left", xs: "left" }}
          display="flex"
          className=" grid-caption-wrapper"
        >
          <div className="grid-caption"> Changed Since Vendor Notified? : </div>
        </Grid>

        <Grid item xs={12} sm={12} md={3} xl={3} className="radio-wrapper">
          <CustomRadioGroup
            idPrefix={"searchChangeSice"}
            onChange={setSearchFormCustom}
            options={[
              { id: "Yes", value: "Yes" },
              { id: "No", value: "No" },
              { id: "Any", value: "Any" },
            ]}
            default={"Any"}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          xl={2}
          textAlign={{ xl: "right", md: "right", sm: "left", xs: "left" }}
          display="flex"
          className="grid-caption-wrapper"
        >
          <div className="grid-caption"> Export Status: </div>
        </Grid>
        <Grid item xs={10} className="radio-wrapper">
          <CustomRadioGroup
            idPrefix={"searchExportStatus"}
            onChange={setSearchFormCustom}
            options={[
              { id: "Yes", value: "Yes" },
              { id: "No", value: "No" },
              { id: "Any", value: "Any" },
            ]}
            default={"Any"}
          />

          {/* <Autocomplete
                        xs={{
                          width: 50,
                        }}
                        className="grid-inout-wrapper"
                        id="searchExportStatus"
                        options={exportStatusList}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        value={searchForm.searchExportStatus}
                        onChange={(event, value) => {
                          setSearchFormAutoComplete(
                            "searchExportStatus",
                            value
                          );
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            className="grid-inout-wrapper"
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      /> */}
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          xl={2}
          display="flex"
          textAlign={{ xl: "right", md: "right", sm: "left", xs: "left" }}
          justify="flex-end"
          className="grid-caption-wrapper"
        >
          <div className="grid-caption"> CIN/Medicine AID Number : </div>
        </Grid>
        <Grid item xs={10}>
          <TextField
            className="grid-inout-wrapper search-input-box"
            id="searchCINNumber"
            variant="outlined"
            size="small"
            value={searchForm.searchCINNumber}
            onChange={setSearchForm}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          xl={2}
          display="flex"
          textAlign={{ xl: "right", md: "right", sm: "left", xs: "left" }}
          justify="flex-end"
          className="grid-caption-wrapper"
        >
          <div className="grid-caption"> Name : </div>
        </Grid>
        <Grid item xs={10}>
          <TextField
            className="grid-inout-wrapper search-input-box"
            id="searchName"
            variant="outlined"
            size="small"
            value={searchForm.searchName}
            onChange={setSearchForm}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          xl={2}
          display="flex"
          textAlign={{ xl: "right", md: "right", sm: "left", xs: "left" }}
          justify="flex-end"
          className="grid-caption-wrapper"
        >
          <div className="grid-caption"> Date Of Birth: </div>
        </Grid>
        <Grid item xs={10} sm={10} md={6} xl={6} className="datepicker-wrapper">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputFormat="MM/DD/YYYY"
              clearable
              id="searchFromDOB"
              value={searchForm.searchFromDOB}
              onChange={(newValue) => {
                console.log(newValue);

                setSearchFormDate(
                  "searchFromDOB",
                  newValue.format("MM/DD/YYYY")
                );

                setSearchFormDate(
                  "searchToDOB",
                  newValue.add(1, "days").format("MM/DD/YYYY")
                );
              }}
              renderInput={(params) => (
                <TextField
                  className="search-input-box search-input-box-dob"
                  {...params}
                  value={searchForm.searchFromDOB}
                />
              )}
            />
          </LocalizationProvider>{" "}
          -
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputFormat="MM/DD/YYYY"
              clearable
              id="searchToDOB"
              value={searchForm.searchToDOB}
              minDate={moment(searchForm.searchFromDOB).toDate()}
              onChange={(newValue) => {
                setSearchFormDate("searchToDOB", newValue.format("MM/DD/YYYY"));
              }}
              renderInput={(params) => (
                <TextField
                  className="search-input-box search-input-box-dob"
                  {...params}
                  value={searchForm.searchToDOB}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1}>
          <Button
            className="date-clear-btn"
            variant="outlined"
            startIcon={<HighlightOffIcon />}
            onClick={() => {
              setSearchFormDate("searchFromDOB", null);
              setSearchFormDate("searchToDOB", null);
            }}
          ></Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          xl={2}
          display="flex"
          textAlign={{ xl: "right", md: "right", sm: "left", xs: "left" }}
          justify="flex-end"
          className="grid-caption-wrapper"
        >
          <div className="grid-caption">Standing Order? :</div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6} className="radio-wrapper">
          <div className="grid-inout-wrapper">
            {" "}
            <CustomRadioGroup
              idPrefix={"searchStandingOrder"}
              onChange={setSearchFormCustom}
              options={[
                { id: "Yes", value: "Yes" },
                { id: "No", value: "No" },
                { id: "Any", value: "Any" },
              ]}
              default={"Any"}
            />{" "}
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          xl={2}
          display="flex"
          textAlign={{ xl: "right", md: "right", sm: "left", xs: "left" }}
          justify="flex-end"
          className="grid-caption-wrapper"
        >
          <div className="grid-caption">Attested without PA? :</div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6} className="radio-wrapper">
          <div className="grid-inout-wrapper">
            {" "}
            <CustomRadioGroup
              idPrefix={"attestedwithoutpa"}
              onChange={setSearchFormCustom}
              options={[
                { id: "Yes", value: "Yes" },
                { id: "No", value: "No" },
                { id: "Any", value: "Any" },
              ]}
              default={"Any"}
            />{" "}
          </div>
        </Grid>
      </Grid>
      {/* <Grid container >
                    <Grid
                      item
                      xs={2}
                      display="flex"
                      textAlign={{xl:"right",md:"right",sm:"left",xs:"left"}}
                      justify="flex-end"
                    >
                      <div className="grid-caption">
                        Part OF Split S.O Series? :
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="grid-inout-wrapper">
                        {" "}
                        <CustomRadioGroup
                          idPrefix={"searchPartOfSplitSOSeries"}
                          onChange={setSearchFormCustom}
                          options={[
                            { id: "Yes", value: "Yes" },
                            { id: "No", value: "No" },
                            { id: "Any", value: "Any" },
                          ]}
                          default={"Any"}
                        />{" "}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container >
                    <Grid
                      item
                      xs={2}
                      display="flex"
                      textAlign={{xl:"right",md:"right",sm:"left",xs:"left"}}
                      justify="flex-end"
                    >
                      <div className="grid-caption">Parent Trip of S.O? :</div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="grid-inout-wrapper">
                        {" "}
                        <CustomRadioGroup
                          idPrefix={"searchParentOfSplitSOSeries"}
                          onChange={setSearchFormCustom}
                          options={[
                            { id: "Yes", value: "Yes" },
                            { id: "No", value: "No" },
                            { id: "Any", value: "Any" },
                          ]}
                          default={"Any"}
                        />{" "}
                        ( first day of a split standing order series )
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container >
                    <Grid
                      item
                      xs={2}
                      display="flex"
                      textAlign={{xl:"right",md:"right",sm:"left",xs:"left"}}
                      justify="flex-end"
                    >
                      <div className="grid-caption">Child Trip of S.O? :</div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="grid-inout-wrapper">
                        {" "}
                        <CustomRadioGroup
                          idPrefix={"searchChildTripOfSplitSOSeries"}
                          onChange={setSearchFormCustom}
                          options={[
                            { id: "Yes", value: "Yes" },
                            { id: "No", value: "No" },
                            { id: "Any", value: "Any" },
                          ]}
                          default={"Any"}
                        />{" "}
                        ( subsequent days of a split standing order )
                      </div>
                    </Grid>
                  </Grid> 
                  <Grid container >
                    <Grid
                      item
                      xs={2}
                      display="flex"
                      textAlign={{xl:"right",md:"right",sm:"left",xs:"left"}}
                      justify="flex-end"
                    >
                      <div className="grid-caption">Print Emailed? :</div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="grid-inout-wrapper">
                        {" "}
                        <CustomRadioGroup
                          idPrefix={"searchPrintEmaild"}
                          onChange={setSearchFormCustom}
                          options={[
                            { id: "Yes", value: "Yes" },
                            { id: "No", value: "No" },
                            { id: "Any", value: "Any" },
                          ]}
                          default={"Any"}
                        />{" "}
                      </div>
                    </Grid>
                  </Grid>*/}
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          xl={2}
          display="flex"
          textAlign={{ xl: "right", md: "right", sm: "left", xs: "left" }}
          justify="flex-end"
          className="grid-caption-wrapper"
        >
          <div className="grid-caption"> Transport Type : </div>
        </Grid>
        <Grid item xs={10}>
          <Autocomplete
            className="grid-inout-wrapper"
            id="searchTransportType"
            options={transportTypeList}
            autoHighlight
            value={searchForm.searchTransportType}
            onChange={(event, value) => {
              setSearchFormAutoComplete("searchTransportType", value);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                className="grid-inout-wrapper search-input-box"
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {/* <Grid container >
                    <Grid item xs={2} display="flex">
                      <div className="grid-caption"> Transport Provider : </div>
                    </Grid>
                    <Grid item xs={10}>
                      <Autocomplete
                        className="grid-inout-wrapper"
                        id="searchTransportProvider"
                        options={transportProviderList}
                        autoHighlight
                        value={searchForm.searchTransportProvider}
                        onChange={(event, value) => {
                          setSearchFormAutoComplete(
                            "searchTransportProvider",
                            value
                          );
                        }}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            className="grid-inout-wrapper"
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid> */}
      {/* <Grid container >
                    <Grid
                      item
                      xs={2}
                      display="flex"
                      textAlign={{xl:"right",md:"right",sm:"left",xs:"left"}}
                      justify="flex-end"
                    >
                      <div className="grid-caption"> Date Exported: </div>
                    </Grid>
                    <Grid item xs={2}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date Exported"
                          id="searchDateExportedFrom"
                          format="MM/DD/YYYY"
                          value={searchForm.searchDateExportedFrom}
                          onChange={(newValue) => {
                            setSearchFormDate(
                              "searchDateExportedFrom",
                             newValue.format("MM/DD/YYYY")
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={
                                searchForm.searchDateExportedFrom
                              }
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={1}>
                      to
                    </Grid>
                    <Grid item xs={2}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date Exported"
                          id="searchDateExportedTo"
                          format="MM/DD/YYYY"
                          value={searchForm.searchDateExportedTo}
                          minDate={moment(
                            searchForm.searchDateExportedFrom
                          ).toDate()}
                          onChange={(newValue) => {
                            setSearchFormDate(
                              "searchDateExportedTo",
                             newValue.format("MM/DD/YYYY")
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={searchForm.searchDateExportedTo}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid> */}
      <Grid container>
        {" "}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          xl={12}
          className="search-string-class"
        >
          <Alert severity="info">{searchstring}</Alert>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={0} sm={0} md={2} xl={2} display="flex"></Grid>
        <Grid item xs={12} sm={12} md={10} xl={10} sx={{ textAlign: "right" }}>
          {!searchForm.fetching ? (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  searchForm.page = 0;

                  setState({ searchForm: searchForm }, fetchResults);
                }}
              >
                <SearchIcon /> Search
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  excelExport();
                }}
              >
                <InsertDriveFileIcon /> Excel{" "}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  pdfExport();
                }}
              >
                <InsertDriveFileIcon /> PDF{" "}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  resetSearchForm();
                }}
              >
                <RestartAltIcon /> Reset{" "}
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  updateSchedule();
                }}
              >
                <ElectricBoltIcon /> Live Update
              </Button>
            </>
          ) : (
            <></>
          )}

          {searchForm.fetching ? (
            <>
              <CircularProgress /> Loading ...
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
}
export default Search;
