import React from "react";

import Box from "@mui/material/Box";
import moment from "moment";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import "react-toastify/dist/ReactToastify.css";
import Checkbox from "@mui/material/Checkbox";

import { Autocomplete, TextField } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import PersonIcon from "@mui/icons-material/Person";
import ScheduleEditorLeg from "./scheduleEditorLeg";

function ScheduleEditor({
  row,
  setState,
  cars,
  drivers,
  schedules,
  updateTripSchedule,
  user,
}) {
  return (
    <>
      {" "}
      {/* <TableRow
        key={row.name}
        sx={{
          display: { xl: "table-row", md: "table-row", sm: "none", xs: "none" },
        }}
        className="schedule-leg-row"
      >
        <TableCell
          colSpan={11}
          align="right"
          className="trip-results-data-cell-fone"
        >
          <Table>
            <TableBody> */}
      {row.triplegs &&
        row.triplegs.map((tl, index) => {
          let scheduleKey = row.invoiceNumber + "<SP>" + tl.tripLegSequence;
          let schadule = schedules[scheduleKey]
            ? schedules[scheduleKey]
            : {
                updated: true,
                invoice: row.invoiceNumber,
                saved: false,
                leg: tl.tripLegSequence,
                key: scheduleKey,
                self: false,
              };
          return (
            <ScheduleEditorLeg
              user={user}
              row={row}
              setState={setState}
              cars={cars}
              tripSchadule={schadule}
              drivers={drivers}
              schedules={schedules}
              updateTripSchedule={updateTripSchedule}
              tl={tl}
              key={index}
              scheduleKey={scheduleKey}
            />
          );
        })}
      {/* </TableBody>
          </Table>
        </TableCell>
      </TableRow>{" "} */}
    </>
  );
}
export default ScheduleEditor;
