
import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";


const StyledButton = styled(MuiButton)(({theme}) =>({
    borderColor: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
    minWidth:70,
    padding: theme.spacing(1),
  }));


  export default StyledButton;