import { React, useState, useEffect } from "react";
import moment from "moment";

import { Button, Grid, NativeSelect, TextField } from "@mui/material";
import { Box } from "@mui/system";

import { useForm } from "../../hooks/useForms";

const initValues = {
  searchInvoiceNumber: "",
  searchCINNumber: "",
  searchName: "",
  searchServiceStartsFrom: moment(""),
  searchServiceStartsTo: moment(""),
  searchServiceEndsFrom: moment(""),
  searchServiceEndsTo: moment(""),
  searchFromDOBFrom: moment(""),
  searchFromDOBTo: moment(""),
  searchMasDriver: "",
  searchDKDriver: "",
};

const SearchFormBody = ({ setSearchByForm, searchForm, driverList }) => {
  const { values, setValues, handleOnChange } = useForm(initValues, true);

  const handleSearchServiceDates = (e, start, end) => {
    const startDate = moment(values[start]);
    const endDate = moment(values[end]);
    values[start] = startDate.format("MM/DD/YYYY");
    /**
     *  Check if the start has changed , then only adjust end date
     */

    if (e.target.name == "searchServiceStartsFrom") {
      values[end] = startDate.add(1, "days").format("MM/DD/YYYY");
    } else {
      values[end] = endDate.format("MM/DD/YYYY");
    }
    setSearchByForm({
      ...values,
      [start]: values[start],
      [end]: values[end],
    });
  };

  useEffect(() => {
    if (searchForm) {
      setValues({
        ...values,
        searchInvoiceNumber: searchForm.searchInvoiceNumber
          ? searchForm.searchInvoiceNumber
          : "",
        searchCINNumber: searchForm.searchCINNumber
          ? searchForm.searchCINNumber
          : "",
        searchName: searchForm.searchName ? searchForm.searchName : "",
        searchServiceStartsFrom: searchForm.searchServiceStartsFrom
          ? searchForm.searchServiceStartsFrom
          : "",
        searchServiceStartsTo: searchForm.searchServiceStartsTo
          ? searchForm.searchServiceStartsTo
          : "",
        searchServiceEndsFrom: searchForm.searchServiceEndsFrom
          ? searchForm.searchServiceEndsFrom
          : "",
        searchServiceEndsTo: searchForm.searchServiceEndsTo
          ? searchForm.searchServiceEndsTo
          : "",
        searchFromDOBFrom: searchForm.searchFromDOBFrom
          ? searchForm.searchFromDOBFrom
          : "",
        searchFromDOBTo: searchForm.searchFromDOBTo
          ? searchForm.searchFromDOBTo
          : "",
        searchMasDriver: searchForm.searchMasDriver
          ? searchForm.searchMasDriver
          : "",
        searchDKDriver: searchForm.searchDKDriver
          ? searchForm.searchDKDriver
          : "",
      });
    }
  }, [searchForm]);

  return (
    <Grid item sm={12} className={"creteria-container-wrapper"}>
      <Grid container style={{ marginTop: "1rem" }}>
        <Grid xs={12} item>
          <Grid container>
            <Grid item sm={12}>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                  className="attestations_labels-wrapper"
                >
                  <div className="attestations_labels"> Invoice Number </div>
                </Grid>
                <Grid item xs={7} sm={7} md={7} xl={7}>
                  <TextField
                    className="attestations_inputs search-input-box"
                    id="searchInvoiceNumber"
                    name="searchInvoiceNumber"
                    variant="outlined"
                    size="small"
                    value={
                      values.searchInvoiceNumber
                        ? values.searchInvoiceNumber
                        : ""
                    }
                    onChange={(e) => {
                      handleOnChange(e);
                    }}
                    onBlur={(e) => {
                      setSearchByForm({
                        ...values,
                        ["searchInvoiceNumber"]: values.searchInvoiceNumber,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={12}>
              <Grid container style={{ marginTop: "1rem" }}>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                  display="flex"
                  className="attestations_labels-wrapper"
                >
                  <div className="attestations_labels">
                    {" "}
                    CIN/Medicine AID Number{" "}
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    className="attestations_inputs search-input-box"
                    id="searchCINNumber"
                    name={"searchCINNumber"}
                    variant="outlined"
                    size="small"
                    value={values.searchCINNumber ? values.searchCINNumber : ""}
                    onChange={(e) => handleOnChange(e)}
                    onBlur={(e) =>
                      setSearchByForm({
                        ...values,
                        ["searchCINNumber"]: values.searchCINNumber,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: "1rem" }}>
            <Grid item sm={12}>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  xl={4}
                  className="attestations_labels-wrapper"
                >
                  <div className="attestations_labels"> Patient Name </div>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    className="attestations_inputs search-input-box"
                    id="searchName"
                    name={"searchName"}
                    variant="outlined"
                    size="small"
                    value={values.searchName ? values.searchName : ""}
                    onChange={(e) => handleOnChange(e)}
                    onBlur={(e) =>
                      setSearchByForm({
                        ...values,
                        ["searchName"]: values.searchName,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "1rem" }}>
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              xl={4}
              className="attestations_labels-wrapper"
            >
              <div className="attestations_labels"> Trip Date</div>
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              xl={4}
              //   className="datepicker-wrapper"
            >
              <input
                type="date"
                className={'attestations_inputs thin-border-select'}
                name="searchServiceStartsFrom"
                value={
                  values && values.searchServiceStartsFrom
                    ? moment(values.searchServiceStartsFrom).format(
                        "YYYY-MM-DD"
                      )
                    : ""
                }
                onChange={(e) => {
                  handleOnChange(e);
                  handleSearchServiceDates(
                    e,
                    "searchServiceStartsFrom",
                    "searchServiceStartsTo"
                  );
                }}
                onBlur={(e) =>
                  handleSearchServiceDates(
                    e,
                    "searchServiceStartsFrom",
                    "searchServiceStartsTo"
                  )
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              xl={4}
              //   className="datepicker-wrapper"
            >
              <input
                type="date"
                className={'attestations_inputs thin-border-select'}
                name="searchServiceStartsTo"
                value={
                  values && values.searchServiceStartsTo
                    ? moment(values.searchServiceStartsTo).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  handleOnChange(e);
                  handleSearchServiceDates(
                    e,
                    "searchServiceStartsFrom",
                    "searchServiceStartsTo"
                  );
                }}
                onBlur={(e) =>
                  handleSearchServiceDates(
                    e,
                    "searchServiceStartsFrom",
                    "searchServiceStartsTo"
                  )
                }
              />
            </Grid>
          </Grid>

          {/* <Grid container>
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              xl={4}
              className="attestations_labels-wrapper"
            >
              <div className="attestations_labels"> Service Ends </div>
            </Grid>
            <Grid
              item
              xs={7}
              sm={7}
              md={7}
              xl={7}
              //   className="datepicker-wrapper"
            >
              <input
                type="date"
                className="attestations_inputs"
                name="searchServiceEndsFrom"
                value={
                  values && values.searchServiceEndsFrom
                    ? moment(values.searchServiceEndsFrom).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => handleOnChange(e)}
                onBlur={(e) =>
                  handleSearchServiceDates(
                    "searchServiceEndsFrom",
                    "searchServiceEndsTo"
                  )
                }
              />
            </Grid> */}
          {/* <Grid
              item
              xs={12}
              sm={6}
              md={6}
              xl={6}
              //   className="datepicker-wrapper"
            >
              <input
                type="date"
                className="attestations_inputs"
                name="searchServiceEndsTo"
                value={
                  values && values.searchServiceEndsTo
                    ? moment(values.searchServiceEndsTo).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => handleOnChange(e)}
                onBlur={(e) =>
                  handleSearchServiceDates(
                    "searchServiceEndsFrom",
                    "searchServiceEndsTo"
                  )
                }
              />
            </Grid> */}
          {/* </Grid> */}
          {/* <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              xl={12}
              className="attestations_labels-wrapper"
            >
              <div className="attestations_labels"> Date Of Birth </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              xl={6}
              //   className="datepicker-wrapper"
            >
              <input
                type="date"
                className="attestations_inputs"
                name="searchFromDOBFrom"
                value={
                  values && values.searchFromDOBFrom
                    ? moment(values.searchFromDOBFrom).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => handleOnChange(e)}
                onBlur={(e) =>
                  handleSearchServiceDates(
                    "searchFromDOBFrom",
                    "searchFromDOBTo"
                  )
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              xl={6}
              //   className="datepicker-wrapper"
            >
              <input
                type="date"
                className="attestations_inputs"
                name="searchFromDOBTo"
                value={
                  values && values.searchFromDOBTo
                    ? moment(values.searchFromDOBTo).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => handleOnChange(e)}
                onBlur={(e) =>
                  handleSearchServiceDates(
                    "searchFromDOBFrom",
                    "searchFromDOBTo"
                  )
                }
              />
            </Grid>
          </Grid> */}

          <Grid container>
            {/* <Grid
              item
              xs={12}
              sm={4}
              md={4}
              xl={4}
              className="attestations_labels-wrapper"
            >
              <div className="attestations_labels"> MAS Driver </div>
            </Grid>
            <Grid item xs={7}>
              <Box
                sx={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginBottom: 0,
                  marginTop: 0,
                }}
              >
                <NativeSelect
                  className="attestations_inputs search-input-select"
                  name="searchMasDriver"
                  value={values.searchMasDriver}
                  onChange={(e) => handleOnChange(e)}
                  onBlur={(e) =>
                    setSearchByForm({
                      ...values,
                      ["searchMasDriver"]: values.searchMasDriver,
                    })
                  }
                >
                  <option value="All">All</option>
                  {driverList != null &&
                    driverList.map((option, index) => (
                      <option value={option.id} key={index}>
                        {option.firstName} {option.lastName}
                      </option>
                    ))}
                </NativeSelect>
              </Box>
            </Grid> */}
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              xl={4}
              className="attestations_labels-wrapper"
            >
              <div className="attestations_labels"> Driver </div>
            </Grid>
            <Grid item xs={7}>
              <Box
                style={{
                  paddingTop: '2px',
                  paddingBottom: '2px',
                  marginBottom: 0,
                  marginTop: 0,
                }}
              >
                <NativeSelect
                  className={'attestations_inputs thin-border-select'}
                  name="searchDKDriver"
                  value={values.searchDKDriver}
                  onChange={(e) => handleOnChange(e)}
                  // onBlur={(event, value) => handleDKDriver(event, value)}
                  onBlur={(e) =>
                    setSearchByForm({
                      ...values,
                      ["searchDKDriver"]: values.searchDKDriver,
                    })
                  }
                >
                  <option value="All">All</option>
                  {driverList != null &&
                    driverList.map((option, index) => (
                      <option value={option.id} key={index}>
                        {option.firstName} {option.lastName}
                      </option>
                    ))}
                </NativeSelect>
              </Box>
            </Grid>
            <Grid>
              <br></br>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const FormHeader = ({ title }) => {
  return (
    <Grid container>
      <Grid item sm={12}>
        <Box className={"attestations_creteria_header"}>{title}</Box>
      </Grid>
    </Grid>
  );
};

const SearchBy = ({ searchForm, resetSearchForm, search, driverList }) => {
  const [searchByForm, setSearchByForm] = useState({});

  const handleSearch = () => {
    search(searchByForm);
  };

  return (
    <Grid container>
      <Grid item sm={12}>
        <Box className={"attestations_creteria_container"}>
          <Grid container>
            <Grid item sm={12}>
              <FormHeader title="Search By" />
            </Grid>
            <Grid item sm={12}>
              <SearchFormBody
                setSearchByForm={setSearchByForm}
                searchForm={searchForm}
                driverList={driverList}
              />
            </Grid>
            <Grid item sm={12}>
              <br></br>
            </Grid>
            <Grid item sm={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  textAlign: "end",
                  paddingTop:"10px",
                  paddingBottom:"10px"
                }}
              >
                <Button variant="contained" onClick={() => handleSearch()}>
                  Search
                </Button>
                <Button variant="outlined" onClick={resetSearchForm}>
                  Clear
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SearchBy;
