import React, { Component } from "react";
import "fontsource-roboto";
import { BASE_URL } from "../../config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";


import {
  TextField,
  Autocomplete,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";

class UserRights extends Component {
  constructor(props) {
    super(props);
   // let url = new URL(window.location.href);

    this.state = {
      userGroups: [],
      sysProgramGroups: [],
      sysPrograms: [],
      selectedProgram : 0 ,
      programRights : [], 
      selectedLevel: {
        name: "Select a group",
        description: "System Administrator",
        id: 0,
        createdAt: "2022-08-12T17:17:08.000Z",
        updatedAt: "2022-08-12T17:17:08.000Z",
      },
    };

    this.updateRight = this.updateRight.bind(this);

  }
  async componentDidMount() {
    let user = { active: false, name: "" };
    if (localStorage.getItem("session")) {
      user = JSON.parse(localStorage.getItem("session"));
    }
    this.setState({ loggedUser: user });

    const api = axios.create({
      /*baseURL: `https://reqres.in/api`*/
      baseURL: BASE_URL,
      headers : {
        "x-auth-key": user.key,
      }
    });
  
    

    const userGroups = await api.get( "/userlevel/allwithoutpage?massession="+ user.key);
    await this.setState({ userGroups: userGroups.data.data });

    const sysProgramGroups = await api.get(  "/sysprogramgroup/allwithoutpage?massession="+ user.key);
    await this.setState({ sysProgramGroups: sysProgramGroups.data.data });

    const sysPrograms = await api.get(  "/sysprogram/allwithoutpage?massession="+ user.key);
    await this.setState({ sysPrograms: sysPrograms.data.data });


    const sysRights = await api.get(  "/user/findrights?massession="+ user.key);
    await this.setState({ programRights: sysRights.data });
  }

  async updateRight(selectedProgram,operation){
if(this.state.selectedLevel.id > 0 ){

  let right = { };
  right.level = this.state.selectedLevel.id ; 
  right.program =  selectedProgram ;
  right.operation = operation ;
  // send the username and password to the server
  try {
    const response = await axios.post(
      BASE_URL + "/user/updateright",
      right
    );
    // set the state of the user
    //setUser(response.data);
    // store the user in localStorage
   // localStorage.setItem("session", JSON.stringify(response.data));

    console.log(response.data);
    await this.setState({programRights : response.data });
   // props.onLogin(response.data);
    toast.success("Updated ! ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });


   // user.active = false ;  this.setSessionState(user);
  } catch (error) {
    console.log("error Print");
    console.log(error);
    if(error.response.data){
    toast.error(error.response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
}
  }
} else{
  toast.error("Please select the level", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
} 
  }

  render() {
    //let loggedUser = this.state.loggedUser;

    return (
      <>
        <ToastContainer />
        <Grid container spacing={3}>
          {/* Chart */}

          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h2>
                {" "}
                <AssignmentIndIcon /> User Rights management{" "}
              </h2>

              <Box
                component="form"
                onSubmit={this.handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <Grid container>
                  <Grid item xs={12} md={3} lg={3}>
                    User Level / Group
                  </Grid>
                  <Grid item xs={12} md={9} lg={9}>
                    <Autocomplete
                      xs={{
                        width: 50,
                      }}
                      className="grid-inout-wrapper"
                      id="userLevel"
                      options={this.state.userGroups}
                      autoHighlight
                      getOptionLabel={(option) => option.name}
                      value={this.state.selectedLevel}
                      onChange={(event, value) => {
                        console.log(value);
                        this.setState({ selectedLevel: value });
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          className="grid-inout-wrapper search-input-box"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Grid container>
                      {this.state.sysProgramGroups.map((g) => {
                        return (
                          <>
                            <Grid item xs={12} md={12} lg={12}>
                              <Grid container>
                                <Grid item xs={12} md={12} lg={12}>
                                  {" "}
                                  {g.name}{" "}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  paddingLeft={5}
                                >
                                  <Grid container>
                                    {this.state.sysPrograms
                                      .filter((p) => p.programgroup === g.id)
                                      .map((p) => {
                                        return (
                                          <>
                                            <Grid item xs={12} md={8} lg={8}>
                                              <FormGroup>
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox checked={ (this.state.programRights.find(t=> (t.level ===this.state.selectedLevel.id && t.program ===  p.id))?this.state.programRights.find(t=> (t.level ===this.state.selectedLevel.id && t.program ===  p.id)).operation : false )} onChange={ (e ) =>  {this.updateRight(p.id , e.target.checked)}}  />
                                                  }
                                                  label={p.name}
                                                />
                                              </FormGroup>
                                            </Grid>
                                          </>
                                        );
                                      })}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}></Grid>
          <Grid item xs={12} md={6} lg={6}></Grid>
          {/* Recent Deposits */}

          {/* Recent Orders */}
        </Grid>
      </>
    );
  }
}

export default UserRights;
