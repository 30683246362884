import {
  CloudUpload,
  Download,
  Print,
  RefreshRounded,
} from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { React, useEffect, useState } from "react";

const HeaderActions = (props) => {
  return (
    <Grid container>
      {!props?.csv && (
        <Grid
          item
          sm={3}
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <IconButton>
            <RefreshRounded />
          </IconButton>
          <a>Refresh Attestations</a>
        </Grid>
      )}
      <Grid
        item
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {!props?.csv && (
          <>
            <IconButton>
              <Download />
            </IconButton>
            <a href="#">Download Attestations</a>

            <IconButton>
              <Print />
            </IconButton>
            <a href="#">Print Attestations</a>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default HeaderActions;
