
import React, { useCallback, useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { styled } from "@mui/material/styles";
import axios from "axios";

const PLACES_URL = "https://maps.googleapis.com/maps/api/geocode/json?";
const GOOGLE_API_KEY = "AIzaSyDXmlo7N22Yvdo4yyDyuAE93nQyi6Vgb9o"

const MapComponent = ({address, lat, lng, changeRef}) => {
    
     const [marker, setMarker] = useState(null);
     const [map, setMap] = useState(null);
     const [center, setCenter] = useState({
          lat: lat?Number(lat):-3.745,
          lng: lng?Number(lng):- 38.523
      });

      async function getGeoCode(address) {
        return new Promise(async (resolve, reject) => {
          try {
            let URL = `${PLACES_URL}key=${GOOGLE_API_KEY}&address=${address.replaceAll(
              " ",
              "+"
            )}`;
            const response = await axios.get(URL);
            if (response.status == 200) {
              const location = response.data.results[0].geometry.location;
              const lat = location.lat ? location.lat : null;
              const lng = location.lng ? location.lng : null;
              resolve({ lat: Number(lat), lng: Number(lng) });
            } else {
              resolve({ lat: null, lng: null });
            }
          } catch (err) {
            console.log(err);
            resolve({ lat: null, lng: null });
          }
        });
      }
      

    const containerStyle = {
        position:"relative",
        left:"0",
        top:"0",
        width: '100%',
        height: '100%'
      };

    useEffect(async ()=>{
      let latLng = {};
      if(!lat && !lng){
        latLng = await getGeoCode(address)
      } else {
        latLng = {
          lat: lat?Number(lat):-3.745,
          lng: lng?Number(lng):- 38.523
        }
      }
      setMarker(latLng);
      setCenter(latLng);
      changeRef(latLng);
    },[address, lat, lng])


    const {isLoaded} = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey:GOOGLE_API_KEY
    });

    const onLoad = useCallback((map)=>{
        setMarker(center)
        setMap(map)
    },[]);

    const handleMarker = (m) =>{
      
      setMarker({
        lat:m.latLng.lat(),
        lng:m.latLng.lng()
      })
      changeRef({
        lat:m.latLng.lat(),
        lng:m.latLng.lng()
      });
    }
    const onUnmount = useCallback(()=>{
      setMap(null);
    },[]);
     
    return  (
        
        isLoaded?
          <div style={{height:"100%", width:"100%"}}>
            <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={25}
            onClick={(marker)=>handleMarker(marker)}
            onLoad={onLoad}
            onUnmount={onUnmount}>
                <Marker 
                    key={1}
                    position={marker}
                    onRightClick={(marker)=>handleMarker(marker)}
                />
        </GoogleMap>
        </div>
        :
        <></>)
}

const StyledMap = styled(MapComponent)(({theme, multiple}) =>({
    backgroundColor:theme.palette.background.form,
    padding: theme.spacing(1),
    width:"100%",
    heigh:"100%"
  }))


export default React.memo(StyledMap);