
import { styled } from "@mui/material/styles";


const StyledDatePicker = styled('input')(({theme}) =>({
    borderColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  }));


  export default StyledDatePicker;