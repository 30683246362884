import React, { Component } from "react";
import "fontsource-roboto";
import { BASE_URL } from "../../config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
 
import md5  from "md5";
import Button from "@mui/material/Button";
import PasswordIcon from '@mui/icons-material/Password';
import KeyIcon from '@mui/icons-material/Key';
import { TextField } from "@mui/material";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    let url = new URL(window.location.href);

    this.state = {password:"",passwordAgain:"",userName:""};
    this.setSessionState = this.setSessionState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    
  }
  componentDidMount() {
    let user = { active: false, name: "" };
    if (localStorage.getItem("session")) {
      user = JSON.parse(localStorage.getItem("session"));
    }
    this.setState({loggedUser :user});
   
  }
  setSessionState = async(userSession ) => {

    this.props.setSessionState(userSession);
      
    }
  async handleSubmit(e){
    e.preventDefault();
    let password = this.state.password ; 
    let passwordAgain = this.state.passwordAgain ; 
    let userName = this.state.userName;
    let loggedUser = this.state.loggedUser;

    if (password != "" && password == passwordAgain) {
      let user = { userName, password };
      user.key = loggedUser.key;
      user.password = md5(user.password);
      // send the username and password to the server
      try {
        const response = await axios.post(
          BASE_URL + "/user/changepassword",
          user
        );
        // set the state of the user
        //setUser(response.data);
        // store the user in localStorage
       // localStorage.setItem("session", JSON.stringify(response.data));

        console.log(response.data);
       // props.onLogin(response.data);
        toast.success("Welcome ! ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });


        user.active = false ;  this.setSessionState(user);
      } catch (error) {
        console.log("error Print");
        console.log(error);
        if(error.response.data){
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }
      }
    } else {
        if (password == "" ) {
            toast.error("Password can not be empty ", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
        }
        if (password != passwordAgain) {
            toast.error("Password mismatch ", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
        }
    }
  };

  render() {
    let loggedUser = this.state.loggedUser;

    return (
      <>
      <ToastContainer />
        <Grid container spacing={3}>
          {/* Chart */}

          <Grid item xs={12} md={12} lg={12}>
          <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h2>  <KeyIcon />  Change Password  </h2>

              <Box
            component="form"
            onSubmit={this.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              onChange={({ target }) => {this.setState({password:target.value});}}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="passwordAgain"
              label="Password Again"
              onChange={({ target }) => {this.setState({passwordAgain:target.value});}}
              
              type="password"
              id="passwordAgain"
              autoComplete="password-again"
            />
            {/*<FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 1 }}
            >
              Change password
            </Button>
          
             
            {/*<Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>  */}
          </Box>

              </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
             
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
           
          </Grid>
          {/* Recent Deposits */}

          {/* Recent Orders */}
        </Grid>
      </>
    );
  }
}

export default ChangePassword;
