import { React, useState, useEffect, useCallback, useMemo } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, LinearProgress } from "@mui/material";
import { attestationList } from "./Attestations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { darken, lighten, styled } from "@mui/material/styles";

const datagridSx = {
  borderRadius: 2,
  "& .MuiDataGrid-main": { borderRadius: 2 },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#f5fafe",
    color: "#033d5f",
    fontWeight: "bold !important",
    fontSize: 14,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold !important",
    color: "#033d5f !important",
    overflow: "visible",
    lineHeight: "1.43rem",
    whiteSpace: "normal",
  },
};

const useStyle = makeStyles({
  root: {
    "& .wrapHeader .MuiDataGrid-colCellTitle": {
      overflow: "visible",
      lineHeight: "1.43rem",
      whiteSpace: "normal",
    },
  },
});

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const SchedularDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .planner-view-grid--O-0": {
    backgroundColor: getBackgroundColor(
      theme.palette.info.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .planner-view-grid--O-1": {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .planner-view-grid--O-3": {
    backgroundColor: getBackgroundColor(
      theme.palette.secondary.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.secondary.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.secondary.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.secondary.main,
          theme.palette.mode
        ),
      },
    },
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
      <GridToolbarExport
        csvOptions={{
          allColumns: true,
          fileName: `${moment(new Date()).format(
            "YYYY-MM-DD HH:mm:ss"
          )}_scheduler`,
        }}
      />
    </GridToolbarContainer>
  );
}

const RoasterPlanningView = (props) => {
  const classes = useStyle();

  const [rows, setRows] = useState([]);
  const [masDrivers, setMasDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editedRow, setEditedRow] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);

  const columns = useMemo(
    () => [
      {
        field: "invoiceNumber",
        headerName: "Invoice Number",
        width: 120,
        editable: false,
        sortable: true,
        headerAlign: "left",
      },
      {
        field: "customer",
        headerName: "Customer",
        width: 150,
        editable: false,
        sortable: true,
        align: "left",
        headerAlign: "left",
      },
      {
        headerName: "Pickup / Dropoff Address",
        field: "pickup_dropoff_address",
        sortable: true,
        editable: false,
        width: 420,
        renderCell: (params) => {
          const parts = params.row.pickup_dropoff_address.split("|||");
          return (
            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
              <div style={{ marginBottom: 10 }}>
                <strong style={{ color: "#033d5f" }}>Pickup : </strong>
                {parts[0]}
              </div>
              <div>
                <strong style={{ color: "#033d5f" }}>Dropoff : </strong>
                {parts.length > 0 ? parts[1] : ""}
              </div>
            </div>
          );
        },
      },
      {
        field: "leg",
        headerName: "Leg",
        type: "number",
        width: 20,
        editable: false,
        sortable: true,
        align: "center",
        headerAlign: "left",
      },
      {
        field: "driver",
        headerName: "Driver Name",
        sortable: true,
        editable: false,
        width: 100,
        type: "singleSelect",
        valueOptions: [
          ...new Set(masDrivers.map((option) => option.firstname.trim())),
        ],
      },
      {
        field: "duration",
        headerName: "Duration",
        type: "number",
        sortable: true,
        editable: false,
        width: 70,
        align: "center",
      },
      {
        field: "servic_time",
        headerName: "Service",
        type: "number",
        sortable: true,
        editable: true,
        width: 70,
        align: "center",
      },
      {
        field: "pickup_time",
        headerName: "Pickup",
        sortable: true,
        editable: true,
        width: 70,
        align: "center",
        headerAlign: "left",
        renderCell: (params) =>
          params.row.pickup_time &&
          moment(`${params.row.pickup_time}`, "hmm").format("HHmm"),
      },
      {
        field: "dropoff_time",
        headerName: "Dropoff",
        sortable: true,
        editable: true,
        width: 70,
        align: "center",
        headerAlign: "left",
        renderCell: (params) =>
          params.row.dropoff_time &&
          moment(`${params.row.dropoff_time}`, "hmm").format("HHmm"),
      },
      {
        field: "mas_pickup_dropoff_time",
        headerName: "MAS Time",
        sortable: true,
        editable: false,
        width: 150,
        align: "left",
        headerAlign: "left",

        renderCell: (params) => {
          if (params.row.leg === 0) {
            return <span></span>;
          }

          const parts = params.row.mas_pickup_dropoff_time.split("|||");

          if (parts[1] === "") {
            return (
              <span>
                <strong style={{ color: "#033d5f" }}>{parts[0]}</strong>
                <span style={{ marginRight: 10 }}></span>
              </span>
            );
          }

          return (
            <span>
              <strong style={{ color: "#033d5f" }}>{parts[0]}</strong>
              <span style={{ marginRight: 5 }}></span>
              <span style={{ marginRight: 5 }}>
                <strong style={{ color: "#033d5f" }}>/</strong>{" "}
              </span>
              <strong style={{ color: "#033d5f" }}>{parts[1]}</strong>
            </span>
          );
        },
      },
      {
        headerName: "Lat / Lng",
        field: "pickuplatlng_dropofflatlng",
        sortable: true,
        editable: false,
        width: 220,
        renderCell: (params) => {
          const parts = params.row.pickuplatlng_dropofflatlng.split("|||");
          const pickupLatlng = parts[0] ? parts[0].split(",") : null;
          const dropLatlng = parts[1] ? parts[1].split(",") : null;
          return (
            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
              <div style={{ marginBottom: 10 }}>
                <strong style={{ color: "#033d5f" }}>Pickup : </strong>
                <span>{pickupLatlng ? pickupLatlng[0] : ""}</span>
                <br />
                <span>{pickupLatlng ? pickupLatlng[1] : ""}</span>
              </div>
              <div>
                <strong style={{ color: "#033d5f" }}>Dropoff : </strong>
                <span>{dropLatlng ? dropLatlng[0] : ""}</span>
                <br />
                <span>{dropLatlng ? dropLatlng[1] : ""}</span>
              </div>
            </div>
          );
        },
      },
      {
        field: "order",
        headerName: "Order",
        type: "Number",
        sortable: true,
        editable: false,
        width: 55,
        align: "center",
        headerAlign: "left",
      },
      {
        field: "standingOrder",
        headerName: "SO",
        type: "Number",
        sortable: true,
        editable: false,
        width: 20,
        align: "center",
        headerAlign: "left",
      },
      {
        field: "tripDate",
        headerName: "Trip Date",
        sortable: true,
        editable: false,
        width: 100,
        align: "center",
        headerAlign: "left",
      },
    ],
    [masDrivers]
  );

  const [showUpateConfimation, setShowUpateConfimation] = useState(false);
  const [isInitScheduleRun, setInitScheduleRun] = useState(false)
  const [runSchedule, setRunSchedule] = useState(false)


  const handleShowUpateConfimation = () => {
    setShowUpateConfimation(true);
  }

  const handleCloseConfimation = (actionType) => {
    setShowUpateConfimation(false);

    if(actionType !== null) {
      mayPerformScheduling(actionType)
    }

  }

  const processRowUpdate = useCallback(
    (newRow) => {
      setEditedRow(newRow);
      const index = rows.findIndex((row) => row.id === newRow.id);
      const tempRows = [...rows];
      tempRows[index] = newRow;
      setRows(tempRows);

      setRunSchedule(true);      
      return newRow;
    },
    [rows, masDrivers]
  );

  useEffect(() => {
    async function getMasDrivers() {
      await attestationList
        .fetchMasDrivers()
        .then((res) => {
          setMasDrivers(res);
        })
        .catch((error) => {
          setMasDrivers([]);
        });
    }
    getMasDrivers();
  }, []);

  const handleProcessRowUpdateError = useCallback((error) => {
    toast.error(`[Error] ${error.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }, []);

  const updateRows = useCallback(async () => {
    const rowsToBeUpdated = [];

    rows.forEach((row) => {
      // TODO: Show user to warning with Invalid date records before proceed with update
      if (
      (row.pickup_time &&
        row.pickup_time !== "Invalid date") ||
        (row.dropoff_time && 
        row.dropoff_time !== "Invalid date")
      ) {
        rowsToBeUpdated.push({
          id: row.id,
          pickupTime: row.pickup_time,
          dropoffTime: row.dropoff_time,
          serviceTime: row.servic_time,
        });
      }
    });

    if (rowsToBeUpdated.length < 1) {
      return;
    }

    setLoading(true);
    await attestationList
      .updateRoasterPlanningData(rowsToBeUpdated)
      .then(() => {
        toast.success("Roaster Planning Data successfully updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(() => {
        toast.error("Roaster Planning Data update failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [rows]);

  const loadRoasterPlanningData = useCallback(async (searchAttrs) => {
    setRows([]);
    setLoading(true);
    await attestationList
      .getRoasterPlanningData(searchAttrs)
      .then((res) => {
        res.forEach((rec) => {
          rec.pickup_dropoff_address = `${rec.pickaddress}|||${rec.dropoffAddress}`;
          rec.pickuplatlng_dropofflatlng = `${rec.pickuplatlng}|||${rec.dropofflatlng}`;
          rec.mas_pickup_dropoff_time = `${rec.MAS_pickup_time}|||${rec.MAS_dropoff_time}`;
        });

        setRows([...res]);
      })
      .catch((error) => {
        setRows([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);


  const mayPerformScheduling = useCallback((actionType) => {

    if (rows?.length > 0) {
      const driverMap = {};

      rows.forEach((row) => {
        const driver = row.driver.trim();

        if (driverMap[driver]) {
          driverMap[driver] = [...driverMap[driver], row];
        } else {
          driverMap[driver] = [row];
        }
      });

      for (var driver in driverMap) {
        if (editedRow && editedRow["driver"].trim() !== driver) {
          continue;
        }

        const tempRows = [...driverMap[driver]];
        const orderedRows = tempRows.sort((a, b) => a.order - b.order);

        let initPickupTime = "0500";

        let leg1PickupTime = "";
        let leg1Duration = 0;
        let leg1ServiceTime = 0;

        let startIndex = 0;

        if (editedRow) {
          startIndex = orderedRows.findIndex(
            (oRow) => oRow.id === editedRow.id
          );
        }

        let editingInvoice = null;
        let editingLeg = 0;

        for (let index = startIndex; index < orderedRows.length; index++) {
          const element = orderedRows[index];

          if (element.isAltered === 1 && actionType === 'CURRENT') {
            // Logic: if already saved and auto update the values in the specific changing invoice 
            if (editedRow?.id ) {
              if(editingInvoice == null) {
                editingInvoice = element.invoiceNumber
                editingLeg = element.leg
              }

              if(editingInvoice === element.invoiceNumber) {

                if (selectedCell.field === "pickup_time") {
                  initPickupTime = element.pickup_time
                }
                
                if (element.leg === 0) {
                  element.pickup_time = moment(initPickupTime, "hmm").format(
                    "HHmm"
                  );
                  leg1PickupTime = element.pickup_time;
                  leg1Duration = element.duration;
                  leg1ServiceTime = element.servic_time;
                  element.dropoff_time = moment(initPickupTime, "hmm")
                    .add(leg1Duration, "minutes")
                    .format("HHmm");
                } else if (element.leg === 1) {
                  element.pickup_time = moment(`${leg1PickupTime ? leg1PickupTime : element.pickup_time}`, "hmm")
                    .add(leg1Duration, "minutes")
                    .add(leg1ServiceTime, "minutes")
                    .format("HHmm");
                
                  element.dropoff_time = moment(`${element.pickup_time}`, "hmm")
                    .add(element.duration, "minutes")
                    .format("HHmm");

                    console.log('element.dropoff_time: ', element.dropoff_time);
                }else {
                  if(editingLeg === element.leg) {
                    if (selectedCell.field === "pickup_time") {
                      element.dropoff_time = moment(`${element.pickup_time}`, "hmm")
                        .add(element.duration, "minutes")
                        .format("HHmm");
                    }
                  }else {
                    if (orderedRows[index - 1]) {
                      element.pickup_time = moment(
                        `${orderedRows[index - 1].dropoff_time}`,
                        "hmm"
                      )
                        .add(orderedRows[index - 1].servic_time, "minutes")
                        .format("HHmm");
                      element.dropoff_time = moment(`${element.pickup_time}`, "hmm")
                        .add(element.duration, "minutes")
                        .format("HHmm");
                    } else {
                      console.log("Skipping due to missing previous legs 0/1");
                    }
                  }
                  
                }
                
              }

              
            }

            continue;
          }

          if (editedRow && selectedCell) {
            if (editedRow.id === element.id) {
              if (selectedCell.field === "pickup_time") {
                element.dropoff_time = moment(element.pickup_time, "hmm")
                  .add(element.duration, "minutes")
                  .format("HHmm");
              }
            } else {
              element.pickup_time = moment(
                `${orderedRows[index - 1].dropoff_time}`,
                "hmm"
              )
                .add(orderedRows[index - 1].servic_time, "minutes")
                .format("HHmm");
              element.dropoff_time = moment(`${element.pickup_time}`, "hmm")
                .add(element.duration, "minutes")
                .format("HHmm");
            }
          } else {
            if (index > 0 && element.leg === 0) {
              initPickupTime = moment(
                `${orderedRows[index - 1].dropoff_time}`,
                "hmm"
              ).format("HHmm");
            } else if (index === 0 && element.pickup_time) {
              initPickupTime = `${element.pickup_time}`;
            }
            
            if (element.leg === 0) {
              element.pickup_time = moment(initPickupTime, "hmm").format(
                "HHmm"
              );
              leg1PickupTime = element.pickup_time;
              leg1Duration = element.duration;
              leg1ServiceTime = element.servic_time;
              element.dropoff_time = moment(initPickupTime, "hmm")
                .add(leg1Duration, "minutes")
                .format("HHmm");
            } else if (element.leg === 1) {
              element.pickup_time = moment(`${leg1PickupTime ? leg1PickupTime : element.pickup_time}`, "hmm")
                .add(leg1Duration, "minutes")
                .add(leg1ServiceTime, "minutes")
                .format("HHmm");
              element.dropoff_time = moment(`${element.pickup_time}`, "hmm")
                .add(element.duration, "minutes")
                .format("HHmm");
            }else {
              if (orderedRows[index - 1]) {
                element.pickup_time = moment(
                  `${orderedRows[index - 1].dropoff_time}`,
                  "hmm"
                )
                  .add(orderedRows[index - 1].servic_time, "minutes")
                  .format("HHmm");
                element.dropoff_time = moment(`${element.pickup_time}`, "hmm")
                  .add(element.duration, "minutes")
                  .format("HHmm");
              } else {
                console.log("Skipping due to missing previous legs 0/1");
              }
            }
          }
        }
      }

      
    }

    setEditedRow(null);
    setSelectedCell(null);
    
  }, [rows])

  
  useEffect(() => {
    if(rows.length > 0 ) {

      if(isInitScheduleRun === false) {
        setInitScheduleRun(true)
        mayPerformScheduling("ALL")
      } else if(runSchedule) {
        setRunSchedule(false)
        const timer = setTimeout(() => {
          handleShowUpateConfimation(true)
        }, 500);
        return () => clearTimeout(timer);
      }      
    }

  }, [rows]);

  const onCellClick = useCallback((cellInfo) => {
    setSelectedCell(cellInfo);
  }, []);

  useEffect(() => {
    if (Object.keys(props.searchAttrs).length === 0) {
      loadRoasterPlanningData({
        tripDate: moment(new Date()).format("YYYY-MM-DD"),
      });
    } else {
      loadRoasterPlanningData({
        tripDate: props.searchAttrs.tripDate,
        invoiceNo: props.searchAttrs.invoiceNo,
      });
    }
  }, [props]);

  return (
    <Grid container>
      <ToastContainer />
      <Dialog
        open={showUpateConfimation}
        onClose={() => handleCloseConfimation(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Update time changes"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you wish to update all invoices for the Driver?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseConfimation(null)}>Cancel</Button>
          <Button onClick={() => handleCloseConfimation("ALL")}>
            All Invoices
          </Button>
          <Button onClick={() => handleCloseConfimation('CURRENT')} autoFocus>
            Current invoice only
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Box sx={{ height: 400, width: "100%" }}>
          <SchedularDataGrid
            rows={rows}
            className={classes.root}
            sx={datagridSx}
            columns={columns}
            slots={{
              loadingOverlay: LinearProgress,
              toolbar: CustomToolbar,
            }}
            loading={loading}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            pageSizeOptions={[25]}
            autoHeight
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            onCellClick={onCellClick}
            getRowHeight={() => "auto"}
            getRowClassName={(params) =>
              `planner-view-grid--O-${
                params.row?.isStandingOrderTrip === 0 ||
                params.row?.isStandingOrderTrip === false
                  ? 0
                  : params.row?.isNoneMainFacilityTrip === 1
                  ? 3
                  : 2
              }`
            }
          />
        </Box>
      </Grid>
      <Grid item sm={12} className={"attestations_container_button"}>
        <Button variant="contained" onClick={updateRows}>
          Update
        </Button>
      </Grid>
    </Grid>
  );
};

export default RoasterPlanningView;
