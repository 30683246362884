import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";

import ListIcon from "@mui/icons-material/List";
import CheckIcon from "@mui/icons-material/Check";
import GroupIcon from "@mui/icons-material/Group";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CommuteIcon from "@mui/icons-material/Commute";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PasswordIcon from "@mui/icons-material/Password";
import InsightsIcon from "@mui/icons-material/Insights";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";

function getIcon(name, className) {
  switch (name) {
    case "InsightsIcon":
      return <InsightsIcon className={className} />;
    case "ManageAccountsIcon":
      return <ManageAccountsIcon className={className} />;
    case "AssignmentIndIcon":
      return <AssignmentIndIcon className={className} />;
    case "TouchAppIcon":
      return <TouchAppIcon className={className} />;
    case "GroupWorkIcon":
      return <GroupWorkIcon className={className} />;
    case "FolderSharedIcon":
      return <FolderSharedIcon className={className} />;
    case "PasswordIcon":
      return <PasswordIcon className={className} />;
    case "AccountCircleIcon":
      return <AccountCircleIcon className={className} />;
    case "AdminPanelSettingsIcon":
      return <AdminPanelSettingsIcon className={className} />;
    case "RssFeedIcon":
      return <RssFeedIcon className={className} />;
    case "MiscellaneousServicesIcon":
      return <MiscellaneousServicesIcon className={className} />;
    case "HelpOutlineIcon":
      return <HelpOutlineIcon className={className} />;
    case "CommuteIcon":
      return <CommuteIcon className={className} />;

    case "AssessmentIcon":
      return <AssessmentIcon className={className} />;
    case "DirectionsCarIcon":
      return <DirectionsCarIcon className={className} />;
    case "SupervisedUserCircleIcon":
      return <SupervisedUserCircleIcon className={className} />;

    case "CheckIcon":
      return <CheckIcon className={className} />;
    case "ListIcon":
      return <ListIcon className={className} />;

    case "GroupIcon":
      return <GroupIcon className={className} />;
    case "DashboardIcon":
      return <DashboardIcon className={className} />;
    case "AppRegistrationIcon":
      return <AppRegistrationIcon className={className} />;
    case "RecordVoiceOverIcon":
      return <RecordVoiceOverIcon className={className} />;

    default:
      return <></>;
  }
}

export default function mainListItems(props) {
  return (
    <React.Fragment>
      {props.menu.map((p) => {
        if (p.list.length > 0) {
          return (
            <>
              <div className="menu-group-header">
                &nbsp; {getIcon(p.group.icon, "menu-group-icon")}{" "}
                &nbsp;&nbsp;&nbsp;&nbsp; {p.group.name}
              </div>

              {p.list.map((ip) => {
                return (
                  <>
                    <ListItemButton
                      className="menu-item-class-name"
                      selected={props.screen === ip.code ? "selected" : ""}
                      onClick={() => {
                        props.setScreen(ip.code);
                      }}
                    >
                      <ListItemIcon>{getIcon(ip.icon, "")}</ListItemIcon>
                      <ListItemText primary={ip.name} title={ip.description} />
                      <i className="arrow right"></i>
                    </ListItemButton>
                  </>
                );
              })}
            </>
          );
        } else {
          return "";
        }
      })}
      {/*<>
  <div className='menu-group-header'>&nbsp;<InsightsIcon className='menu-group-icon' /> &nbsp;&nbsp;&nbsp;&nbsp; Analytics</div>
   <ListItemButton  className='menu-item-class-name'  selected={props.screen === "MAIN_DASHBOARD"?"selected":""} onClick={()=>{props.setScreen("MAIN_DASHBOARD")}}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
      <i className="arrow right"></i>
    </ListItemButton>
    <div className='menu-group-header'>&nbsp;<CommuteIcon className='menu-group-icon' /> &nbsp;&nbsp;&nbsp;&nbsp;Manage Trips</div>
      
    <ListItemButton    className='menu-item-class-name'  selected={props.screen === "TRIP_SEARCH"?"selected":""}   onClick={()=>{props.setScreen("TRIP_SEARCH")}} >
      <ListItemIcon>
        <ListIcon />
      </ListItemIcon>
      <ListItemText   primary="Trip Roster"  />
      <i className="arrow right"></i>
    </ListItemButton>
    <ListItemButton   className='menu-item-class-name under-constructions-menu'  selected={props.screen === "ATTESTATIONS"?"selected":""}  onClick={()=>{props.setScreen("ATTESTATIONS")}} >
      <ListItemIcon>
        <CheckIcon />
      </ListItemIcon>
      <ListItemText   primary="Attestations" />
      <i className="arrow right"></i>
    </ListItemButton>
 


    <div className='menu-group-header'>&nbsp;<ManageAccountsIcon className='menu-group-icon' /> &nbsp;&nbsp;&nbsp;&nbsp; Profile Management</div>

    <ListItemButton    className='menu-item-class-name under-constructions-menu'  selected={props.screen === "MY_PROFILE"?"selected":""}   onClick={()=>{props.setScreen("MY_PROFILE")}} >
      <ListItemIcon>
        <AccountCircleIcon />
      </ListItemIcon>
      <ListItemText primary="Edit My Profile" />
      <i className="arrow right"></i>
    </ListItemButton>

    <ListItemButton    className='menu-item-class-name'  selected={props.screen === "CHANGE_PASSWORD"?"selected":""}  onClick={()=>{props.setScreen("CHANGE_PASSWORD")}} >
      <ListItemIcon>
        <PasswordIcon />
      </ListItemIcon>
      <ListItemText primary="Change Password" />
      <i className="arrow right"></i>
    </ListItemButton>
    <div className='menu-group-header'>&nbsp;<AdminPanelSettingsIcon className='menu-group-icon' />  &nbsp;&nbsp;&nbsp;&nbsp;Admin Area</div>
    <ListItemButton    className='menu-item-class-name'  selected={props.screen === "CRUD_USERS"?"selected":""}  onClick={()=>{props.setScreen("CRUD_USERS")}} >
      <ListItemIcon>
        <SupervisedUserCircleIcon />
      </ListItemIcon>
      <ListItemText primary="User Management" />
      <i className="arrow right"></i>
    </ListItemButton>
    <ListItemButton    className='menu-item-class-name'  selected={props.screen === "CRUD_USER_LEVELS"?"selected":""}  onClick={()=>{props.setScreen("CRUD_USER_LEVELS")}} >
      <ListItemIcon>
        <FolderSharedIcon />
      </ListItemIcon>
      <ListItemText primary="User Level Management" />
      <i className="arrow right"></i>
    </ListItemButton>
    <ListItemButton    className='menu-item-class-name'  selected={props.screen === "CRUD_SYS_PROG_GROUP"?"selected":""}  onClick={()=>{props.setScreen("CRUD_SYS_PROG_GROUP")}} >
      <ListItemIcon>
        <GroupWorkIcon />
      </ListItemIcon>
      <ListItemText primary="System Program Group" />
      <i className="arrow right"></i>
    </ListItemButton>
    <ListItemButton    className='menu-item-class-name'  selected={props.screen === "CRUD_SYS_PROG"?"selected":""}  onClick={()=>{props.setScreen("CRUD_SYS_PROG")}} >
      <ListItemIcon>
        <TouchAppIcon />
      </ListItemIcon>
      <ListItemText primary="System Program" />
      <i className="arrow right"></i>
    </ListItemButton>


    <ListItemButton    className='menu-item-class-name'  selected={props.screen === "USER_RIGHTS"?"selected":""}  onClick={()=>{props.setScreen("USER_RIGHTS")}} >
      <ListItemIcon>
        <AssignmentIndIcon />
      </ListItemIcon>
      <ListItemText primary="User Rights" />
      <i className="arrow right"></i>
    </ListItemButton>

    <ListItemButton    className='menu-item-class-name'  selected={props.screen === "USER_RIGHTS"?"selected":""}  onClick={()=>{props.setScreen("USER_RIGHTS")}} >
      <ListItemIcon>
        <AssignmentIndIcon />
      </ListItemIcon>
      <ListItemText primary="Attestation Audit" />
      <i className="arrow right"></i>
    </ListItemButton>
 
    <ListItemButton    className='menu-item-class-name under-constructions-menu'  selected={props.screen === "CRUD_DRIVERS"?"selected":""}   onClick={()=>{props.setScreen("CRUD_DRIVERS")}} >
      <ListItemIcon>
        <GroupIcon />
      </ListItemIcon>
      <ListItemText primary="Driver Management" />
      <i className="arrow right"></i>
    </ListItemButton>

    <ListItemButton    className='menu-item-class-name under-constructions-menu'  selected={props.screen === "CRUD_CAR"?"selected":""}   onClick={()=>{props.setScreen("CRUD_CAR")}} >
      <ListItemIcon>
        <DirectionsCarIcon />
      </ListItemIcon>
      <ListItemText primary="Vehicles Management" />
      <i className="arrow right"></i>
    </ListItemButton>
    <div className='menu-group-header'> &nbsp;<AssessmentIcon className='menu-group-icon' />&nbsp;&nbsp;&nbsp;&nbsp; Reports</div>

    <ListItemButton    className='menu-item-class-name'  selected={props.screen === "FAQ"?"selected":""}  onClick={()=>{props.setScreen("FAQ")}} >
      <ListItemIcon>
        <HelpOutlineIcon />
      </ListItemIcon>
      <ListItemText primary="FAQ" />
      <i className="arrow right"></i>
    </ListItemButton>

    <div className='menu-group-header'> &nbsp;<MiscellaneousServicesIcon className='menu-group-icon' /> &nbsp;&nbsp;&nbsp;&nbsp;Data Services </div>
    <ListItemButton    className='menu-item-class-name'  selected={props.screen === "UPDATE_ROSTER"?"selected":""}  onClick={()=>{props.setScreen("UPDATE_ROSTER")}} >
      <ListItemIcon>
        <RssFeedIcon />
      </ListItemIcon>
      <ListItemText primary="Update Roster" />
      <i className="arrow right"></i>
    </ListItemButton>
    
    </>*/}
    </React.Fragment>
  );
}
/*
export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>
  </React.Fragment>
);
*/
