import { Box, Grid, NativeSelect, Button } from "@mui/material";
import { React, useState, useEffect } from "react";
import CustomRadioGroup from "../../components/CustomRadioGroup";

import { useForm } from "../../hooks/useForms";

const initValues = {
  county: "",
  tripStatus: [],
  opencorrection: "Any",
  searchChangeSince: "Any",
  searchExportStatus: "Any",
  searchStandingOrder: "Any",
  attestedwithoutpa: "Any",
  searchCounty: "All",
  searchTransportType: "All",
  searchMasDriver: "",
  searchDKDriver: "",
};

const tripStatus = [
  { id: 1, name: "Eligible", checked: true },
  { id: 2, name: "Cancel/ReassignOther", checked: false },
  { id: 3, name: "Cancelled/New S/O", checked: false },
  { id: 4, name: "Cancelled", checked: false },
  { id: 5, name: "Cancelled/Ineligible", checked: false },
  { id: 6, name: "Cancelled/Combined", checked: false },
  { id: 7, name: "All", checked: false },
];

const FilterFormBody = ({ setFilters, transportTypeList, countyList }) => {
  const { values, setValues, handleOnChange, resetForm } = useForm(
    initValues,
    true
  );

  useEffect(() => {
    setValues({
      ...values,
      ["tripStatus"]: tripStatus,
    });
  }, []);

  const handleTripStatus = (e, index, item) => {
    let tripStatus = values.tripStatus;
    for (let obj of tripStatus) {
      if (obj.name === e.target.value) {
        obj.checked = e.target.checked;
      }
    }
    setValues({
      ...values,
      ["tripStatus"]: tripStatus,
    });
  };

  const handleFilteration = () => {
    setFilters(values);
  };
  return (
    <Grid container>
      <Grid xs={12} item>
        <Grid container>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            xl={6}
            className="attestations_labels-wrapper"
          >
            <div className="attestations_labels"> County </div>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            xl={6}
            className="attestations_labels-wrapper"
          >
            <div className="attestations_labels"> Trip type </div>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                paddingTop: 0,
                paddingBottom: 0,
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              <NativeSelect
                className={'attestations_inputs thin-border-select'}
                name="searchCounty"
                value={values.searchCounty}
                onChange={(event, value) =>
                  handleOnChange(event, "searchCounty", value)
                }
                onBlur={handleFilteration}
              >
                <option value="All">All</option>
                {countyList != null &&
                  countyList.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </NativeSelect>
            </Box>
          </Grid>

          <Grid item xs={6} sm={6} md={6} xl={6}>
            <NativeSelect
              className={'attestations_inputs thin-border-select'}
              // className=" "
              name="searchTransportType"
              value={values.searchTransportType}
              onChange={(event, value) =>
                handleOnChange(event, "searchTransportType", value)
              }
              onBlur={handleFilteration}
            >
              <option value="All">All</option>
              {transportTypeList != null &&
                transportTypeList.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
            </NativeSelect>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            xl={12}
            className="attestations_labels-wrapper"
          >
            <div className="attestations_labels"> Trip Status </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            className="attestations_labels-wrapper"
            style={{
              marginLeft: "1rem",
            }}
          >
            {values.tripStatus &&
              values.tripStatus.map((item, index) => (
                <div key={index} className="trip-status-check-wrap">
                  <input
                    id={"TR-S_CH_" + item.id}
                    value={item.name}
                    type="checkbox"
                    className="trip-status-check"
                    checked={item.checked}
                    onChange={(e) => {
                      handleTripStatus(e, index, item);
                    }}
                    onBlur={handleFilteration}
                  />
                  <label htmlFor={"TR-S_CH_" + item.id}>{item.name}</label>
                </div>
              ))}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item sm={12}>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                xl={6}
                className=" attestations_labels-wrapper"
              >
                <span className="attestations_labels">
                  Changed Since Vendor Notified?
                </span>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                xl={6}
                className=" attestations_labels-wrapper"
              >
                <CustomRadioGroup
                  idPrefix={"searchChangeSince"}
                  name={"searchChangeSince"}
                  onChange={(e, value) => {
                    handleOnChange(e, "searchChangeSince", value);
                  }}
                  onBlur={(e) => {
                    handleFilteration();
                  }}
                  options={[
                    { id: "Yes", value: "Yes" },
                    { id: "No", value: "No" },
                    { id: "Any", value: "Any" },
                  ]}
                  default={"Any"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item sm={12}>
            <Grid container>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                xl={6}
                className="attestations_labels-wrapper"
              >
                <span className="attestations_labels">Standing Order? </span>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                xl={6}
                className="attestations_labels-wrapper"
              >
                <CustomRadioGroup
                  idPrefix={"searchStandingOrder"}
                  name={"searchStandingOrder"}
                  onChange={(e, value) => {
                    handleOnChange(e, "searchStandingOrder", value);
                  }}
                  onBlur={(e) => {
                    handleFilteration();
                  }}
                  options={[
                    { id: "Yes", value: "Yes" },
                    { id: "No", value: "No" },
                    { id: "Any", value: "Any" },
                  ]}
                  default={"Any"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            xl={6}
            className="attestations_labels-wrapper"
          >
            <span className="attestations_labels">Attested without PA?</span>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            xl={6}
            className="attestations_labels-wrapper"
          >
            <CustomRadioGroup
              idPrefix={"attestedwithoutpa"}
              name={"attestedwithoutpa"}
              onChange={(e, value) => {
                handleOnChange(e, "attestedwithoutpa", value);
              }}
              onBlur={(e) => {
                handleFilteration();
              }}
              options={[
                { id: "Yes", value: "Yes" },
                { id: "No", value: "No" },
                { id: "Any", value: "Any" },
              ]}
              default={"Any"}
            />
          </Grid>
        </Grid>
        {/* <Grid container>
          <Grid item sm={12}>
            <br></br>
          </Grid>
        </Grid>

        <Grid container></Grid> */}
      </Grid>
    </Grid>
  );
};

const FormHeader = ({ title }) => {
  return (
    <Grid container>
      <Grid item sm={12}>
        <Box className={"attestations_creteria_header"}>{title}</Box>
      </Grid>
    </Grid>
  );
};

const FilterBy = ({
  handleFilteration,
  tripSortByList,
  transportTypeList,
  countyList,
  values,
}) => {
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    console.log(filters);
  }, [filters]);

  const resetSearchForm = () => {};

  return (
    <Grid container>
      <Grid item sm={12}>
        <Box className={"attestations_creteria_container"}>
          <Grid container>
            <Grid item sm={12}>
              <FormHeader title="Filter By" />
            </Grid>
            <Grid item sm={12}>
              <FilterFormBody
                setFilters={setFilters}
                tripSortByList={tripSortByList}
                transportTypeList={transportTypeList}
                countyList={countyList}
                // resetForm={resetForm}
              />
            </Grid>
            <Grid item sm={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  textAlign: "end",
                  paddingTop:"10px",
                  paddingBottom:"10px"
                }}
              >
                <Button
                  variant="contained"
                  onClick={(e) => {
                    console.log("Filters : ", filters);
                    handleFilteration(filters);
                  }}
                >
                  Filter
                </Button>
                <Button variant="outlined" onClick={resetSearchForm}>
                  Clear
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FilterBy;
