import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import moment from "moment";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import "react-toastify/dist/ReactToastify.css";
import Checkbox from "@mui/material/Checkbox";

import { Autocomplete, Hidden, MenuItem, NativeSelect, Select, TextField } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import PersonIcon from "@mui/icons-material/Person";

import axios from "axios";
import { BASE_URL } from "../../../config";
import { ScheduleSendOutlined } from "@mui/icons-material";

function ScheduleEditorLeg({
  row,
  setState,
  cars,
  drivers,
  schedules,
  updateTripSchedule,
  tripSchadule,
  tl,
  scheduleKey,
  user,
}) {
  const [schedule, setSchedule] = useState(tripSchadule);

  useEffect(() => {
    if (tripSchadule) {
      setSchedule(tripSchadule);
    }
  }, [tripSchadule]);

  const headers = {
    "Content-Type": "application/json",
    "x-auth-key": user.key,
  };

  async function handleUpdate() {
    let _schedule = schedule;
    _schedule.updated = true;
    if (
      _schedule.scheduledcar &&
      (_schedule.self ? true : _schedule.selecteddriver) &&
      _schedule.updated
    ) {
      let response = await axios.post(
        BASE_URL + "/scheduler/updatetripSchedule",
        _schedule,
        {
          headers: headers,
        }
      );
      let sh = JSON.parse(JSON.stringify(response.data));
      setSchedule({
        ...schedule,
        ["updated"]: false,
        ["saved"]: true,
      });
    }
  }
  
  function allocateVehicle(driverId){
    console.log(driverId)
    console.log(schedule)
  }
  
  return (
    <>
      {" "}
      <TableRow sx={{ width: "100%" }}>
        <TableCell
          // sx={{ width: "95px" }}
          colSpan={1}
          variant="body"
          align="right"
          className="trip-results-data-cell-fone grid-header"
          style={{
            borderLeft: "1px solid #dee2e6",
            borderRight: "1px solid #dee2e6",
            paddingLeft: "3px",
            paddingRight: "3px",
          }}
        >
          {moment(tl.pickupDate).format("MM/DD/YYYY")} <br />{" "}
          {moment(tl.pickupTime, "HH:mm").format("hh:mm A")}
        </TableCell>

        <TableCell
          colSpan={3}
          align="right"
          className="trip-results-data-cell-fone grid-header"
          style={{ borderLeft: "1px solid #dee2e6" }}
        >
          <div style={{ paddingBottom: 10, paddingTop: 10 }}>
            <div style={{ paddingLeft: "3px" }}>
              <span className="trip-results-data-cell-highlight">Pickup: </span>
              {tl.pickup.address}, {tl.pickup.city}, {tl.pickup.zip}
            </div>
            <div style={{ paddingLeft: "3px" }}>
              <span className="trip-results-data-cell-highlight">Dropoff:</span>
              {tl.dropoff.address}, {tl.dropoff.city},{tl.dropoff.zip}
            </div>
          </div>
        </TableCell>
        <TableCell
          align="right"
          className="trip-results-data-cell-fone grid-header"
          style={{
            borderLeft: "1px solid #dee2e6",
            borderRight: "1px solid #dee2e6",
            paddingLeft: "3px",
            paddingRight: "3px",
          }}
        >
          {user.userlevel == 1 ? (
            <>
              <div>$ {parseFloat(tl.legCost).toFixed(2)}</div>
              <hr style={{ borderTop: "1px solid rgba(0,0,0,.1)" }} />
            </>
          ) : null}
          <div>{parseFloat(tl.legMileage).toFixed(2)} Ml.</div>
        </TableCell>
        <TableCell
          colSpan={4}
          // sx={{  }}
          className="trip-results-data-cell-fone grid-header"
        >
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ width: "50%" }}>
              {(
                schedule
                  ? drivers.filter((d) => {
                      return (d.id == schedule.self) == 1;
                    })[0]
                  : false
              ) ? (
                " - Self - "
              ) : (
                <Box style={{width:"100%"}}>

                  <NativeSelect type="select"
                    className={'thin-border-select'}
                    name="scheduleDriver"
                    value={
                      schedule
                        ? schedule.selecteddriver: null
                    }
                    onChange={(event) => {
                      let value = event.target.value
                      console.log("Value ->",value)
                      setSchedule({
                        ...schedule,
                        ["selecteddriver"]: value,
                        ["updated"]: true,
                      });

                      //this.setSearchFormAutoComplete("selecteddriver", value);
                    }}
                    onBlur={(event) => {
                      let value = event.target.value
                      allocateVehicle(value)}}
                  >

                    <option value="All">Driver</option>
                    {drivers != null &&
                      drivers.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.firstName} -  {(option.licencse ? option.licencse : "")} 
                        </option>
                      ))}
                  </NativeSelect>
                  {/* <Autocomplete
                    xs={{
                      width: 100,
                    }}
                    freeSolo
                    className="grid-inout-wrapper"
                    id="scheduleDriver"
                    options={drivers}
                    autoHighlight
                    autoComplete="off"
                    getOptionLabel={(option) =>
                      option.firstName +
                      " " +
                      (option.licencse ? option.licencse : "")
                    }
                    value={
                      schedule
                        ? drivers.filter((d) => {
                            return d.id == schedule.selecteddriver;
                          })[0]
                        : null
                    }
                    onChange={(event, value) => {
                      setSchedule({
                        ...schedule,
                        ["selecteddriver"]: value.id,
                        ["updated"]: true,
                      });

                      //this.setSearchFormAutoComplete("selecteddriver", value);
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.firstName +
                          " " +
                          (option.licencse ? option.licencse : "")}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        placeholder=" Driver"
                        autoComplete="off"
                        sx={{
                          "& label": {
                            fontSize: "14px",
                            marginTop: "-5px",
                            background: "rgba(0,0,0,0)",
                            width: 100,
                          },
                        }}
                        className={
                          schedule &&
                          schedule.updated &&
                          !(schedule
                            ? drivers.filter((d) => {
                                return d.id == schedule.selecteddriver;
                              })[0]
                            : null)
                            ? "grid-inout-wrapper search-input-box schedule-update-cell-error"
                            : "grid-inout-wrapper search-input-box"
                        }
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                          autoComplete: "off",
                        }}
                      />
                    )}
                  /> */}
                </Box>
              )}
            </div>
            <div style={{ width: "50%" }}>
                <NativeSelect type="select"
                  className={'thin-border-select'}
                  name="scheduleCar"
                  value={
                    schedule
                      ? schedule.scheduledcar: null
                  }
                  onChange={(event) => {
                    let value = event.target.value
                    console.log("Value ->",value)
                    setSchedule({
                      ...schedule,
                      ["scheduledcar"]: value,
                      ["updated"]: true,
                    });

                    //this.setSearchFormAutoComplete("selecteddriver", value);
                  }}
                  onBlur={(event) => {
                    let value = event.target.value
                    allocateVehicle(value)}}
                >

                  <option value="All">Vehicle</option>
                  {cars != null &&
                    cars.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.license} -  {(option.vehiclename ? option.vehiclename : "")} 
                      </option>
                    ))}
                </NativeSelect>

              {/* <Autocomplete
                xs={{
                  width: "100px",
                }}
                freeSolo
                className="grid-inout-wrapper"
                id="scheduleCar"
                autoComplete="off"
                options={cars}
                autoHighlight
                getOptionLabel={(option) =>
                  option.license + " " + option.vehiclename
                }
                value={
                  schedule
                    ? cars.filter((d) => {
                        return d.id == schedule.scheduledcar;
                      })[0]
                    : null
                }
                onChange={(event, value) => {
                  setSchedule({
                    ...schedule,
                    ["scheduledcar"]: value.id,
                    ["updated"]: true,
                  });
                  // this.setSearchFormAutoComplete("Scheduledcar", value);
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.license + " " + option.vehiclename}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    placeholder="Car"
                    autoComplete="off"
                    sx={{
                      "& label": {
                        fontSize: "14px",
                        marginTop: "-5px",
                        background: "rgba(0,0,0,0)",
                      },
                    }}
                    className={
                      schedule &&
                      schedule.updated &&
                      !(schedule
                        ? cars.filter((d) => {
                            return d.id == schedule.scheduledcar;
                          })[0]
                        : null)
                        ? "grid-inout-wrapper search-input-box schedule-update-cell-error"
                        : "grid-inout-wrapper search-input-box"
                    }
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                      autoComplete: "off",
                    }}
                  />
                )}
              /> */}
            </div>
          </div>
        </TableCell>
        <TableCell
          colSpan={1}
          className="trip-results-data-cell-fone grid-header"
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            sx={{
              width: "30px",
              height: "30px",
              color: "white",
              backgroundColor: (
                schedule
                  ? drivers.filter((d) => {
                      return (d.id == schedule.self) == 1;
                    })[0]
                  : false
              )
                ? "#19d28b"
                : "rgba(224, 224, 224, 1)",
            }}
            onClick={() => {
              setSchedule({
                ...schedule,
                ["self"]: !schedule.self,
                ["selecteddriver"]: schedule.self ? "" : null,
                ["updated"]: true,
              });
            }}
          >
            <PersonIcon />
          </IconButton>
          <Button
            className={
              schedule
                ? schedule.updated
                  ? "schedule-submit-pending"
                  : schedule.saved
                  ? "schedule-saved"
                  : "schedule-submit-pending"
                : "schedule-no-record"
            }
            variant="contained"
            size="small"
            onClick={(e) => {
              handleUpdate();
            }}
          >
            {schedule
              ? schedule.saved
                ? schedule.updated
                  ? "Updating"
                  : "Updated"
                : schedule.updated
                ? "Updating"
                : "Update"
              : "Update"}
          </Button>{" "}
        </TableCell>
      </TableRow>
    </>
  );
}
export default ScheduleEditorLeg;
