const axios  = require('axios');
const config = require("../config")

const BASE_URL = `${config.BASE_URL}/reports`;


const getHeaders = () => {
    let _user = { active: false, name: "" };
    if (localStorage.getItem("session")) {
      _user = JSON.parse(localStorage.getItem("session"));
    }
    const headers = {
      "Content-Type": "application/json",
      "x-auth-key": _user.key,
    };
  
    return headers;
  };
async function getTPASummary(driverId, tripDate, invoiceNumber) {
    return new Promise(async (resolve, reject) =>{
        let refData = null;
        try {
            
            const response = await axios.get(`${BASE_URL}/tps/summary?driverId=${driverId}&tripDate=${tripDate}&invoiceNumber=${invoiceNumber}`, {
                headers: getHeaders()
            });
            resolve(response)   
        } catch(error) {
            console.error(error)
            reject(error);
        }
    })
  }

  async function getTPADetails(invoiceNumber, tripLegId, tripDate, driverId) {
    return new Promise(async (resolve, reject) =>{
        let refData = null;
        try {
            // console.log(`${BASE_URL}/tps/details?driverId=${driverId}&tripDate=${tripDate}&invoiceNumber=${invoiceNumber}&tripLegId=${tripLegId}`)
            const response = await axios.get(`${BASE_URL}/tps/details?driverId=${driverId}&tripDate=${tripDate}&invoiceNumber=${invoiceNumber}&tripLegId=${tripLegId}`, {
                headers: getHeaders()
            });
            resolve(response)   
        } catch(error) {
            console.error(error)
            reject(error);
        }
    })
  }

  export {getHeaders, getTPASummary, getTPADetails}