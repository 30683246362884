const reportWebVitals = onPerfEntry => {
  window.addEventListener('load', (event) => {
     
    try{
      setTimeout(()=>{
        window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
   // alert( window.location.href)
      },3500)
      
    }catch(e){
      console.log(e);
    }
      });


  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
