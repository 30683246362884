
import { FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";


const StyledFormControl = styled(FormControl)(({theme}) =>({
    backgroundColor:theme.palette.background.form,
    padding: theme.spacing(1),
    width:"100%",
  }));


  export default StyledFormControl;