import React, { useEffect, useState } from "react";

import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

// Helpers
import "fontsource-roboto";
import { BASE_URL } from "../../../config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

// components
import { GridHeader } from "./gridHeader";
import { ShceduleGroupsTable } from "./shceduleGroupsTable";
import { scheduleGroups } from "../scheduleGrouping/ScheduleGroups";

import "./scheduleGroups.css";
import "react-toastify/dist/ReactToastify.css";

const initialValues = {
  drivers: [],
  groups: [],
  selectedDriver: null,
  groupdate: null,
  updated: false,
  addresses: [],
  loggedUser: null,
};

const ScheduleGrouping = () => {
  const [values, setValues] = useState(initialValues);

  const [scheduleGroupsInstance, setScheduleGroupInstance] =
    useState(scheduleGroups);

  const [notify, seNotify] = useState(null);

  // Register business objects
  useEffect(() => {
    scheduleGroups.registerSchduleGroupes();
    loadReferenceData();
  }, []);

  useEffect(() => {
    scheduleGroups.registerNotifyAll(seNotify);
  }, [notify]);

  useEffect(() => {
    fetchGroups();
  }, [scheduleGroupsInstance.selectedDriver, scheduleGroupsInstance.groupdate]);

  function setStateVars(name, val, callBack) {
    console.log(name, val);
    setValues({
      ...values,
      [name]: val,
    });
  }

  async function fetchGroups() {
    if (
      scheduleGroupsInstance.selectedDriver != null &&
      scheduleGroupsInstance.groupdate != null
    ) {
      await scheduleGroupsInstance.fetchGroups();
    }
  }

  async function loadReferenceData() {
    try {
      await scheduleGroupsInstance.loadRefdata();
      scheduleGroupsInstance.notifyAll({
        drivers: scheduleGroupsInstance.drivers,
      });
    } catch (err) {
      toast.error(
        "Error fetching metadata , Please check your internet connection  ",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  }

  return (
    <>
      <ToastContainer />
      <Grid container spacing={3}>
        {/* Chart */}

        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h2>
              {" "}
              <AssignmentIndIcon /> Schedule Grouping{" "}
            </h2>

            <Box
              component="form"
              // onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <Grid container>
                <Grid item md={12} sm={12}>
                  <GridHeader />
                </Grid>
                {/* The original code removed as that have been moved to  GridHeader components*/}
              </Grid>
              <Grid item md={12} className="groups-container">
                {scheduleGroupsInstance.groups.length > 0 ? (
                  <ShceduleGroupsTable />
                ) : null}
                {/* The original code removed as that have been moved to  ShceduleGroupsTable components*/}
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}></Grid>
        <Grid item xs={12} md={6} lg={6}></Grid>
        {/* Recent Deposits */}

        {/* Recent Orders */}
      </Grid>
    </>
    // </ScheduleGroupContext.Provider>
  );
};

export default ScheduleGrouping;
