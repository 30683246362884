import { styled } from "@mui/material/styles";


const StyledIconWrapper = styled('div')(({theme}) =>({
    color: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  }));


  export default StyledIconWrapper;