import React, { useEffect, useState } from 'react';
import {  Button, 
          Dialog, 
          DialogActions, 
          DialogContent, 
          DialogTitle, 
          Grid, 
          Table,
          TableBody,
          TableCell,
          TableContainer,
          TableHead,
          TableRow} from '@mui/material';

function AttestInvoiceStatusDialog ({
    showAttestionStatus,
    setShowAttestationStatus,
    attestationResponse,
    setAttestationEnvironment,
    setAttest,
    setSendBreadcrumbs,
    setAttestationData,
    attestationData,
    setReload,
}) {
    const [currentAttestationData, setCurrentAttestationData] = useState(attestationData);
    const [responseData, setResponsetData] = useState({});

    useEffect(()=>{
      function updateResponseData(){
        let _responseData = responseData.map((inv)=>{
          if(inv.invoice.invoiceNumber == attestationResponse.response[0].invoice.invoiceNumber &&
          inv.invoice.triplegId == attestationResponse.response[0].invoice.triplegId)
          {
            inv.updateState = attestationResponse.response[0].updateState
            inv.breadcrumbs = attestationResponse.response[0].breadcrumbs
          }
          return inv;
        })
        setResponsetData(_responseData)
      }
      if(responseData.length > 0)
        updateResponseData();
      else 
        setResponsetData(attestationResponse.response)
    },[`${attestationResponse.timtDelta}`])

    function handleCloseDialog(){
      setAttestationData({})
      setAttestationEnvironment('Prod');  // I will remove this once testing is completed and we are confident.
      setReload(true);
      setShowAttestationStatus(false);
    } 

    function handleProductionAttest(invoiceNumber, tripLegId) {
      let atd = currentAttestationData.invoiceNumbers.filter((_atd) => {
        return(_atd.invoiceNumber == invoiceNumber && _atd.triplegId == tripLegId)
      })

      setAttestationData({
        ...attestationData,
        invoiceNumbers:atd
      })
      setAttestationEnvironment('Prod');
      setAttest(true);
    }

    function handleSendBreadcrumbs(invoiceNumber, tripLegId) {
      let atd = currentAttestationData.invoiceNumbers.filter((_atd) => {
        return(_atd.invoiceNumber == invoiceNumber && _atd.triplegId == tripLegId)
      })

      setAttestationData({
        ...attestationData,
        invoiceNumbers:atd
      })
      setSendBreadcrumbs(true);
    }

    return (
        <Dialog
              open={showAttestionStatus}
              onClose={() => handleCloseDialog(null)}
              maxWidth="md"
              fullWidth={false}
            >
              <DialogTitle> Attestation Status </DialogTitle>
              <DialogContent>
                <Grid container>
                  <Grid item>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell aliigh="center">Invoice Number</TableCell>
                                    <TableCell aliigh="center">Trip Leg Id</TableCell>
                                    <TableCell aliigh="center">Test Request</TableCell>
                                    <TableCell aliigh="center">Production Request</TableCell>
                                    <TableCell aliigh="center">Breadcrumbs</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    responseData.length > 0? responseData.map((resp, index) => {
                                        return (
                                                <TableRow key={index}>
                                                    <TableCell aliigh="center">{resp.invoice.invoiceNumber}</TableCell>
                                                    <TableCell aliigh="center">{resp.invoice.triplegId}</TableCell>
                                                    {
                                                      resp.updateState[0]?.environment == 'Testing'? 
                                                        <TableCell aliigh="center">{resp.updateState[0]?.status}</TableCell> 
                                                      : null
                                                    }
                                                    {
                                                        resp.updateState[1]?.environment == 'Production'? 
                                                        <TableCell aliigh="center">
                                                          <div style={{
                                                            display:'flex',
                                                            flexDirection:'row',
                                                            alignContent:'space-between',
                                                            alignItems:'center',
                                                            
                                                          }}>
                                                            <div style={{
                                                              paddingLeft:3,
                                                              paddingRight:3,
                                                            }}>
                                                              {resp.updateState[1]?.status}
                                                            </div>
                                                            {/* <div style={{
                                                              paddingLeft:3,
                                                              paddingRight:3,
                                                              textDecoration: 'underline',
                                                            }}>
                                                              <Button  onClick={(e)=>{handleProductionAttest(resp.invoice.invoiceNumber, resp.invoice.triplegId)}}>{attestationData.type} </Button>
                                                            </div> */}
                                                          </div>
                                                        </TableCell> 
                                                      : <TableCell aliigh="center">
                                                          <div style={{
                                                              paddingLeft:3,
                                                              paddingRight:3,
                                                              textDecoration: 'underline',
                                                            }}>
                                                            <Button  onClick={(e)=>{handleProductionAttest(resp.invoice.invoiceNumber, resp.invoice.triplegId)}}>{attestationData.type} </Button>
                                                          </div>
                                                        </TableCell> 
                                                    }
                                                    <TableCell aliigh="center">
                                                      <div style={{
                                                            display:'flex',
                                                            flexDirection:'row',
                                                            alignContent:'space-between',
                                                            alignItems:'center',
                                                            
                                                          }}>
                                                            <div style={{
                                                              paddingLeft:3,
                                                              paddingRight:3,
                                                            }}>
                                                              {resp.updateState[1].breadcrumbs?resp.updateState[1].breadcrumbs.status:"N/A"}
                                                            </div>
                                                            {/* { attestationData.type == "Invoice" || attestationData.type == "NoShow" ? 
                                                                <div style={{
                                                                  paddingLeft:3,
                                                                  paddingRight:3,
                                                                  textDecoration: 'underline',
                                                                }}>
                                                                  <Button  onClick={(e)=>{handleSendBreadcrumbs(resp.invoice.invoiceNumber, resp.invoice.triplegId)}}>Send </Button>
      
                                                                </div>
                                                              : null
                                                            } */}
                                                      </div>
                                                    </TableCell>
                                                </TableRow>
                                        )
                                    }):null
                                }
                            </TableBody>    
                        </Table>
                    </TableContainer>
                  </Grid>
                </Grid> 
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleCloseDialog()} variant="contained">Close</Button>
              </DialogActions>
            </Dialog>
      )
}

export default AttestInvoiceStatusDialog;