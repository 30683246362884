import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";


const StyledMenuItem = styled(MenuItem)(({theme}) =>({
    borderColor: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    width:"100%",
    padding: theme.spacing(1),
  }));


  export default StyledMenuItem;