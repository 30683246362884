import { styled } from "@mui/material/styles";


const StyledWrapper = styled('div')(({theme}) =>({
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  }));


  export default StyledWrapper;