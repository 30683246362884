import { styled } from "@mui/material/styles";


const StyledHeader = styled('div')(({theme}) =>({
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    ...theme.typography.h4,
    color:theme.palette.header.default,
  }));


  export default StyledHeader;