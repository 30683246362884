import React from 'react';
import {  Box, 
          Button, 
          Dialog, 
          DialogActions, 
          DialogContent, 
          DialogTitle, 
          Grid, 
          NativeSelect } from '@mui/material';

function  ResolveConfirmDialog ({showUpateConfimation, handleCloseConfimation, masDrivers, selectedDriver, setSelectedDriver}) {
    return (
      <Dialog
            open={showUpateConfimation}
            onClose={() => handleCloseConfimation(null)}
            maxWidth="sm"
            fullWidth={false}
          >
            <DialogTitle> Resolve Trips </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item md={8}>
                    Please select if how you wish to resolve ?
                </Grid>    
                <Grid item md={2}>
                  <Box
                    noValidate
                    component="form"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      m: 'auto',
                      width: 'fit-content',
                      textAlign:'end',
                    }}>
                      <NativeSelect type="select"
                          className={'thin-border-select'}
                          name="selectDriver"
                          value={
                            selectedDriver
                              ? selectedDriver: "All"
                          }
                          onChange={(event) => {
                            let value = event.target.value
                            setSelectedDriver(value);
                          }}
                        >
                        <option value="All">All</option>
                          {masDrivers != null &&
                            masDrivers.map((option, index) => (
                              <option key={index} value={option.driverId}>
                                {option.firstname} {option.lastname} 
                              </option>
                            ))}
                      </NativeSelect>
                  </Box>
                </Grid>
              </Grid> 
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseConfimation(selectedDriver)} variant="contained">Ok</Button>
              <Button onClick={() => handleCloseConfimation(null)} variant="outlined">Cancel</Button>
            </DialogActions>
          </Dialog>
    )
  }

  export default ResolveConfirmDialog;