
import { FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";

const FileUpload = ({multiple, labeltext, uploadFunction, id}) =>{
    return (
        <div>
        <input type="file" multiple={multiple} id={`file-input_${id}`} name={`file-input_${id}`} style={{display:"none"}}
            onChange={(e)=>uploadFunction(e,id)}/>
            <StyledFileInputLabel  id="file-input-label" for={`file-input_${id}`}>
              {labeltext}
            </StyledFileInputLabel>
        </div>
    )
}

const StyledFileInputLabel = styled("label")(({theme}) =>({
    cursor:"pointer",
    borderBottom:"1px solid black",
    color:"blue",
  }));

const StyledFileupload = styled(FileUpload)(({theme, multiple}) =>({
    backgroundColor:theme.palette.background.form,
    padding: theme.spacing(1),
    width:"100%",
  }));


  export default StyledFileupload;