import React from "react";

import Grid from "@mui/material/Grid";

import "react-toastify/dist/ReactToastify.css";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import UserRights from "../userRights";

function ScheduleTableHead({ user }) {
  return (
    <>
      <TableHead>
        <TableRow
          sx={{
            display: {
              xl: "table-row",
              md: "table-row",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <TableCell className="trip-results-head-cell-fone grid-header">
            {/* <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                title="InvoiceNumber ascending"
                onClick={() => {
                  this.setSearchOrder("invoiceNumber", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                title="InvoiceNumber descending"
                onClick={() => {
                  this.setSearchOrder("invoiceNumber", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div> */}
            <div class="sortable-title11">
              Invoice
              <hr
                style={{
                  borderTop: "1px solid rgba(0,0,0,.1)",
                  padding: "0px",
                }}
              />
              PA#
              <hr
                style={{
                  borderTop: "1px solid rgba(0,0,0,.1)",
                  padding: "0px",
                }}
              />
              Expiery
            </div>
          </TableCell>

          <TableCell className="trip-results-head-cell-fone grid-header ">
            <div class="sortable-title">SO/MO</div>{" "}
          </TableCell>

          <TableCell className="trip-results-head-cell-fone grid-header">
            <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                onClick={() => {
                  this.setSearchOrder("status", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                onClick={() => {
                  this.setSearchOrder("status", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div>
            <div class="sortable-title">Status </div>{" "}
          </TableCell>
          <TableCell className="trip-results-head-cell-fone grid-header">
            <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                onClick={() => {
                  this.setSearchOrder("medicaidNumber", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                onClick={() => {
                  this.setSearchOrder("medicaidNumber", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div>
            <div class="sortable-title">CIN # </div>{" "}
          </TableCell>
          <TableCell className="trip-results-head-cell-fone grid-header">
            <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                onClick={() => {
                  this.setSearchOrder("firstName", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                onClick={() => {
                  this.setSearchOrder("firstName", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div>
            <div class="sortable-title">Recipient</div>{" "}
          </TableCell>
          {user.userlevel == 1 ? (
            <TableCell className="trip-results-head-cell-fone grid-header">
              <div class="sort-wrapper">
                <div
                  class="sort-wrapper-up"
                  onClick={() => {
                    this.setSearchOrder("totalTripCost", "ASC");
                  }}
                >
                  <ArrowDropUpIcon />{" "}
                </div>
                <div
                  class="sort-wrapper-down"
                  onClick={() => {
                    this.setSearchOrder("totalTripCost", "DESC");
                  }}
                >
                  {" "}
                  <ArrowDropDownIcon />{" "}
                </div>
              </div>
              <div class="sortable-title">Trip Cost</div>{" "}
            </TableCell>
          ) : null}

          <TableCell className="trip-results-head-cell-fone grid-header">
            <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                onClick={() => {
                  this.setSearchOrder("calculatedTripMileage", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                onClick={() => {
                  this.setSearchOrder("calculatedTripMileage", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div>
            <div class="sortable-title">Milage</div>{" "}
          </TableCell>

          <TableCell className="trip-results-head-cell-fone grid-header">
            <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                onClick={() => {
                  this.setSearchOrder("pickupDate", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                onClick={() => {
                  this.setSearchOrder("pickupDate", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div>
            <div class="sortable-title">Trip Date </div>{" "}
          </TableCell>
          {/* 
          <TableCell className="trip-results-head-cell-fone grid-header">
            Trip Legs
          </TableCell> */}
          <TableCell className="trip-results-head-cell-fone grid-header">
            County
          </TableCell>
          <TableCell className="trip-results-head-cell-fone grid-header">
            <div class="sort-wrapper">
              <div
                class="sort-wrapper-up"
                onClick={() => {
                  this.setSearchOrder("originalCreateddate", "ASC");
                }}
              >
                <ArrowDropUpIcon />{" "}
              </div>
              <div
                class="sort-wrapper-down"
                onClick={() => {
                  this.setSearchOrder("originalCreateddate", "DESC");
                }}
              >
                {" "}
                <ArrowDropDownIcon />{" "}
              </div>
            </div>
            <div class="sortable-title">Created Date</div>
          </TableCell>

          {/*
																	<TableCell className="trip-results-head-cell-fone grid-header">
																		Action{" "}
																	</TableCell> */}
        </TableRow>

        <TableRow
          sx={{
            display: {
              xl: "none",
              md: "none",
              sm: "table-row",
              xs: "table-row",
            },
          }}
        >
          <TableCell className="grid-header-mobile-wrapper" colSpan={13}>
            <Grid container>
              <Grid
                item
                xs={4}
                sm={3}
                className="trip-results-head-cell-fone grid-header"
              >
                <div class="sortable-title-mobile">Invoice PA#</div>
              </Grid>
              <Grid
                item
                xs={4}
                sm={3}
                className="trip-results-head-cell-fone grid-header"
              >
                <div class="sortable-title-mobile">SO/MO </div>{" "}
              </Grid>
              <Grid
                item
                xs={4}
                sm={3}
                className="trip-results-head-cell-fone grid-header"
              >
                <div class="sortable-title-mobile">Status </div>{" "}
              </Grid>
              <Grid
                item
                xs={4}
                sm={3}
                className="trip-results-head-cell-fone grid-header"
              >
                <div class="sortable-title-mobile">CIN Medicaid # </div>{" "}
              </Grid>
              <Grid
                item
                xs={4}
                sm={3}
                className="trip-results-head-cell-fone grid-header"
              >
                <div class="sortable-title-mobile">Recipient</div>{" "}
              </Grid>

              <Grid
                item
                xs={4}
                sm={3}
                className="trip-results-head-cell-fone grid-header"
              >
                <div class="sortable-title-mobile">Trip Cost</div>{" "}
              </Grid>

              <Grid
                item
                xs={4}
                sm={3}
                className="trip-results-head-cell-fone grid-header"
              >
                <div class="sortable-title-mobile">Calc Milage</div>{" "}
              </Grid>

              <Grid
                item
                xs={4}
                sm={3}
                className="trip-results-head-cell-fone grid-header"
              >
                <div class="sortable-title-mobile">Transport Type</div>{" "}
              </Grid>

              <Grid
                item
                xs={4}
                sm={3}
                className="trip-results-head-cell-fone grid-header"
              >
                <div class="sortable-title-mobile">Trip Date / Mode</div>{" "}
              </Grid>

              {/* <Grid
                item
                xs={4}
                sm={3}
                className="trip-results-head-cell-fone grid-header sortable-title-mobile"
              >
                Trip Legs
              </Grid> */}
              <Grid
                item
                xs={4}
                sm={3}
                className="trip-results-head-cell-fone grid-header sortable-title-mobile"
              >
                County
              </Grid>
              <Grid
                item
                xs={4}
                sm={3}
                className="trip-results-head-cell-fone grid-header sortable-title-mobile"
              >
                Created date
              </Grid>

              {/*
																			<Grid item xs={4} sm={3} className="trip-results-head-cell-fone grid-header sortable-title-mobile">
																				Action{" "}
																			</Grid>  */}
            </Grid>
          </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
}

export default ScheduleTableHead;
