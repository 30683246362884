import React, { Component } from "react"; 

import { BASE_URL } from "../../config";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import moment from "moment";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Orders from "../Orders";
import CustomRadioGroup from "../components/CustomRadioGroup";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Autocomplete, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class UpdateRoster extends Component {
  constructor(props) {
    super(props);
    let url = new URL(window.location.href);

    this.state = {
      searchForm: {
        authentication : "PY72I5JWGBWENV4ZVF01I7VT28RY2UXDOFQUKCNGM3LTMEL61MR9SK820MB40OIR",
        searchFromDate: moment().format("MM/DD/YYYY"),
        searchToDate: moment().format("MM/DD/YYYY"),
      },
    };
    this.setSearchFormCustom = this.setSearchFormCustom.bind(this);
    this.setSearchForm = this.setSearchForm.bind(this);
    this.setSearchFormDate = this.setSearchFormDate.bind(this);
  }
  componentDidMount() {
    let user = { active: false, name: "" };
    if (localStorage.getItem("session")) {
      user = JSON.parse(localStorage.getItem("session"));
    }
    this.setState({ user: user });
  }

  setSearchFormCustom(id, value) {
    let searchForm = this.state.searchForm;
    searchForm[id] = value;

    this.setState({ searchForm: searchForm });
  }
  setSearchForm(event) {
    let id = event.target.id;
    let searchForm = this.state.searchForm;
    searchForm[id] = event.target.value;

    this.setState({ searchForm: searchForm });
  }
  setSearchFormDate(id, value) {
    let searchForm = this.state.searchForm;
    searchForm[id] = value;

    this.setState({ searchForm: searchForm });
  }
  async fetchRoster(){
     
    
    // send the username and password to the server
    try{


        const headers = {
            'Content-Type': 'application/json',
            'x-auth-key': this.state.user.key
          }


      const response = await axios.post(
        BASE_URL + "/Scheduler/fetchall",
        this.state.searchForm ,{
            headers: headers
          }
      );
      // set the state of the user
      //etUser(response.data)
      // store the user in localStorage
       
      console.log(response.data)
       
      toast.success('Welcome ', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });


    }catch(error){ 
      console.log("error Print"); 
        

      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    }
  }
  render() {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h2> PullRoster </h2>
              <Grid container spacing={2} padding={1}>
                <Grid
                  item
                  xs={2}
                  display="flex"
                  textAlign={"right"}
                  justify="flex-end"
                >
                  <div className="grid-caption"> Authentication" : </div>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    className="grid-inout-wrapper"
                    id="authentication"
                    label="Authentication"
                    variant="outlined"
                    size="small"
                    value={this.state.searchForm.authentication}
                    onChange={this.setSearchForm}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} padding={1}>
                <Grid
                  item
                  xs={2}
                  display="flex"
                  textAlign={"right"}
                  justify="flex-end"
                >
                  <div className="grid-caption"> Date Of Birth: </div>
                </Grid>
                <Grid item xs={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Date Of Birth"
                      id="searchFromDate"
                      format="MM/DD/YYYY"
                      value={this.state.searchForm.searchFromDate}
                      onChange={(newValue) => {
                        console.log(newValue);

                        this.setSearchFormDate(
                          "searchFromDate",
                          moment(newValue).format("MM/DD/YYYY")
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={this.state.searchForm.searchFromDate}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={1}>
                  to
                </Grid>
                <Grid item xs={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Date Of Birth"
                      id="searchToDate"
                      format="MM/DD/YYYY"
                      value={this.state.searchForm.searchToDate}
                      minDate={moment(
                        this.state.searchForm.searchFromDate
                      ).toDate()}
                      onChange={(newValue) => {
                        this.setSearchFormDate(
                          "searchToDate",
                          moment(newValue).format("MM/DD/YYYY")
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={this.state.searchForm.searchToDate}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Grid container spacing={2} padding={1}>
                <Grid item xs={2} display="flex"></Grid>
                <Grid item xs={10}>
                  <Button variant="contained" onClick={()=>{this.fetchRoster()}}>Search</Button>
                  <Button variant="contained">Reset Form</Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default UpdateRoster;
