import { BASE_URL } from "../../../config";
import axios from "axios";

class ScheduleGroups {
  constructor() {
    this.drivers = [];
    this.groups = [];
    this.selectedDriver = null;
    this.groupdate = null;
    this.updated = false;
    this.addresses = {};
    this.loggedUser = null;
    this.notifyAll = null;
  }

  reset() {
    this.drivers = [];
    this.groups = [];
    this.selectedDriver = null;
    this.groupdate = null;
    this.updated = false;
    this.addresses = {};
    this.loggedUser = null;
    this.notifyAll = null;
  }
  registerSchduleGroupes = () => {
    this.reset();
    this.setUser();
  };

  registerNotifyAll = (notifyAll) => {
    this.notifyAll = notifyAll;
  };

  setProps = (name, val) => {
    this[name] = val;
    this.notifyAll({ name: val });
  };

  setUser = () => {
    let user = { active: false, name: "" };
    if (localStorage.getItem("session")) {
      user = JSON.parse(localStorage.getItem("session"));
    }
    this.loggedUser = user;
  };

  setGroupDate = (groupDate) => {
    this.groupdate = groupDate;
    this.notifyAll({ groupdate: groupDate });
  };

  setSelectedDriver = (driver) => {
    this.selectedDriver = driver;
    this.notifyAll({ selectedDriver: driver });
  };

  loadRefdata = async () => {
    let api = axios.create({
      baseURL: BASE_URL,
      headers: {
        "x-auth-key": this.loggedUser.key,
      },
    });

    try {
      const metaResp = await api.get("/refdata/all");
      const countryList = await metaResp.data;
      this.drivers = await countryList.drivers.data;
    } catch (err) {
      throw new Error(err.message);
    }
  };

  fetchGroups = async () => {
    let api = axios.create({
      baseURL: BASE_URL,
      headers: {
        "x-auth-key": this.loggedUser.key,
      },
    });

    if (this.selectedDriver != null && this.groupdate != null) {
      const groupResp = await api.post("/scheduler/fetchgroups", {
        date: this.groupdate,
        driver: this.selectedDriver,
      });
      let grs = groupResp.data.groups.map((g) => {
        return {
          ...g,
          nameEdit: false,
          fromEdit: false,
          toEdit: false,
          edited: false,
          display: true,
          arrange: false,
          addressEdit: false,
          timeEdit: false,
        };
      });
      for (let gs of grs) {
        let newSchedules = [];
        for (let sch of gs.schedules) {
          newSchedules.push({
            ...sch,
            moved: false,
            arrange: false,
            addressEdit: false,
            timeEdit: false,
          });
        }
        grs.schedules = newSchedules;
      }
      this.groups = grs;
      this.updated = false;
      this.addresses = groupResp.data.addresses;
      this.notifyAll({
        groups: grs,
        updated: false,
        addresses: groupResp.data.addresses,
      });
    }
  };

  saveGroups = async () => {
    let api = axios.create({
      baseURL: BASE_URL,
      headers: {
        "x-auth-key": this.loggedUser.key,
      },
    });
    if (this.selectedDriver != null && this.groupdate != null) {
      const groupResp = await api.post("/scheduler/updategroupstate", {
        groups: this.groups,
      });
      await this.fetchGroups();
    }
  };

  addGroupSchedule = (groupSchedule, groupId) => {
    const _groups = this.groups;
    let groupWithnewSchedule = _groups.map((g) => {
      if (g.group.id === groupId) {
        g.schedules = [...g.schedules, groupSchedule];
      }
      return g;
    });
    this.groups = groupWithnewSchedule;
    this.notifyAll({ groups: groupWithnewSchedule });
  };

  updateGroupSchedule = (groupSchedule, groupId) => {
    const _groups = this.groups;

    let groupWithnewSchedule = _groups.map((g) => {
      if (g.group.id === groupId) {
        g.schedules = groupSchedule;
      }
      return g;
    });
    this.groups = groupWithnewSchedule;
    this.notifyAll({ groups: groupWithnewSchedule });
  };

  removeGroupSchedule = (scheduleId, groupId) => {
    const _groups = this.groups;
    let groupWithnewSchedule = _groups.map((g) => {
      if (g.group.id === groupId) {
        const newSchdules = g.schedules.filter((s) => {
          if (s.scheduleid != scheduleId) {
            return s;
          }
        });
        g.schedules = newSchdules != null ? newSchdules : [];
      }
      if (g.schedules.length == 0) g.display = false;
      return g;
    });
    this.groups = groupWithnewSchedule;
    this.notifyAll({ groups: groupWithnewSchedule });
  };
}

export const scheduleGroups = new ScheduleGroups();
