import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Paper from "@mui/material/Paper";

const Accordion = styled((props: AccordionProps) => ( 
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': { 
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FaqManagement() {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div>
    <Paper
    sx={{
      p: 2,
      display: "flex",
      flexDirection: "column",
    }}
  >
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Does MAS provide transportation?</Typography>
        </AccordionSummary>
        <AccordionDetails className='faq-content-style'  >
          <Typography>
          No. MAS is contracted with New York State Department of Health (NYSDOH) to provide transportation management services for the NEMT program.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>How do I request a fee change for the rate schedule?</Typography>
        </AccordionSummary>
        <AccordionDetails className='faq-content-style'  >
          <Typography>
          MAS does not have an influence on fee changes. Inquiries regarding the rate schedule should be directed to the NYSDOH.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>How are vendors assigned to trip requests?</Typography>
        </AccordionSummary>
        <AccordionDetails className='faq-content-style' >
          <Typography>
          MAS assigns trips in the following order based on the medically appropriate transport level:
          <ol>
          <li>Enrollee’s preferred provider</li>
          <li>Medical provider’s preferred provider</li>
          <li>“Round robin” rotation</li>

          </ol>
            
            
            
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Paper></div> 
  );
}
