import { useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { toast } from "react-toastify";

// Helpers
import moment from "moment-timezone";

// Business objects
import { scheduleGroups } from "../scheduleGrouping/ScheduleGroups";

export const GridHeader = () => {
  const [selectedDriver, setSelectedDriver] = useState();
  const [groupdate, setGroupDate] = useState();

  const saveMessage = (length, title) => {
    toast.success(`Records saved successfully ! `, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const saveError = (length, title) => {
    toast.error(`Error saving records ! `, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const saveSchedules = async () => {
    try {
      await scheduleGroups.saveGroups();
      saveMessage();
    } catch (err) {
      saveError();
    }
  };
  return (
    <Grid container>
      <Grid item md={6} sm={6}>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <div>
            <FormControl
              sx={{
                m: 1,
                minWidth: "200px",
                border: "1px solid #bedfff",
                borderRadius: "5px",
                marginLeft: "0px",
              }}
              size="small"
            >
              <InputLabel id="driver-label">Drivers</InputLabel>
              <Select
                labelId="driver-label"
                id="driver-label-select"
                value={selectedDriver}
                onChange={(event) => {
                  scheduleGroups.setSelectedDriver(event.target.value);
                  setSelectedDriver(event.target.value);
                }}
                label="Drivers"
              >
                {scheduleGroups.drivers ? (
                  scheduleGroups.drivers.map((option) => {
                    return (
                      <MenuItem value={option}>
                        {option.firstName + " " + option.licencse}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
          <div>
            <div>
              <FormControl
                sx={{
                  m: 1,
                  padding: "6px",
                  minWidth: "200px",
                  border: "1px solid #bedfff",
                  borderRadius: "5px",
                }}
                size="small"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat="MM/DD/YYYY"
                    clearable
                    id="groupdate"
                    value={scheduleGroups.groupdate}
                    onChange={(newValue) => {
                      scheduleGroups.setGroupDate(
                        newValue.format("MM/DD/YYYY")
                      );
                      setGroupDate(newValue.format("MM/DD/YYYY"));
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="search-input-box search-input-box-dob"
                        {...params}
                        value={groupdate}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </div>
          </div>
        </Box>
      </Grid>
      <Grid item md={6}>
        <Box
          style={{
            textAlign: "end",
            justifyContent: "flex-end",
            width: "100%",
          }}
          variant="contained"
          aria-label="outlined primary button group"
        >
          <FormControl
            sx={{
              m: 1,
              minWidth: "100px",
            }}
          ></FormControl>
          {
            <>
              <FormControl
                sx={{
                  m: 1,
                  minWidth: "100px",
                }}
              >
                <Button
                  variant="contained"
                  endIcon={<SaveIcon />}
                  onClick={() => {
                    saveSchedules();
                  }}
                >
                  Save
                </Button>
              </FormControl>
              <FormControl
                sx={{
                  m: 1,
                  minWidth: "100px",
                }}
              >
                <Button
                  variant="contained"
                  endIcon={<RestartAltIcon />}
                  onClick={() => {
                    scheduleGroups.fetchGroups();
                  }}
                >
                  Reset
                </Button>
              </FormControl>
            </>
          }
        </Box>
      </Grid>
    </Grid>
  );
};
