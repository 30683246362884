import React, { useEffect , useMemo} from 'react'

import {
    Box,
    Button,
    ClickAwayListener,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    LinearProgress,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
  } from "@mui/material";


  import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    GRID_CHECKBOX_SELECTION_COL_DEF,
  } from "@mui/x-data-grid";


  import { createStyles, makeStyles } from "@mui/styles";
  import { darken, lighten, styled } from "@mui/material/styles";

  import moment from "moment";
  import { useCallback, useState } from "react";
  import { StyledButton, StyledDatePicker, StyledFormControl, StyledFrame, StyledHeader, StyledInputText } from "../../styled";
  import axios from 'axios';
  
  import {getTPASummary, getTPADetails} from '../../api/ReportsApi'


/* rows GRID STYLES */
const datagridSx = {
    borderRadius: 2,
    "& .MuiDataGrid-main": { borderRadius: 2 },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#f5fafe",
      color: "#033d5f",
      fontWeight: "bold !important",
      fontSize: 14,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold !important",
      color: "#033d5f !important",
      overflow: "visible",
      lineHeight: "1.0rem",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-cell": {
      paddingTop: 1,
      paddingBottom: 1,
    },
  };
  
  const useStyles = makeStyles((theme) => 
    createStyles({
      root: {
        backgroundColor: theme.palette.red,
      },
      highlighted: {
        color: theme.palette.highlight.main,
        fontWeight:"bold"
      },
    }));
  

    const getBackgroundColor = (color, mode) =>
        {
          return (mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7));
        }
      const getHoverBackgroundColor = (color, mode) =>
        mode === "dark" ? darken(color, 0.6) : lighten(color, 0.9);
      
      const getSelectedBackgroundColor = (color, mode) =>
        mode === "dark" ? darken(color, 0.5) : lighten(color, 0.9);
      
      const getSelectedHoverBackgroundColor = (color, mode) =>
        mode === "dark" ? darken(color, 0.4) : lighten(color, 0.9);

    const StyledGrid = styled(DataGrid)(({theme}) =>({
        "& .planner-view-grid--O-0": {
            backgroundColor: getBackgroundColor(
              theme.palette.background.default,
              theme.palette.mode
            ),
            "&:hover": {
              backgroundColor: getHoverBackgroundColor(
                theme.palette.neutral.light,
                theme.palette.mode
              ),
            },
            "&.Mui-selected": {
              backgroundColor: getSelectedBackgroundColor(
                theme.palette.neutral.main,
                theme.palette.mode
              ),
              "&:hover": {
                backgroundColor: getSelectedHoverBackgroundColor(
                  theme.palette.neutral.light,
                  theme.palette.mode
                ),
              },
            },
           
          },
          "& highlighted": {
            color:theme.palette.error
          }
    }))

    /* 
     DETAILS TABLE STYLES
    */

    const StyledTable = styled(Table)(({theme}) =>({
      "& .MuiTableHead-root": {
        backgroundColor: "#f5fafe",
        color: "#033d5f",
        fontWeight: "bold !important",
        fontSize: 14,
      },
      "& th.MuiTableCell-root": {
        backgroundColor: "#f5fafe",
        color: "#033d5f",
        fontWeight: "bold !important",
        fontSize: 14,
        textAlign:'center',
      }
    }))
    function CustomToolbar() {
        return (
          <GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
            <GridToolbarExport
              csvOptions={{
                allColumns: true,
                fileName: `${moment(new Date()).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}_mas_roster`,
              }}
            />
          </GridToolbarContainer>
        );
      }

      
const SearchBy = ({ handleOnSearch }) => {

    const [invoiceNo, setInvoiceNo] = useState(null);
    const [tripDate, setTripDate] = useState(
      moment(new Date()).format("YYYY-MM-DD")
    );
  
    const handleSearch =() => {
      handleOnSearch({
        invoiceNo: invoiceNo,
        tripDate: tripDate,
        reload:true,
      });
    };
  
    const handleInvoiceNoChange = useCallback((e) => {

      setInvoiceNo(e.target.value);
    });
  
    const handleSetTripDate = useCallback((e) => {
      setTripDate(e.target.value);
    });
  
    return (
      <Grid container p={0}>
       
        <Grid item xs={12} md={12} sm={12} p={0}>
          <StyledHeader>
                TPA Connectivity Status
          </StyledHeader>
        </Grid>
  
        <Grid item md={12} xs={12} lg={12} p={0}>
          <br></br>
        </Grid>
        <StyledFrame>
          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <StyledFormControl>
                <Typography  sx={{
                  fontWeight:800
                }}>Invoice Number</Typography>
                <StyledInputText 
                  id="invoiceNumber"
                  name="invoiceNumber"
                  // label="Invoice Number"
                  value={invoiceNo ?? ""}
                  onChange={(e)=>{handleInvoiceNoChange(e)}}>
                </StyledInputText>
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <StyledFormControl>
                <Typography  sx={{
                    fontWeight:800
                  }}>Trip Date</Typography>
                    <StyledDatePicker
                      type="date"
                      id="dateFrom"
                      name="dateFrom"
                      value={tripDate}
                      onChange={(e)=>{handleSetTripDate(e)}}
                    />
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <StyledFormControl 
                  style={{
                    width:"100%",
                  }}>
                  <div style={{
                    marginTop:"16px",
                    width:"100%",
                    textAlign:"left",
                    justifyContent:"center",
                  }}>
                  <StyledButton 
                    variant={"contained"}  
                    onClick={(e)=>{handleSearch()}}
                    theme >
                    Search
                  </StyledButton>
                </div>
              </StyledFormControl>
            </Grid>
          </Grid>
        </StyledFrame>
      </Grid>
    );
  };
  

 function SummaryReport({searchAttrs, setDetails}){
    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(25);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [loading, setLoading] = useState(false);

    const invoiceNo = searchAttrs.invoiceNo == null? '':searchAttrs.invoiceNo;
    const tripDate =  searchAttrs.tripDate?moment(searchAttrs.tripDate, 'YYYY-MM-DD').format('YYYY-MM-DD'):moment().format('YYYY-MM-DD');
    useEffect(()=>{
        const fetchData = async () => {
           setLoading(true) 
           const response = await getTPASummary('',tripDate,invoiceNo)
           if(response.status == 200) {
             let rows = response.data.map((d) =>{
                return {
                    ...d,
                    "id":d.legId
                }
             })
            setRows(rows);
           } else {
            setRows([]);
           }
           setLoading(false);
        }
        
        fetchData()
    },[searchAttrs['tripDate'], searchAttrs['invoiceNo']])

    const handleDetails  = (legId, invoiceNo) =>{
      setDetails(legId,invoiceNo);
    }

    const processRowUpdate = useCallback(
        (newRow) => {
        const index = rows.findIndex((row) => row.id === newRow.id);
        const tempRows = [...rows];
        tempRows[index] = newRow;
        setRows(tempRows);
        return newRow;
        },
        [rows]
    );

  const handleProcessRowUpdateError = useCallback((error) => {
      toast.error(`[Error] ${error.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }, []); 


    const columns = useMemo(
        () => [
        {
            field: "tripDate",
            headerName: "TripDate",
            editable: false,
            flex: 1,
            sortable: false,
        },
        {
            field: "invoiceNumber",
            headerName: "Invoice Number",
            flex: 1,
            editable: false,
            sortable: true,
            
        },

        {
            field: "legId",
            headerName: "Trip Leg id",
            editable: false,
            flex: 1,
            sortable: true,
            
        },
        {
            field: "customer",
            headerName: "Name",
            editable: false,
            flex: 1,
            sortable: false,
        },
        {
            field: "count",
            headerName: "Message Count",
            editable: false,
            flex: 1,
            sortable: true,
            
        },
        {
            headerName: "Actions",
            field: "actions",
            type: "actions",
            align: "center",
            width:200,
            height:100,
            renderCell: (params) => {
            return (
                <div style={{ paddingTop: 4, paddingBottom: 4,  display:"flex", textAlign:"center"}}>
                    <div style={{ marginLeft: 2 }}>
                        <Button variant='contained' onClick={e => handleDetails(params.row.legId, params.row.invoiceNumber)}> Details </Button>
                    </div>
                    <div style={{ marginLeft: 2 }}>
                        <Button variant='contained'> Download </Button>
                    </div>
                </div>
            );
            },
        },
    ])
    return (
        <Box sx={{width:"100%"}}>
            <ClickAwayListener onClickAway={() => setRowSelectionModel([])}>
                <StyledGrid
                    rowHeight={50}
                    rows={rows}
                    columns={columns}
                    sx={datagridSx}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                        // setSelectedIds(new Set(newRowSelectionModel));
                    }}
                    rowSelectionModel={rowSelectionModel}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: CustomToolbar,
                    }}
                    loading={loading}
                    pageSize={pageSize}
                    autoHeight
                    // disableAutosize
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[25, 50, 100]}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    getRowClassName={(params) =>
                        `planner-view-grid--O-0`
                    }
                    getRowHeight={() => "auto"}
                />
            </ClickAwayListener>
        </Box>
    )
 } 


 function DetailReport({invoiceNo, legId, openDetails, setOpenDetails}){
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(()=>{
        const fetchData = async ()=>{
        const response = await getTPADetails(invoiceNo, legId);
      
        if(response.status == 200) {
          let rows = response.data.map((d) =>{
             return {
                 ...d,
                 "id":d.legId
             }
          })
         setRows(rows);
        } else {
         setRows([]);
        }

      } 
      if(openDetails){
          setLoading(true);
          fetchData();
          setLoading(false)
      }
    },[openDetails])

    const handleClose = () => {
      setOpenDetails(false)
    }
    return <div>
            <Dialog
                fullScreen={true}
                open={openDetails}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>
                   <StyledHeader>{`TPA Messages listing for Invoice - ${invoiceNo}'s Leg ID -  ${legId}`}</StyledHeader> 
                </DialogTitle>
                <DialogContent>
                  <StyledTable>
                    <TableHead>
                       <TableRow>
                        <TableCell>Invvoice Number</TableCell>
                        <TableCell>Trip Leg Id</TableCell>
                        <TableCell>Request</TableCell>
                        <TableCell>Response</TableCell>
                        {/* <TableCell>Type</TableCell>
                        <TableCell>Status</TableCell> */}
                       </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((r) =>(
                        <TableRow>
                        <TableCell>{invoiceNo}</TableCell>
                        <TableCell>{legId}</TableCell>
                        <TableCell>{r.request}</TableCell>
                        <TableCell>{r.response}</TableCell>
                        {/* <TableCell>{r.request?.call}</TableCell>
                        <TableCell>{r.response?.callStatus}</TableCell> */}
                       </TableRow>
                      ))}
                    </TableBody>
                  </StyledTable>
                </DialogContent>
                <DialogActions>
                <StyledButton variant={"contained"}  autoFocus onClick={handleClose}>
                    Download
                </StyledButton>
                <StyledButton variant={"contained"}  onClick={handleClose} autoFocus>
                    Close
                </StyledButton>
                </DialogActions>
        </Dialog>

    </div>
 } 
export default function TPAReport() {
    const [searchAttrs, setSearchAttrs] = useState({});
    const [openDetails, setOpenDetails] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [selectedLegId, setSelectedlegId] = useState(null);

    const setDetails = (legId,invoiceNo) =>{

      setSelectedInvoice(invoiceNo);
      setSelectedlegId(legId);
      setOpenDetails(true);

    }

    const handleOnSearch  = (attr) => 
    {      
        setSearchAttrs(attr);
          // setUploadView(false);
    }   

    return (
    <div>
         <div>
            <SearchBy  handleOnSearch={handleOnSearch}/>
         </div>
         <div><br></br></div>
         <div>
            <SummaryReport searchAttrs={searchAttrs} setDetails={setDetails}/> 
         </div>
         <div>
            <DetailReport 
              openDetails={openDetails}
              setOpenDetails={setOpenDetails}
              invoiceNo={selectedInvoice}
              legId = {selectedLegId}
            />
         </div>
         
    </div>
  )
}