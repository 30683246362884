import React, { Component } from 'react';
import 'fontsource-roboto'; 
import Dashboard from './dashboard/Dashboard';
import SignIn from './sign-in/SignIn';
import {BASE_URL} from './config';
import axios from "axios";

import 'react-toastify/dist/ReactToastify.css';
import ChangePassword from './sign-in/ChangePassword';


class ScreenPicker extends Component { 

  constructor(props) {
    super(props);
    //let url = new URL(window.location.href)

    this.state = {
    }
   
  
   
}

 
 setSessionState = async(userSession ) => {

  if(! userSession.active ){
    try{

      const response = await axios.post(
        BASE_URL + "/user/logout",
        userSession
      );
      // set the state of the user
      
      // store the user in localStorage
      
      console.log(response.data)
       
      this.setState({session : {'active':false,name:''}});
      localStorage.setItem('session', JSON.stringify({'active':false,name:''}) )

    }catch(error){ 
       
      }

     
    }else{
      this.setState({session : userSession});
    }

    
  }

componentDidMount() {
  let user = {'active':false,name:''}; 
  if(localStorage.getItem('session')){
      user = JSON.parse(localStorage.getItem('session'));
      this.setSessionState(user);
  }

  
}

render() {
  
    return( (this.state.session && this.state.session.active ) ? ( this.state.session.pwdreset? <ChangePassword onLogin={this.setSessionState} />  : <Dashboard setSessionState={this.setSessionState } /> ): <SignIn onLogin={this.setSessionState} />  );
}
}

export default ScreenPicker;