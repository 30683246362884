import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";

const FormHeader = ({ title }) => {
  return (
    <Grid container>
      <Grid item sm={12}>
        <Box className={"attestations_creteria_header"}>{title}</Box>
      </Grid>
    </Grid>
  );
};

const SearchRoasterPlan = ({ handleOnSearch, uploadView }) => {
  const [source, setSource] = useState("mas");
  const [invoiceNo, setInvoiceNo] = useState(null);
  const [tripDate, setTripDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const handleSearch = useCallback(() => {
    handleOnSearch({
      invoiceNo: invoiceNo,
      tripDate: tripDate,
      source: source,
    });
  }, [invoiceNo, tripDate, source]);

  const handleInvoiceNoChange = useCallback((e) => {
    setInvoiceNo(e.target.value);
  });

  const handleSetTripDate = useCallback((e) => {
    setTripDate(e.target.value);
  });

  const handleOnSourceChange = (event) => {
    setSource(event.target.value);
  };

  return (
    <Grid item sm={12}>
      <Box className={"attestations_creteria_container"}>
        <div>
          <FormHeader title="Search By" />
        </div>
        <Grid container spacing={3} justify="flex-end" sx={{ p: 0.5 }}>
          <Grid item xs sm={3}>
            <Grid item sx={{ height: uploadView ? 45 : 0 }}></Grid>
            <span className={"attestations_labels"}> Invoice #</span>
            <div>
              <input
                type="search"
                name="invoiceNo"
                className="attestations_inputs"
                value={invoiceNo ?? ""}
                onChange={(e) => handleInvoiceNoChange(e)}
              />
            </div>
          </Grid>
          <Grid item sm={3}>
            {uploadView && (
              <Grid item sx={{ marginLeft: 1 }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={source}
                  onChange={handleOnSourceChange}
                >
                  <FormControlLabel
                    value="mas"
                    control={<Radio />}
                    label="Trip Date"
                  />
                  <FormControlLabel
                    value="planned"
                    control={<Radio />}
                    label="Planned Date"
                  />
                </RadioGroup>
              </Grid>
            )}
            <Grid item>
              <span className={"attestations_labels"}>Start Date</span>
            </Grid>
            <Grid item>
              <input
                type="date"
                className="attestations_inputs"
                name="startDate"
                value={tripDate}
                onChange={(e) => handleSetTripDate(e)}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs
            container
            direction="row"
            justify="flex-start"
            alignItems="end"
            sx={{ paddingBottom: 0.5 }}
          >
            <Button
              variant="contained"
              onClick={(e) => {
                handleSearch();
              }}
            >
              Search
            </Button>
          </Grid>
          {/*  */}
        </Grid>
      </Box>
    </Grid>
  );
};

export default SearchRoasterPlan;
