import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import moment from "moment";

import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Button,
  TextField,
  Alert,
  MenuItem,
  NativeSelect,
  IconButton,
} from "@mui/material";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import "./trip_schedule.css";
import FilterBy from "./FilterBy";
import SearchBy from "./SearchBy";
import Download from "@mui/icons-material/Download";
import Print from "@mui/icons-material/Print";

let initialValues = {
  searchTripSortBy: "",
};

let skippedSortIds = [7, 8, 9, 10, 11, 12];
function ScheduleSearch({
  tripSortByList,
  searchForm,
  countyList,
  transportTypeList,
  searchstring,
  fetching,
  setSearchOrder,
  setSearchForm,
  handleFilteration,
  driverList,
  setFetchResults,
  pdfExport,
  excelExport,
  resetSearchForm,
}) {
  const sortz = [
    { name: "Invoice", field: "invoiceNumber" },
    { name: "CIN Medicaid", field: "medicaidNumber" },
    { name: "Recipient", field: "firstName" },
    { name: "Sex", field: "gender" },
    { name: "DOB", field: "DOB" },
    { name: "Trip Cost", field: "totalTripCost" },
    { name: "Calc Milage", field: "calculatedTripMileage" },
    { name: "Transport Type", field: "transportType" },
    { name: "Trip Date", field: "pickupDate" },
    { name: "Created Date", field: "originalCreateddate" },
  ];

  const [values, setValues] = useState(initialValues);

  const handleValueChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    console.log("loading search form data..........", searchForm);

    setValues({
      searchTripSortBy: tripSortByList[0],
    });
  }, []);

  useEffect(() => {
    let tripStatus = searchForm.tripStatus.map((item, index) => {
      return {
        id: item.id,
        name: item.name,
        checked: item.checked,
      };
    });
    handleValueChange("tripStatus", tripStatus);
  }, [searchForm.tripStatus]);

  const handleTripOrder = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    if (value && name) {
      // let name = event.target.value.name;
      let sortName = value.split("-")[0].trim();
      let order = value.split("-")[1].trim();
      if (order === "Low to High") {
        order = "ASC";
      } else {
        order = "DESC";
      }

      let selectedField;

      for (let o of sortz) {
        if (o.name === sortName) {
          selectedField = o.field;
        }
      }
      setSearchOrder(selectedField, order);
    }
    handleValueChange("searchTripSortBy", event.target.value);
  };

  const handleSearch = (searchValues) => {
    let _searchForm = searchForm;
    _searchForm.searchServiceStartsFrom =
      searchValues.searchServiceStartsFrom != ""
        ? moment(searchValues.searchServiceStartsFrom).format("MM/DD/YYYY")
        : "";
    _searchForm.searchServiceStartsTo =
      searchValues.searchServiceStartsTo != ""
        ? moment(searchValues.searchServiceStartsTo).format("MM/DD/YYYY")
        : "";

    _searchForm.searchServiceEndsFrom =
      searchValues.searchServiceEndsFrom != ""
        ? moment(searchValues.searchServiceEndsFrom).format("MM/DD/YYYY")
        : "";
    _searchForm.searchServiceEndsTo =
      searchValues.searchServiceEndsTo != ""
        ? moment(searchValues.searchServiceEndsTo).format("MM/DD/YYYY")
        : "";

    _searchForm.searchFromDOBFrom =
      searchValues.searchFromDOBFrom != ""
        ? moment(searchValues.searchFromDOBFrom).format("MM/DD/YYYY")
        : "";

    _searchForm.searchFromDOBTo =
      searchValues.searchFromDOBTo != ""
        ? moment(searchValues.searchFromDOBTo).format("MM/DD/YYYY")
        : "";

    _searchForm.searchCINNumber = searchValues.searchCINNumber;
    _searchForm.searchName = searchValues.searchName;
    _searchForm.searchInvoiceNumber = searchValues.searchInvoiceNumber;
    _searchForm.searchMasDriver =
      searchValues.searchMasDriver != "All"
        ? searchValues.searchMasDriver
        : undefined;
    _searchForm.searchDKDriver =
      searchValues.searchDKDriver != "All"
        ? searchValues.searchDKDriver
        : undefined;
    setSearchForm(_searchForm);
    setFetchResults(true);
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12}>
          <Grid container>
            <Grid item sm={6}>
              <Grid container>
                <SearchBy
                  resetSearchForm={resetSearchForm}
                  searchForm={searchForm}
                  search={handleSearch}
                  driverList={driverList}
                />
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container>
                <Grid item sm={12}>
                  <FilterBy
                    handleFilteration={handleFilteration}
                    tripSortByList={tripSortByList}
                    transportTypeList={transportTypeList}
                    countyList={countyList}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={2} sm={2} md={2} xl={2} className="grid-caption-wrapper">
          <div className="grid-caption"> Trip Sort By</div>
        </Grid>
        <Grid item xs={4} sm={4} md={4} xl={4}>
          <NativeSelect
            className={'attestations_inputs thin-border-select'}
            name="searchTripSortBy"
            value={searchForm.searchTripSortBy}
            onChange={(event, value) => {
              handleTripOrder(event, value);
            }}
          >
            {tripSortByList != null &&
              tripSortByList.map((option, index) => {
                if (!skippedSortIds.includes(option.id)) {
                  return (
                    <option key={index} value={option.name}>
                      {option.name}
                    </option>
                  );
                }
              })}
          </NativeSelect>

          {/* <TextField
            select
            size="small"
            className={"grid-inout-wrapper search-input-select"}
            id="searchTripSortBy"
            name={"searchTripSortBy"}
            value={searchForm.searchTripSortBy}
            onChange={handleTripOrder}
          >
            {tripSortByList ? (
              tripSortByList.map((option, index) => {
                if (!skippedSortIds.includes(option.id)) {
                  return (
                    <MenuItem
                      key={index}
                      className={"search-input-box"}
                      value={option}
                    >
                      {option.name}
                    </MenuItem>
                  );
                }
              })
            ) : (
              <MenuItem value="">Select</MenuItem>
            )}
          </TextField> */}
        </Grid>
      </Grid>
      <Grid container>
        {" "}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          xl={12}
          className="search-string-class"
        >
          <Alert severity="info">{searchstring}</Alert>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={0} sm={0} md={2} xl={2} display="flex"></Grid>
        <Grid item xs={12} sm={12} md={10} xl={10} sx={{ textAlign: "right" }}>
          {!searchForm.fetching ? (
            <>
              <IconButton>
                <Download />
              </IconButton>
              <a
                href="#"
                onClick={(e) => {
                  excelExport();
                }}
              >
                Download Schedule
              </a>
              <IconButton>
                <Print />
              </IconButton>
              <a
                href="#"
                onClick={(e) => {
                  pdfExport();
                }}
              >
                Print Schedule
              </a>
            </>
          ) : (
            <></>
          )}

          {fetching ? (
            <>
              <CircularProgress /> Loading ...
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
}
export default ScheduleSearch;
