import React, { Component }  from 'react';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import 'typeface-muli'; 
import "@fontsource/manrope";
import { createTheme, ThemeProvider } from '@mui/material';
import ScreenPicker from './screenPicker';
 
import  { ColorModeContext, useMode } from './theme';

 
const darkTheme = createTheme({
  palette: {
    secondary: {
      main: '#f9dd6d',
    },
  },
   typography: {
    fontFamily: [
      "Manrope",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif"
    ].join(",")
  }
});

const theme = createTheme({
  typography: {
    fontFamily: [
      "Manrope",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif"
    ].join(",")
  }
}); 

function App() {
  const [theme, colorMode] = useMode()
  return (     
    <ColorModeContext.Provider   value= {colorMode}>      
      <ThemeProvider theme= {theme}>
        <CssBaseline>
           <main>
             <ScreenPicker/>
          </main>
        </CssBaseline>
      </ThemeProvider>
    </ColorModeContext.Provider>

  );
}/*
function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <main>This app is using the dark mode</main>
    </ThemeProvider>
  );
}*/
export default App;
