import React, { useEffect, useState } from "react";
import { BASE_URL, COPYRIGHT, COPYRIGHT_URL } from "../config";

import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from "@mui/icons-material/Logout";
import MainListItems from "./listItems";
import MainDashboard from "./views/mainDashboard";
import CRUDUsers from "./views/crudUsers";
import CRUDDrivers from "./views/crudDrivers";

import CRUDUserLevels from "./views/crudUserLevels";
import CRUDSysProgramGroups from "./views/crudSysProgramGroups";
import CRUDSysPrograms from "./views/crudSysPrograms";
import UserRights from "./views/userRights";
import TripSearch from "./views/tripSearch";
import TripSchedule from "./views/tripSchedule";

import FaqManagement from "./views/faqManagement.tsx";
import UpdateRoster from "./views/updateRoster";
import ChangePassword from "./views/changePassword";
import CustomAddress from "./views/clientCustomAddresses";
import ScheduleGrouping from "./views/scheduleGrouping/scheduleGrouping";
import ConstructionIcon from "@mui/icons-material/Construction";

import axios from "axios";

import { styled } from "@mui/material/styles";
import AttestaionController from "../views/Attestations/AttestationController";

// import AttestaionController from "../views/Attestation/AttestaionController";

import AttestaionAuditController from "./views/Attestation/AttestationAuditController";

import TPAReport from "../views/Reports/TPAReport";



function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit">{COPYRIGHT}</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    // position: 'relative',
    whiteSpace: "nowrap",
    width: "100%",
    position: "absolute",
    zIndex: 9999,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "unset",
      zIndex: 10,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(0),
      position: "unset",
      zIndex: 10,
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(6),
        position: "unset",
        zIndex: 10,
      },
    }),
  },
}));

function contentSwitch(param, setSession) {
  switch (param) {
    case "MAIN_DASHBOARD":
      return <MainDashboard setSessionState={setSession} />;
    case "CRUD_USERS":
      return <CRUDUsers setSessionState={setSession} />;
    case "CRUD_USER_LEVELS":
      return <CRUDUserLevels setSessionState={setSession} />;
    case "CRUD_SYS_PROG_GROUP":
      return <CRUDSysProgramGroups setSessionState={setSession} />;
    case "CRUD_SYS_PROG":
      return <CRUDSysPrograms setSessionState={setSession} />;
    case "USER_RIGHTS":
      return <UserRights setSessionState={setSession} />;

    case "ATTESTATIONS":
      return <AttestaionController setSessionState={setSession} />;

    case "TRIP_SEARCH":
      return <TripSearch setSessionState={setSession} />;
    // case 'ATTESTATIONS':
    //   return <TripAuthorisations setSessionState={setSession } />;
    case "FAQ":
      return <FaqManagement setSessionState={setSession} />;
    case "UPDATE_ROSTER":
      return <UpdateRoster setSessionState={setSession} />;
    case "CHANGE_PASSWORD":
      return <ChangePassword setSessionState={setSession} />;
    case "TRIP_SCHADULE":
      return <TripSchedule setSessionState={setSession} />;
    case "ATTESTAION_AUDIT":
      return <AttestaionAuditController setSessionState={setSession} />;
    case "MOBILE_DRIVER":
      return <CRUDDrivers setSessionState={setSession} />;
    case "SCHADULE_GROPUPING":
      return <ScheduleGrouping setSessionState={setSession} />;
    case "CUSTOM_ADDRESS":
      return <CustomAddress setSessionState={setSession} />;
    case "REQUEST_RESPONSE_REPORT":
      return <TPAReport setSessionState={setSession} />;
    default:
      return (
        <>
          <ConstructionIcon /> Under Construction !{" "}
        </>
      );
  }
}

function DashboardContent(props) {
  const [screen, setScreen] = useState("MAIN_DASHBOARD");
  const [menu, setMenu] = useState([]);

  const setDisplayScreen = (screenNmae) => {
    toggleDrawer();
    setScreen(screenNmae);
  };
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  let user = { active: false, name: "" };
  if (localStorage.getItem("session")) {
    user = JSON.parse(localStorage.getItem("session"));
  }

  const getToken = async () => {
    let user = { active: false, name: "" };
    if (localStorage.getItem("session")) {
      user = await JSON.parse(await localStorage.getItem("session"));
    }

    const response = await axios.post(BASE_URL + "/user/check", user);
    if (!response.data.chk) {
      user.active = false;
      props.setSession(user);
    } else {
      setMenu(response.data.menu);
    }
  };
  useEffect(() => {
    getToken();
  }, []);

  /* let LogOutFunc = props.setSession();

  function logOut(){
    let user = {'active':false,name:''}; 
    LogOutFunc(user);
  }*/

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="absolute"
        open={open}
        sx={{
          backgroundColor: "rgb(245 245 245)",
          backgroundRepeat: "round",
          boxShadow: "none",
          //boxShadow: "rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px !important",
          // border:"1px solid"
        }}
      >
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            col
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "22px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            noWrap
            sx={{ flexGrow: 1, textAlign: "left" }}
          >
            <div className="header-user-name">
              <div className="header-user-name-welcome"> Welcome , </div>
              <div className="header-user-name-value">
                &nbsp;{user.userFullName}{" "}
              </div>
            </div>
          </Typography>
          {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}

          <IconButton
            color="inherit"
            onClick={() => {
              user.active = false;
              props.setSession(user);
            }}
            sx={{ color: "black" }}
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          {" "}
          <img
            alt="Mas System Logo"
            src={process.env.PUBLIC_URL + "/logo512.png"}
          />
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <MainListItems
            setScreen={setDisplayScreen}
            screen={screen}
            menu={menu}
          />
          <Divider sx={{ my: 1 }} />
          {/*secondaryListItems*/}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ mt: 1, mb: 1 }}>
          {contentSwitch(screen, props.setSession)}

          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}

export default function Dashboard(props) {
  return <DashboardContent setSession={props.setSessionState} />;
}
